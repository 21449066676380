import { db } from '../../shared/firebase.js';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

export async function getFormGroups() {
    // get all students and find unique form groups
    const q = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval), orderBy('formGroup'));
    const querySnapshot = await getDocs(q);

    var formGroups = [];

    querySnapshot.forEach((doc) => {
        // get data from doc
        const data = doc.data();
        // get form group
        formGroups.push(data.formGroup);
    });

    // remove duplicates
    formGroups = [...new Set(formGroups)];

    // sort alphabetically
    formGroups.sort();

    // remove blank form groups
    formGroups = formGroups.filter(function (el) {
        return el != '' && el != null && el != undefined;
    });

    return formGroups;
}