import { collection, getDocs, query, where, Timestamp, orderBy } from 'firebase/firestore';
import { downloadCSV } from './utils/csv.js';
import { downloadPDF } from './utils/pdf.js';
import { createSpreadsheet } from './utils/sheet.js';
import { db } from '../shared/firebase.js';
import { hideTooltip, simulateTooltipHover } from './utils/tooltip.js';
import flatpickr from 'flatpickr';

export async function applyHistoryFilters() {
    console.log('applying history filters');

    // Preprocess filters for efficient comparisons
    const nameFilter = window.historyFilters.name.toLowerCase();
    const actionFilter = window.historyFilters.action;
    const formGroupFilter = window.historyFilters.formGroup;

    // Use a DocumentFragment to batch updates
    const fragment = document.createDocumentFragment();

    var tableRows = Array.from(document.querySelectorAll('#history-table tbody tr'));

    // Directly manipulate this count instead of calculating later
    let visibleRowsCount = tableRows.length;

    // Remove 'no records found' row if it exists, minimizing DOM manipulation
    const noRecordsRow = document.getElementById('history-no-records');
    if (noRecordsRow) {
        noRecordsRow.remove();
        visibleRowsCount--; // Adjust because we removed a row
    }

    tableRows.forEach(function (row) {
        var nameCell = row.getElementsByTagName('th')[0];
        var name = nameCell ? nameCell.textContent.toLowerCase() : '';

        var actionCell = row.getElementsByTagName('td')[3];
        var action = actionCell ? actionCell.textContent : '';

        var formGroupCell = row.getElementsByTagName('td')[0];
        var formGroup = formGroupCell ? formGroupCell.textContent : '';

        var nameMatch = name.includes(nameFilter);
        var actionMatch = actionFilter === 'Show All' || action.toString().includes(actionFilter) || false;
        var formGroupMatch = formGroupFilter === 'Show All' || formGroup === formGroupFilter;

        if (nameMatch && actionMatch && formGroupMatch) {
            if (row.style.display === 'none') {
                row.style.display = '';
                visibleRowsCount++;
            }
        } else {
            if (row.style.display !== 'none') {
                row.style.display = 'none';
                visibleRowsCount--;
            }
        }
    });

    console.log(`rows processed, ${visibleRowsCount} visible`);

    // Only update the DOM if necessary
    if (visibleRowsCount === 0) {
        console.log('no records found');
        const row = document.createElement('tr');
        row.innerHTML = '<th scope="row" colspan="5" style="text-align: center;" id="history-no-records">No records found</th>';
        fragment.appendChild(row);
        document.querySelector('#history-table tbody').appendChild(fragment);
    }
}


// Fill the 'History' table from Firestore
// show progress if it's a date or reg update, which requires re-fetching data
export async function fillHistory(showProgress = false) {
    console.log('filling history');
    // Hide the refresh and export buttons
    document.getElementById('refresh-history-button').style.display = 'none';
    document.getElementById('export-history-button').disabled = true;
    if (window.loadingHistory) {
        console.log('history already loading');
        return;
    }
    window.loadingHistory = true;
    const loadStartTime = Date.now();
    const progressBar = document.getElementById('history-progress');
    var finishedLoading = false;
    var barTimeout = null;
    if (showProgress) {
        progressBar.value = 0;
        progressBar.max = 100;
        // hide the table
        document.querySelector('#history-table').style.display = 'none';

        // show the bar if it hasn't finished loading after a second
        barTimeout = setTimeout(() => {
            if (!finishedLoading) {
                // set it to 2%, because with a lot of records, it's hard to see the progress bar
                progressBar.style.display = 'block';
                progressBar.value = 2;
            }
        }, 500);
    }

    // Pre-fetch all student data and store in an object
    const students = {};
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // Pre-fetch all kiosk data and store in an object
    const kiosks = {};
    const kiosksRef = query(collection(db, 'domains', window.userDomain, 'kiosks'));

    await getDocs(kiosksRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const kioskData = doc.data();
            kiosks[doc.id] = kioskData.location || '-';
        });
    });

    // Pre-fetch all admin data and store in an object
    const admins = {};
    const adminsRef = query(collection(db, 'users'), where('domain', '==', window.userDomain));

    await getDocs(adminsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const adminData = doc.data();
            admins[doc.id] = adminData.name || '-';
        });
    });

    const startDateString = window.historyFilters.startDate;
    const endDateString = window.historyFilters.endDate;

    const startParts = startDateString.split('/');
    const endParts = endDateString.split('/');

    const startOfDay = new Date(startParts[2], startParts[1] - 1, startParts[0], 0, 0, 0);
    const endOfDay = new Date(endParts[2], endParts[1] - 1, endParts[0], 23, 59, 59);

    const startTimestamp = Timestamp.fromDate(startOfDay);
    const endTimestamp = Timestamp.fromDate(endOfDay);

    // Load this from DB, not using a local filter
    // Otherwise we end up creating lots of rows that may never be shown, clogging the DOM
    const includeReg = document.getElementById('history-registration').checked;

    const q = query(
        collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'),
        where('archived', '==', false),
        where('time', '>=', startTimestamp),
        where('time', '<=', endTimestamp),
        orderBy('time', 'desc')
    );

    // Fetch documents in chunks to prevent freezing
    let querySnapshot;
    try {
        querySnapshot = await getDocs(q);
    } catch (error) {
        console.error('Error fetching documents:', error);
        displayErrorRow();
        progressBar.style.display = 'none';
        window.loadingHistory = false;
        return;
    }

    // Fetch the existing rows as a map for quick access
    const existingRowsMap = new Map();
    const tableBody = document.querySelector('#history-table tbody');
    tableBody.querySelectorAll('tr').forEach(row => {
        const studentID = row.getAttribute('data-student-id');
        const time = row.getAttribute('data-time');
        const key = `${studentID}-${time}`;
        existingRowsMap.set(key, row);
    });

    if (querySnapshot.size === 0) {
        //// LOADING COMPLETE - NO DATA ////
        progressBar.style.display = 'none';
        progressBar.value = null; // return to indeterminate
        progressBar.max = null;
        finishedLoading = true;
        clearTimeout(barTimeout);
        window.loadingHistory = false;
        displayNoRecordsRow();
        return;
    }

    const docs = querySnapshot.docs;
    let index = 0;

    function processNextBatch() {
        const batchSize = 250; // Adjust as necessary to prevent freezing
        const end = Math.min(index + batchSize, docs.length);

        for (; index < end; index++) {
            const docu = docs[index];
            const data = docu.data() || {};
            const studentID = data.studentID || null;
            const timestamp = data.time.toDate() || null;
            const timeKey = timestamp.getTime().toString() || ''; // Convert time to string for key
            const key = `${studentID}-${timeKey}`;

            let action = 'Unknown';

            // Skip 'AM_register' type records
            if (data.type === 'AM_register' && !includeReg) {
                continue;
            } else if (data.type === 'AM_register' && includeReg) {
                action = 'Sign In - Registration';
            } else if (data.action === 'in' && data.type === 'admin') {
                action = 'Sign In - Admin';
            } else if (data.action === 'out' && data.type === 'admin') {
                action = 'Sign Out - Admin';
            } else if (data.action === 'in' && data.type === 'badge') {
                action = 'Sign In - Badge';
            } else if (data.action === 'out' && data.type === 'badge') {
                action = 'Sign Out - Badge';
            } else if (data.action === 'in') {
                action = 'Sign In';
            } else if (data.action === 'out') {
                action = 'Sign Out';
            } else {
                console.error('Unknown action:', data.action);
            }

            if (existingRowsMap.has(key)) {
                existingRowsMap.delete(key);
            } else {
                const row = document.createElement('tr');
                const studentData = students[studentID] || {};
                const studentName = studentData.studentName || 'Unknown';
                const formGroup = studentData.formGroup || 'Unknown';
                const timeFormatted = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const dateFormatted = timestamp.toLocaleDateString();

                // on hover, show the kiosk name or the admin name
                var hoverText = '';
                if (data.adminID) {
                    hoverText = admins[data.adminID] || '-';
                } else if (data.kiosk) {
                    hoverText = kiosks[data.kiosk] || '-';
                }


                row.innerHTML = `
                    <th scope="row">${studentName}</th>
                    <td>${formGroup}</td>
                    <td>${dateFormatted === new Date().toLocaleDateString() ? 'Today' : dateFormatted}</td>
                    <td>${timeFormatted}</td>
                    <td title="${hoverText}">${action}</td>
                `;
                row.style.backgroundColor = data.action === 'in' ? 'rgba(124, 179, 66, 0.38)' : data.action === 'out' ? 'rgba(216, 27, 96, 0.38)' : 'transparent';
                row.setAttribute('data-student-id', studentID);
                row.setAttribute('data-time', timeKey);

                insertRowInSortedOrder(tableBody, row, timestamp.getTime());
            }

            // update progress bar to percentage complete, if we're more than 2% done
            // this avoids the bar bouncing backwards because it starts at 2%
            const progress = Math.round((index / docs.length) * 100);
            if (progress > 2) {
                progressBar.value = progress;
            }
        }

        if (index < docs.length) {
            setTimeout(processNextBatch, 0); // Schedule the next batch
        } else {

            //// LOADING COMLPETE with data ////
            existingRowsMap.forEach(row => {
                row.parentNode.removeChild(row);
            });
            applyHistoryFilters();
            if (showProgress) {
                progressBar.style.display = 'none';
                progressBar.value = null; // return to indeterminate
                progressBar.max = null;
                finishedLoading = true;
                clearTimeout(barTimeout);
            }

            const loadEndTime = Date.now();
            console.log(`Loaded ${docs.length} records in ${Math.round((loadEndTime - loadStartTime) / 1000)}s, with batch size of ${batchSize}`);
            window.displayingHistoryRecordCount = docs.length;
            // show the table and export button
            document.querySelector('#history-table').style.display = 'table';
            window.loadingHistory = false;

            // if there's more than 1,000, show the manual refresh button
            const refreshButton = document.getElementById('refresh-history-button');
            if (docs.length > 1000) {
                console.log('more than 1,000 records, showing manual refresh button');
                refreshButton.style.display = 'block';
                simulateTooltipHover(refreshButton);
            } else {
                console.log('less than 1,000 records, hiding manual refresh button');
                refreshButton.style.display = 'none';
                hideTooltip(refreshButton);
            }

        }
    }

    document.getElementById('export-history-button').disabled = false;
    processNextBatch();
}

function displayNoRecordsRow() {
    const tableBody = document.querySelector('#history-table tbody');
    tableBody.innerHTML = '';
    const row = document.createElement('tr');
    row.innerHTML = `
        <th scope="row" colspan="5" style="text-align: center;" id="history-no-records">No records found</th>
    `;
    tableBody.appendChild(row);
    document.querySelector('#history-table').style.display = 'table';
}

function displayErrorRow() {
    const tableBody = document.querySelector('#history-table tbody');
    tableBody.innerHTML = '';
    const row = document.createElement('tr');
    row.innerHTML = `
        <th scope="row" colspan="5" style="text-align: center;" id="history-no-records">Error loading attendance data</th>
    `;
    tableBody.appendChild(row);
    document.querySelector('#history-table').style.display = 'table';
}


// Function to insert row in sorted order
function insertRowInSortedOrder(tableBody, newRow, newTime) {
    const rows = Array.from(tableBody.children);
    // Find the correct position to insert the new row based on descending time order
    const insertIndex = rows.findIndex(row => {
        const existingTime = Number(row.getAttribute('data-time'));
        return newTime > existingTime;
    });

    if (insertIndex === -1) {
        // If no existing time is later than newTime, append at the end
        tableBody.appendChild(newRow);
    } else {
        // Otherwise, insert before the found row
        tableBody.insertBefore(newRow, rows[insertIndex]);
    }
}


// Count how many records are about to be exported
/// TODO: take into account other filters, not just date
export async function countHistoryExports() {
    console.log('counting history exports...');

    document.getElementById('history-export-count').innerHTML = 'Counting records...';
    document.getElementById('history-export-count').setAttribute('aria-busy', 'true');

    // Pre-fetch all student data and store in an object
    const students = {};
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    const includeReg = document.getElementById('history-registration').checked;

    // get the history filters
    const startDateString = window.historyFilters.startDate;
    const endDateString = window.historyFilters.endDate;

    const startParts = startDateString.split('/');
    const endParts = endDateString.split('/');

    const startOfDay = new Date(startParts[2], startParts[1] - 1, startParts[0], 0, 0, 0);
    const endOfDay = new Date(endParts[2], endParts[1] - 1, endParts[0], 23, 59, 59);

    const startTimestamp = Timestamp.fromDate(startOfDay);
    const endTimestamp = Timestamp.fromDate(endOfDay);

    var q = query(
        collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'),
        where('archived', '==', false),
        where('time', '>=', startTimestamp),
        where('time', '<=', endTimestamp),
    );

    // explicitly don't query for reg records if we don't need to
    if (!includeReg) {
        q = query(q, where('type', '!=', 'AM_register'));
    }

    await getDocs(q)
        .then((querySnapshot) => {
            var count = 0;
            // for each record, run shouldInclude
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (shouldInclude(data, students[data.studentID])) {
                    count++;
                }
            });

            console.log(`Found ${count} records to export`);
            document.getElementById('history-export-count').innerHTML = `You will be exporting ${count} records.`;
            document.getElementById('history-export-count').setAttribute('aria-busy', 'false');
        });
}

export function toggleBackToTopButton() {
    const backToTopButton = document.getElementById('back-to-top');
    const filtersContainer = document.querySelector('.filter-container');

    if (window.scrollY > filtersContainer.offsetHeight) {
        backToTopButton.style.display = 'block';
    } else {
        backToTopButton.style.display = 'none';
    }
}

export async function exportHistory(format) {
    console.log('exporting history...');

    var exportData = [];

    // Pre-fetch all student data and store in an object
    const students = {};

    // get all records - for the date
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // Pre-fetch all kiosk data and store in an object
    const kiosks = {};
    const kiosksRef = query(collection(db, 'domains', window.userDomain, 'kiosks'));

    await getDocs(kiosksRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const kioskData = doc.data();
            kiosks[doc.id] = kioskData.location || '-';
        });
    });

    // Pre-fetch all admin data and store in an object
    const admins = {};
    const adminsRef = query(collection(db, 'users'), where('domain', '==', window.userDomain));

    await getDocs(adminsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const adminData = doc.data();
            admins[doc.id] = adminData.name || '-';
        });
    });

    const includeReg = document.getElementById('history-registration').checked;

    // get the history filters
    const startDateString = window.historyFilters.startDate;
    const endDateString = window.historyFilters.endDate;

    const startParts = startDateString.split('/');
    const endParts = endDateString.split('/');

    const startOfDay = new Date(startParts[2], startParts[1] - 1, startParts[0], 0, 0, 0);
    const endOfDay = new Date(endParts[2], endParts[1] - 1, endParts[0], 23, 59, 59);

    const startTimestamp = Timestamp.fromDate(startOfDay);
    const endTimestamp = Timestamp.fromDate(endOfDay);

    var q = query(
        collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'),
        where('archived', '==', false),
        where('time', '>=', startTimestamp),
        where('time', '<=', endTimestamp),
        orderBy('time', 'desc')
    );

    // explicitly don't query for reg records if we don't need to
    if (!includeReg) {
        q = query(q, where('type', '!=', 'AM_register'));
    }

    await getDocs(q)
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data();

                // get the student name
                const studentData = students[data.studentID];

                if (shouldInclude(data, studentData)) {
                    const studentName = studentData.studentName;
                    const formGroup = studentData.formGroup;

                    // get the date
                    const date = data.time.toDate().toLocaleString();
                    // get the action
                    var action = '';
                    if (data.action === 'in') {
                        action = 'Sign In';
                    } else if (data.action === 'out') {
                        action = 'Sign Out';
                    }

                    var type = '';
                    if (data.type === 'AM_register') {
                        type = 'Registration';
                    } else if (data.type === 'badge') {
                        type = 'Badge';
                    } else if (data.type === 'admin') {
                        type = 'Admin';
                    }

                    var detail = '';
                    if (data.adminID) {
                        detail = admins[data.adminID] || '-';
                    } else if (data.kiosk) {
                        detail = kiosks[data.kiosk] || '-';
                    }


                    if (format !== 'sheet') {
                        // only push the name, form gruop, date, action
                        exportData.push({
                            Name: studentName,
                            FormGroup: formGroup,
                            Date: date,
                            Action: action,
                            Type: type,
                            Detail: detail
                        });
                    } else {
                        // sheet format - no data names
                        exportData.push([
                            studentName,
                            formGroup,
                            date,
                            action,
                            type,
                            detail
                        ]);
                    }
                }
            });
        });

    if (format === 'pdf') {
        downloadPDF('History Report', '', ['Name', 'FormGroup', 'Date', 'Action', 'Type', 'Detail'], exportData, 'history-report');
    } else if (format === 'csv') {
        downloadCSV(exportData, ['Name', 'FormGroup', 'Date', 'Action', 'Type', 'Detail'], 'history-report');
    } else if (format === 'sheet') {
        const spreadsheet = await createSpreadsheet('History Report', ['Name', 'Form Group', 'Date', 'Action', 'Type', 'Detail'], exportData);
        if (spreadsheet) {
            console.log('Spreadsheet created - ID:', spreadsheet.spreadsheetId);
            // open the URL - spreadsheetUrl
            window.open(spreadsheet.spreadsheetUrl, '_blank');
        }
        // if there was an error, the function handles it
    }
}

// Check if a record should be included in the export
function shouldInclude(record, student) {
    if (!student) return false;
    
    // if no student name, set to empty string
    if (!student.studentName) {
        console.warn(`No student name: ${student.studentName} - ${student.misID}`);
        student.studentName = '';
    }

    var studentName = student.studentName ? student.studentName.toLowerCase() : '';
    var action = record.action ? record.action : '';
    var formGroup = student.formGroup ? student.formGroup : '';

    // Applying the filters stored in the global window.historyFilters object.
    var nameFilter = window.historyFilters.name ? window.historyFilters.name.toLowerCase() : '';
    var actionFilter = window.historyFilters.action;
    if (actionFilter === 'Sign In') {
        actionFilter = 'in';
    } else if (actionFilter === 'Sign Out') {
        actionFilter = 'out';
    }
    var formGroupFilter = window.historyFilters.formGroup;

    // Checking if each filter criteria is met.
    var nameMatch = studentName.includes(nameFilter);
    var actionMatch = (actionFilter === 'Show All' || action.toString().includes(actionFilter)) || false;
    var formGroupMatch = (formGroupFilter === 'Show All' || formGroup === formGroupFilter);

    // Determine if the record should be included based on all criteria.
    return nameMatch && actionMatch && formGroupMatch;
}

export function configureDatePicker() {
    // Split the interval to get the start and end years
    const [startYearStr, endYearStr] = window.currentInterval.split('_');

    // Parse the years as integers
    const startYear = parseInt(startYearStr, 10);
    const endYear = parseInt(endYearStr, 10);

    // September to July, academic year
    const startDate = new Date(startYear, 8, 1);  // September 1 of startYear (Month 8 is September)
    const endDate = new Date(endYear, 7, 31);     // August 31 of endYear (Month 7 is August)

    console.log(`history filter between ${startDate} and ${endDate}`);

    // Initialize flatpickr for date range
    flatpickr('#dateInput', {
        mode: 'range',
        dateFormat: 'd/m/Y',
        defaultDate: [new Date(), new Date()],
        // max/min dates are this interval
        minDate: startDate,
        maxDate: endDate,
    });
}