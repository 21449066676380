import { collection, getDocs, query, where, doc, getDoc, updateDoc, orderBy, addDoc, Timestamp, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { openModal, closeModal } from './shared/modal.js';
import { fillAllStudentsTable, refreshAllHomeRows } from './admin/all-students.js';
import { showError } from './shared/error.js';
import { loadFireRegisters } from './admin/fire-registers.js';
import { loadOnsite, refreshOnsiteStudentRows } from './admin/onsite.js';
import { delay } from './shared/delay.js';
import { downloadCSV } from './admin/utils/csv.js';
import { downloadPDF } from './admin/utils/pdf.js';
import { createSpreadsheet } from './admin/utils/sheet.js';
import { loadItems, loanSearchNameChanged, applyItemFilters, refreshAllItems, applyItemHistoryFilters, exportItemHistory, validateCSVImport, fillNewItemFormTypeDropdown, createNewItem, createNewItemType, selectCurrentItemLoanStudent, loadKiosksInItemType } from './admin/items.js';
import { deleteObject } from './admin/utils/delete.js';
import { loadEvents, fillNewEventModal, editEvent, createNewEvent } from './admin/events.js';
import { getFormGroups } from './admin/utils/form-groups.js';
import { validateEmail } from './admin/utils/email.js';
import { processLogin, signOutUser } from './admin/utils/auth.js';
import { humanReadableDate } from './admin/utils/date-format.js';
import { fillHistory, applyHistoryFilters, exportHistory, countHistoryExports, toggleBackToTopButton, configureDatePicker } from './admin/history.js';
import { simulateTooltipHover, hideTooltip } from './admin/utils/tooltip.js';
import { loadKiosks } from './admin/kiosks.js';
import { processManualLoan } from './admin/items';
import { loadEnrolmentMode, loadViewEnrolmentMode, prepareEnrolmentMode } from './admin/enrolment.js';
import { checkForMaintenance } from './shared/maintenance.js';
import '@picocss/pico/css/pico.min.css';

import initSentry from './shared/sentry.js';
// initialise sentry
const Sentry = initSentry();

import { db, auth, provider, functions } from './shared/firebase.js';
provider.addScope('https://www.googleapis.com/auth/drive.file');

import initPosthog from './shared/posthog.js';
import { showListDialog } from './admin/utils/list.js';
const posthog = initPosthog();

window.deletingObjectType = null;
window.deletingObjectID = null;
let activeButtonId = null;
var disablingBadgeUserID = null;
var disablingOrEnabling = null;
var batchStudentData = [];
var badgeStudentData = [];
var lastBatchSearchQuery = '';
var lastBadgeSearchQuery = '';
var assigningBadgeUserID = null;
let skipCurrentStudent = false;
var banningLoanStudentID = null;
let keydownHandlerReference; // This will store the reference to the badge listener keydown handler
let batchUnsubscribe; // This will store the reference to the batch search listener
var batchSnapshot; // This will store the batch snapshot
var lastBatchStudentID; // the last student ID to have a badge added
var batchTableIndex = 0; // used for arrow key navigation in the batch table
var assigningTempBadgeID = null;
var newTempBadgeID = null;
var loadedPages = {}; // pages that have been loaded - don't load the same page twice
var currentPage = null;
window.displayingHistoryRecordCount = 0; // how many records are being displayed - used to defer refreshes if over 1,000
window.loadingHistory = false; // used to prevent multiple loadings of the history table

// Global object to keep track of the current filter states
window.historyFilters = {
    name: '',
    // default date is today
    startDate: new Date().toLocaleDateString(),
    endDate: new Date().toLocaleDateString(),
    action: 'Show All',
    formGroup: 'Show All',
    registrations: false
};

window.itemHistoryFilters = {
    name: '',
    formGroup: 'Show All',
    date: null, // show all by default
    action: 'Show All',
};

var y12OnsiteFilters = {
    name: '',
    formGroup: 'Show All'
};

var y13OnsiteFilters = {
    name: '',
    formGroup: 'Show All'
};

var y12AllStudentsFilters = {
    name: '',
    formGroup: 'Show All'
};

var y13AllStudentsFilters = {
    name: '',
    formGroup: 'Show All'
};

////// AUTHENTICATION //////
// when a new user is signed in with Google, called when the login button is clicked
auth.onAuthStateChanged(async (user) => {
    const maintenance = await checkForMaintenance();
    if (maintenance) {
        return;
    }

    if (user) {
        document.getElementById('loading-progress').style.display = 'block';
        console.log('User signed in:', user.displayName);
        await processLogin(loadApp);
    } else {
        console.log('User not signed in');
        // show login button
        document.getElementById('logInButton').style.display = 'block';
        // hide loading-progress
        document.getElementById('loading-progress').style.display = 'none';
    }
});

// load this page after auth is complete
async function loadApp() {
    if (window.userRole == 'badgeadmin') {
        console.log('User is a badge admin');
        if (document.getElementById('admin-button-li')) {
            document.getElementById('admin-button-li').remove();
        }
    }

    if (window.userRole == 'viewer') {
        console.log('User is a viewer');
        // remove the create/new buttons
        if (document.getElementById('admin-button-li')) {
            document.getElementById('admin-button-li').remove();
        }
    }

    // if the user is a fire marshal, remove all other buttons
    if (window.userRole == 'firemarshal') {
        console.log('User is a fire marshal');
        // home, onsite, history,items, events, badges, students, fire register in menu, users, kiosks
        if (document.getElementById('home-button')) {
            document.getElementById('home-button').remove();
        }
        if (document.getElementById('onsite-button')) {
            document.getElementById('onsite-button').remove();
        }
        if (document.getElementById('history-button')) {
            document.getElementById('history-button').remove();
        }
        if (document.getElementById('items-button')) {
            document.getElementById('items-button').remove();
        }
        if (document.getElementById('events-button')) {
            document.getElementById('events-button').remove();
        }
        if (document.getElementById('manage-badges-button')) {
            document.getElementById('manage-badges-button').remove();
        }
        if (document.getElementById('students-button-li')) {
            document.getElementById('students-button-li').remove();
        }
        if (document.getElementById('fire-register-button-li')) {
            document.getElementById('fire-register-button-li').remove();
        }
        if (document.getElementById('admin-button-li')) {
            document.getElementById('admin-button-li').remove();
        }
        if (document.getElementById('kiosk-button-li')) {
            document.getElementById('kiosk-button-li').remove();
        }
        if (document.getElementById('install-kiosk-button-li')) {
            document.getElementById('install-kiosk-button-li').remove();
        }
        // show fire-register-nav-li
        document.getElementById('fire-register-nav-li').style.display = 'block';

        // hide the home div, show the fire register div
        document.getElementById('home').style.display = 'none';
        document.getElementById('fire-register').style.display = 'block';
        setActiveButton('fire-register-nav-button');

        await loadFireRegisters();
        loadedPages.fireRegister = true;
        currentPage = 'fireRegister';
    } else {
        // usual loading stuff for non-fire marshals

        // set small to checked
        // should be able to do this in HTML but it doesn't work (??)
        document.getElementById('small').checked = true;
    }

    // if the domain does not have enrolment mode, hide the enrolment mode button
    const domainRef = doc(db, 'domains', window.userDomain);
    const domainDoc = await getDoc(domainRef);
    if (domainDoc.exists()) {
        const domainData = domainDoc.data();
        // viewers, kiosks, fire marshals can't enrol
        if (!domainData.enrolmentEnabled || window.userRole === 'viewer' || window.userRole === 'kiosk' || window.userRole === 'firemarshal') {
            document.getElementById('enrolment-mode-button-li').style.display = 'none';
        }

        // check if we have an interval override param
        let intervalOverride = new URLSearchParams(window.location.search).get('interval');
        if (intervalOverride) {
            intervalOverride = intervalOverride.replace('-', '_');
            console.log('interval override:', intervalOverride);

            // if the interval doesn't exist in the db, show an error
            const intervalRef = doc(db, 'domains', window.userDomain, 'intervals', intervalOverride);
            const intervalDoc = await getDoc(intervalRef);
            if (!intervalDoc.exists()) {
                // remove the interval override param and redirect to it via error
                const url = new URL(window.location);
                url.searchParams.delete('interval');

                // show an error
                showError('4004', 'Invalid Academic Year', 'The year you have selected does not exist.<br>Click Reload to reset to the current year.', null, false, false, true, url.toString());
                return;
            }

            window.currentInterval = intervalOverride;
        } else {
            // set the current interval
            window.currentInterval = domainData.currentInterval;
        }

        console.log('Current interval:', window.currentInterval);

        // load the all students table (home, so await)
        await fillAllStudentsTable();
        loadedPages.home = true;
        currentPage = 'home';

        // configure the date picker
        configureDatePicker();

        // load form groups into all the dropdowns (all pages)
        await loadFormGroups();
    } else {
        // domain does not exist, something has gone terribly wrong, just sign out!
        console.error('Domain does not exist');
        await signOutUser();
    }

    // show main container after successful data fetch
    document.getElementById('mainContainer').style.display = 'block';
    document.getElementById('loadingContainer').style.display = 'none';
}

// When the DOM is fully loaded, handle the sign-in redirect result
document.addEventListener('DOMContentLoaded', async () => {
    configureEventHandlers();
    // User is logged already in, just load the app
    if (auth.currentUser) {
        await processLogin(loadApp);
    }
});

function installKiosk() {
    console.log('installing kiosk...');
    // go to /kiosk?install=true
    window.location.href = '/kiosk?install=true';
}

async function configureEventHandlers() {
    // set home-button as active
    setActiveButton('home-button');

    // When the user clicks logout
    document.getElementById('logOutButton').addEventListener('mousedown', async () => {
        console.log('Logging out...');
        await signOutUser();
    });

    // kiosk install button event listener
    document.getElementById('kiosk-install-button').addEventListener('mousedown', async () => {
        console.log('Installing kiosk...');
        await installKiosk();
    });

    document.getElementById('enrolment-mode-button').addEventListener('mousedown', async () => {
        document.getElementById('enrolment-mode-button').setAttribute('aria-busy', 'true');

        // Show a dropdown - start enrolment or view enrolled students
        await showListDialog('Enrolment', 'Select an option to enter enrolment.', ['Enrol New Students', 'View Previously Enrolled Students'], false).then(async (result) => {
            if (result) {
                if (result === 'Enrol New Students') {
                    console.log('Enrol new students');

                    // Enrol new students
                    await prepareEnrolmentMode().then(async () => {
                        if (!loadedPages.enrolmentMode) {
                            const button = document.getElementById('enrolment-mode-button');
                            const timeoutId = setTimeout(() => {
                                button.setAttribute('aria-busy', 'true');
                            }, 100);
                            await loadEnrolmentMode();
                            clearTimeout(timeoutId);
                            loadedPages.enrolmentMode = true;
                            currentPage = 'enrolmentMode';
                        }

                        // Show the enrolment mode div, hide the others
                        document.getElementById('home').style.display = 'none';
                        document.getElementById('history').style.display = 'none';
                        document.getElementById('student-manager').style.display = 'none';
                        document.getElementById('admin').style.display = 'none';
                        document.getElementById('onsite').style.display = 'none';
                        document.getElementById('items-manager').style.display = 'none';
                        document.getElementById('manage-badges').style.display = 'none';
                        document.getElementById('events').style.display = 'none';
                        document.getElementById('fire-register').style.display = 'none';
                        document.getElementById('kiosks').style.display = 'none';
                        document.getElementById('enrolment-mode').style.display = 'block';
                        document.getElementById('view-enrolment-mode').style.display = 'none';

                        // Hide the navbar
                        document.getElementById('main-nav').style.display = 'none';
                        setActiveButton('enrolment-mode-button');
                    }).catch(error => {
                        console.error('Error preparing enrolment mode:', error);
                        return; // don't load if there's an error
                    });


                } else if (result === 'View Previously Enrolled Students') {
                    // View previously enrolled students

                    if (!loadedPages.viewEnrolmentMode) {
                        const button = document.getElementById('enrolment-mode-button');
                        const timeoutId = setTimeout(() => {
                            button.setAttribute('aria-busy', 'true');
                        }, 100);
                        await loadViewEnrolmentMode();
                        clearTimeout(timeoutId);
                        loadedPages.viewEnrolmentMode = true;
                        currentPage = 'viewEnrolmentMode';
                    }

                    // Show the enrolment mode div, hide the others
                    document.getElementById('home').style.display = 'none';
                    document.getElementById('history').style.display = 'none';
                    document.getElementById('student-manager').style.display = 'none';
                    document.getElementById('admin').style.display = 'none';
                    document.getElementById('onsite').style.display = 'none';
                    document.getElementById('items-manager').style.display = 'none';
                    document.getElementById('manage-badges').style.display = 'none';
                    document.getElementById('events').style.display = 'none';
                    document.getElementById('fire-register').style.display = 'none';
                    document.getElementById('kiosks').style.display = 'none';
                    document.getElementById('enrolment-mode').style.display = 'none';
                    document.getElementById('view-enrolment-mode').style.display = 'block';
                    setActiveButton('enrolment-mode-button');
                }
            }

        }).catch(error => {
            console.log('Error loading enrolment mode:', error);
            if (error.message === 'Modal closed without selection') {
                // ignore
            } else {
                showError('4002', 'Error Loading Enrolment Mode', 'There was an error loading the enrolment mode.', error, false, true);
            }
        });
        document.getElementById('enrolment-mode-button').setAttribute('aria-busy', 'false');
    });

    // kiosk button event listener
    document.getElementById('kiosk-button').addEventListener('mousedown', async () => {
        if (!loadedPages.kiosks) {
            const button = document.getElementById('kiosk-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadKiosks();
            clearTimeout(timeoutId);
            loadedPages.kiosks = true;
            currentPage = 'kiosks';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('home').style.display = 'none';
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'block';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        setActiveButton('kiosk-button');
    });

    // admin button event listener
    document.getElementById('admin-button').addEventListener('mousedown', async () => {

        // if the page isn't loaded, wait for it
        if (!loadedPages.admin) {
            const button = document.getElementById('admin-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadAdmin();
            clearTimeout(timeoutId);
            loadedPages.admin = true;
            currentPage = 'admin';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'block';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('home').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('admin-button');
    });

    // History button
    document.getElementById('history-button').addEventListener('mousedown', async () => {
        if (!loadedPages.history) {
            const button = document.getElementById('history-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await fillHistory();
            clearTimeout(timeoutId);
            loadedPages.history = true;
            currentPage = 'history';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('history').style.display = 'block';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('home').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';


        // set other buttons to transparent
        setActiveButton('history-button');
    });

    // Badge manager button
    document.getElementById('students-button').addEventListener('mousedown', async () => {
        if (!loadedPages.studentManager) {
            const button = document.getElementById('students-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await fillStudentManagerTable();
            clearTimeout(timeoutId);
            loadedPages.studentManager = true;
            currentPage = 'studentManager';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'block';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('home').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('students-button');
    });

    // Item manager button
    document.getElementById('items-button').addEventListener('mousedown', async () => {
        if (!loadedPages.itemsManager) {
            const button = document.getElementById('items-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadItems();
            loadedPages.itemsManager = true;
            currentPage = 'itemsManager';
            clearTimeout(timeoutId);
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('home').style.display = 'none';
        document.getElementById('items-manager').style.display = 'block';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('items-button');
    });

    // Onsite button
    document.getElementById('onsite-button').addEventListener('mousedown', async () => {
        if (!loadedPages.onsite) {
            const button = document.getElementById('onsite-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadOnsite();
            clearTimeout(timeoutId);
            loadedPages.onsite = true;
            currentPage = 'onsite';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'block';
        document.getElementById('home').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('onsite-button');
    });

    // All students button
    document.getElementById('home-button').addEventListener('mousedown', async () => {
        if (!loadedPages.home) {
            const button = document.getElementById('home-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await fillAllStudentsTable();
            loadedPages.home = true;
            currentPage = 'home';
            clearTimeout(timeoutId);
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('home').style.display = 'block';
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('home-button');
    });

    // events-button
    document.getElementById('events-button').addEventListener('mousedown', async () => {
        if (!loadedPages.events) {
            const button = document.getElementById('events-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadEvents();
            clearTimeout(timeoutId);
            loadedPages.events = true;
            currentPage = 'events';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('events').style.display = 'block';
        document.getElementById('home').style.display = 'none';
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        setActiveButton('events-button');
    });
    // Event listener for the user-button toggle
    document.getElementById('user-button').addEventListener('toggle', () => {
        const userButtonDetails = document.getElementById('user-button');
        const activeButton = document.getElementById(activeButtonId);

        if (userButtonDetails.hasAttribute('open')) {
            // When the menu is opened, make the active button transparent
            if (activeButton) {
                document.getElementById('hi-name').style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-focus');
                activeButton.style.backgroundColor = 'transparent';
            }
        } else {
            // When the menu is closed, restore the active button's background color
            if (activeButton) {
                activeButton.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-focus');
                // Remove the colour from hi-name - back to transparent
                document.getElementById('hi-name').style.backgroundColor = 'transparent';
            }
        }
    });

    // manage-badges-button
    document.getElementById('manage-badges-button').addEventListener('mousedown', async () => {
        if (!loadedPages.manageBadges) {
            const button = document.getElementById('manage-badges-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadBadges();
            clearTimeout(timeoutId);
            loadedPages.manageBadges = true;
            currentPage = 'manageBadges';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        // show the div, hide the others
        document.getElementById('home').style.display = 'none';
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'block';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'none';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // set other buttons to transparent
        setActiveButton('manage-badges-button');
    });


    // Name filter
    document.getElementById('searchInput').addEventListener('keyup', async function () {
        window.historyFilters.name = this.value.toLowerCase();
        await applyHistoryFilters();
    });
    // edit-badge-modal-button - close modal and clear HTML
    document.getElementById('edit-badge-modal-button').addEventListener('mousedown', async () => {
        closeModal(document.getElementById('edit-badge-modal'));
        // disable badge listener
        disableBadgeListener();
        // wait 450ms - modal takes 400ms to close
        await delay(450);
        // clear HTML under edit-badge-detail
        document.getElementById('edit-badge-detail').innerHTML = '';
    });

    // same for edit-badge-modal-close
    document.getElementById('edit-badge-modal-close').addEventListener('mousedown', async () => {
        closeModal(document.getElementById('edit-badge-modal'));
        // disable badge listener
        disableBadgeListener();
        // wait 450ms - modal takes 400ms to close
        await delay(450);
        // clear HTML under edit-badge-detail
        document.getElementById('edit-badge-detail').innerHTML = '';
    });

    // fire register button in user menu
    document.getElementById('fire-register-button').addEventListener('mousedown', async () => {
        if (!loadedPages.fireRegister) {
            const button = document.getElementById('fire-register-button');
            const timeoutId = setTimeout(() => {
                button.setAttribute('aria-busy', 'true');
            }, 100);
            await loadFireRegisters();
            clearTimeout(timeoutId);
            loadedPages.fireRegister = true;
            currentPage = 'fireRegister';
            if (button.getAttribute('aria-busy') === 'true') {
                button.setAttribute('aria-busy', 'false');
            }
        }

        console.log('fire register button clicked');
        // switch to fire register div
        document.getElementById('home').style.display = 'none';
        document.getElementById('history').style.display = 'none';
        document.getElementById('student-manager').style.display = 'none';
        document.getElementById('admin').style.display = 'none';
        document.getElementById('onsite').style.display = 'none';
        document.getElementById('items-manager').style.display = 'none';
        document.getElementById('manage-badges').style.display = 'none';
        document.getElementById('events').style.display = 'none';
        document.getElementById('fire-register').style.display = 'block';
        document.getElementById('kiosks').style.display = 'none';
        document.getElementById('enrolment-mode').style.display = 'none';
        document.getElementById('view-enrolment-mode').style.display = 'none';

        // reset all the other nav buttons
        resetButtonStyles();
    });

    // start-fire-register - go to /fire?start=true
    document.getElementById('start-fire-register').addEventListener('mousedown', async () => {
        const button = document.getElementById('start-fire-register');
        const timeoutId = setTimeout(() => {
            button.setAttribute('aria-busy', 'true');
        }, 100);
        redirectWithDomainParam('fire', { start: true });
        clearTimeout(timeoutId);
        loadedPages.fireRegister = true;
        currentPage = 'fireRegister';
        if (button.getAttribute('aria-busy') === 'true') {
            button.setAttribute('aria-busy', 'false');
        }
    });

    // Date filter
    // Date filter
    document.getElementById('dateInput').addEventListener('change', async function () {
        var selectedDates = this.value.split(' to ');

        if (selectedDates.length === 1) {
            // Only one date selected, treat it as a single date
            selectedDates = [selectedDates[0], selectedDates[0]];
        }

        // if null or empty, reset to today
        if (selectedDates[0] === null || selectedDates[0] === '' || selectedDates[1] === null || selectedDates[1] === '') {
            console.log('date range cleared - resetting to today');
            const today = new Date().toLocaleDateString('en-GB');
            selectedDates = [today, today];
            document.getElementById('dateInput').value = `${today} to ${today}`;
        }

        console.log(`date range changed to ${selectedDates}`);

        window.historyFilters.startDate = selectedDates[0];
        window.historyFilters.endDate = selectedDates[1];

        await fillHistory(true); // reload the table with the new date range
    });


    var dropdown = document.getElementById('actionDropdown');
    var summary = document.getElementById('actionFilter');
    dropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        summary.textContent = selectedOption;
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        window.historyFilters.action = selectedOption;
        await applyHistoryFilters();
    });

    // same as above for form
    var formDropdown = document.getElementById('formDropdown');
    var formSummary = document.getElementById('formFilter');
    formDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        formSummary.textContent = selectedOption;
        formSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        window.historyFilters.formGroup = selectedOption;
        await applyHistoryFilters();
    });

    // same as above for y12 onsite form filter
    var y12OnsiteDropdown = document.getElementById('y12-formDropdown');
    var y12OnsiteSummary = document.getElementById('y12-formFilter');
    y12OnsiteDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        y12OnsiteSummary.textContent = selectedOption;
        y12OnsiteSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        y12OnsiteFilters.formGroup = selectedOption;
        await applyOnsiteFilters('12');
    });

    // same as above for y13 onsite form filter
    var y13OnsiteDropdown = document.getElementById('y13-formDropdown');
    var y13OnsiteSummary = document.getElementById('y13-formFilter');
    y13OnsiteDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        y13OnsiteSummary.textContent = selectedOption;
        y13OnsiteSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        y13OnsiteFilters.formGroup = selectedOption;
        await applyOnsiteFilters('13');
    });

    // same as above for y12 all students form filter
    var y12AllStudentsDropdown = document.getElementById('y12-all-formDropdown');
    var y12AllStudentsSummary = document.getElementById('y12-all-formFilter');
    y12AllStudentsDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        y12AllStudentsSummary.textContent = selectedOption;
        y12AllStudentsSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        y12AllStudentsFilters.formGroup = selectedOption;
        await applyAllStudentFilters('12', y12AllStudentsFilters);
    });

    // same as above for y13 all students form filter
    var y13AllStudentsDropdown = document.getElementById('y13-all-formDropdown');
    var y13AllStudentsSummary = document.getElementById('y13-all-formFilter');
    y13AllStudentsDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        y13AllStudentsSummary.textContent = selectedOption;
        y13AllStudentsSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        y13AllStudentsFilters.formGroup = selectedOption;
        await applyAllStudentFilters('13', y13AllStudentsFilters);
    });

    // same as above for items type
    var itemsTypeDropdown = document.getElementById('itemTypeDropdown');
    var itemsTypeSummary = document.getElementById('itemTypeFilter');
    itemsTypeDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        itemsTypeSummary.textContent = selectedOption;
        itemsTypeSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        window.itemTypeFilter.type = selectedOption;
        await applyItemFilters();
    });

    // item history name filter
    document.getElementById('item-history-search').addEventListener('input', async function () {
        window.itemHistoryFilters.name = this.value.toLowerCase();
        await applyItemHistoryFilters();
    });

    // item history action filter
    var itemHistoryActionDropdown = document.getElementById('item-history-actionDropdown');
    var itemHistoryActionSummary = document.getElementById('item-history-actionFilter');
    itemHistoryActionDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        itemHistoryActionSummary.textContent = selectedOption;
        itemHistoryActionSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        window.itemHistoryFilters.action = selectedOption;
        await applyItemHistoryFilters();
    });

    // item history form group filter
    var itemHistoryFormGroupDropdown = document.getElementById('item-history-formDropdown');
    var itemHistoryFormGroupSummary = document.getElementById('item-history-formFilter');
    itemHistoryFormGroupDropdown.addEventListener('change', async function () {
        var selectedOption = document.querySelector('input[name="size"]:checked').nextSibling.textContent.trim();
        itemHistoryFormGroupSummary.textContent = selectedOption;
        itemHistoryFormGroupSummary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');

        window.itemHistoryFilters.formGroup = selectedOption;
        await applyItemHistoryFilters();
    });

    // item history date filter
    document.getElementById('item-dateInput').addEventListener('change', async function () {
        const dateValue = this.value;
        if (dateValue) {
            window.itemHistoryFilters.date = new Date(dateValue).toISOString().split('T')[0];
        } else {
            window.itemHistoryFilters.date = null;
        }
        console.log('item history date filter:', window.itemHistoryFilters.date);
        await applyItemHistoryFilters();
    });


    // edit-badge-modal-close
    document.getElementById('edit-badge-modal-close').addEventListener('mousedown', async () => {
        closeModal(document.getElementById('edit-badge-modal'));
    });

    // edit-badge-modal-button
    document.getElementById('edit-badge-modal-button').addEventListener('mousedown', async () => {
        closeModal(document.getElementById('edit-badge-modal'));
    });

    // Stop the enter key from closing the modal
    const modal = document.getElementById('batch-add-modal');
    // Set up a MutationObserver to watch for changes in the modal
    const observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
                const isOpen = modal.getAttribute('open');
                // Check if the modal is closed
                if (isOpen === null || isOpen === 'false') {
                    removeKeydownListener();
                    observer.disconnect();
                }
            }
        }
    });

    // Start observing the modal for attribute changes
    observer.observe(modal, { attributes: true });

    // confirm-delete-button - in the modal
    document.getElementById('confirm-delete-button').addEventListener('mousedown', async () => {
        console.log('Deleting something...');
        // set aria-busy to true
        document.getElementById('confirm-delete-button').setAttribute('aria-busy', 'true');
        await deleteObject();
        document.getElementById('confirm-delete-button').setAttribute('aria-busy', 'false');
    });

    // create-user-button
    document.getElementById('create-user-button').addEventListener('mousedown', async () => {
        console.log('Opening create user...');
        // set aria-busy to true
        document.getElementById('create-user-button').setAttribute('aria-busy', 'true');
        await fillCreateUserModal();
        // show the modal
        openModal(document.getElementById('new-user-modal'));
        // set aria-busy to false
        document.getElementById('create-user-button').setAttribute('aria-busy', 'false');
    });

    // new-item-button
    document.getElementById('new-item-button').addEventListener('mousedown', async () => {
        console.log('Opening new item...');
        // set aria-busy to true
        document.getElementById('new-item-button').setAttribute('aria-busy', 'true');
        // fill the item type dropdown
        await fillNewItemFormTypeDropdown();
        // clear the error message
        document.getElementById('items-csv-import-error').innerHTML = '';
        // clear any imported files
        document.getElementById('items-csv-import').value = '';
        // show the modal
        openModal(document.getElementById('new-item-modal'));
        // set aria-busy to false
        document.getElementById('new-item-button').setAttribute('aria-busy', 'false');
    });

    // create-new-item-btn - in the modal
    document.getElementById('create-new-item-btn').addEventListener('mousedown', async () => {
        console.log('Creating new item...');
        await createNewItem();
    });

    // newUser-roleOptions - when a role is changed
    document.getElementById('newUser-roleOptions').addEventListener('change', async () => {
        console.log('Role changed...');
        // set aria-busy to true
        document.getElementById('newUser-roleOptions').setAttribute('aria-busy', 'true');
        await handleNewUserRoleChange();
        // set aria-busy to false
        document.getElementById('newUser-roleOptions').setAttribute('aria-busy', 'false');
    });


    // create-new-user-btn - in the modal
    document.getElementById('create-new-user-btn').addEventListener('mousedown', async () => {
        console.log('Creating new user...');
        // set aria-busy to true
        document.getElementById('create-new-user-btn').setAttribute('aria-busy', 'true');
        await createNewUser();
        // set aria-busy to false
        document.getElementById('create-new-user-btn').setAttribute('aria-busy', 'false');
    });

    // y12-onsite-search - when the search input is changed
    document.getElementById('y12-onsite-search').addEventListener('keyup', async () => {
        console.log('y12-onsite-search changed...');
        y12OnsiteFilters.name = document.getElementById('y12-onsite-search').value.toLowerCase();

        await applyOnsiteFilters('12');
    });

    // y13-onsite-search - when the search input is changed
    document.getElementById('y13-onsite-search').addEventListener('keyup', async () => {
        console.log('y13-onsite-search changed...');
        y13OnsiteFilters.name = document.getElementById('y13-onsite-search').value.toLowerCase();

        await applyOnsiteFilters('13');
    });

    // y12-all-search - when the search input is changed
    document.getElementById('y12-all-search').addEventListener('keyup', async () => {
        console.log('y12-all-search changed...');
        y12AllStudentsFilters.name = document.getElementById('y12-all-search').value.toLowerCase();

        await applyAllStudentFilters('12', y12AllStudentsFilters);
    });

    // y13-all-search - when the search input is changed
    document.getElementById('y13-all-search').addEventListener('keyup', async () => {
        console.log('y13-all-search changed...');
        y13AllStudentsFilters.name = document.getElementById('y13-all-search').value.toLowerCase();

        await applyAllStudentFilters('13', y13AllStudentsFilters);
    });

    // Reload all data when the tab is focused
    window.addEventListener('focus', async () => {
        console.log('Tab focused - reloading data...');
        await reloadData();
    });

    // disable-badge-confirm - when the button is clicked
    document.getElementById('disable-badge-confirm').addEventListener('mousedown', async () => {
        console.log('Disabling badge...');
        // set aria-busy to true
        document.getElementById('disable-badge-confirm').setAttribute('aria-busy', 'true');
        await disableBadge();
        // set aria-busy to false
        document.getElementById('disable-badge-confirm').setAttribute('aria-busy', 'false');
    });

    // array of button IDs that just need to close the modal
    ///// TODO: just do .close class
    const closeModals = ['disable-badge-cancel', 'disable-badge-confirm', 'confirm-delete-close', 'new-user-close', 'feedback-close', 'new-item-close', 'loan-item-close', 'item-history-modal-close', 'edit-admin-badge-modal-close', 'new-item-type-close', 'loan-ban-cancel-btn', 'loan-ban-close', 'identify-badge-modal-close', 'assign-badge-modal-close', 'new-temp-badge-modal-close', 'badge-history-modal-close', 'bromcom-sync-close', 'event-attendance-modal-close', 'new-event-close', 'fire-register-modal-close', 'edit-event-close', 'export-events-close', 'export-history-close', 'export-item-history-close', 'student-export-close', 'disable-badge-modal-close'];

    // add event listeners to all of them
    closeModals.forEach(buttonId => {
        document.getElementById(buttonId).addEventListener('mousedown', async () => {
            // close the modal of the data-target of the button
            closeModal(document.getElementById(document.getElementById(buttonId).getAttribute('data-target')));
            // stop the badge listener, not always used but just in case
            disableBadgeListener();
        });
    });

    // confirm-delete-cancel
    document.getElementById('confirm-delete-cancel').addEventListener('mousedown', async () => {
        closeModal(document.getElementById('confirm-delete-modal'));
        window.deletingObjectID = null;
        window.deletingObjectType = null;
    });

    // batch-add-modal-close - close, and unsubscribe from firestore
    document.getElementById('batch-add-modal-close').addEventListener('mousedown', async () => {
        console.log('batch-add-modal-close clicked');
        // close the modal
        closeModal(document.getElementById('batch-add-modal'));
        // stop the badge listener
        disableBadgeListener();
        // unsubscribe from the firestore listener
        if (batchUnsubscribe) {
            batchUnsubscribe();
            batchUnsubscribe = null; // reset
        }
    });

    // feedbackButton - open the feedback modal
    document.getElementById('feedbackButton').addEventListener('mousedown', async () => {
        console.log('feedback button clicked');
        // fill the modal
        await fillFeedbackModal();
        // show the modal
        openModal(document.getElementById('feedback-modal'));
    });

    // submit-feedback-btn - submit feedback
    document.getElementById('submit-feedback-btn').addEventListener('mousedown', async () => {
        console.log('Submitting feedback...');
        // set aria-busy to true
        document.getElementById('submit-feedback-btn').setAttribute('aria-busy', 'true');
        await submitFeedback();
        // set aria-busy to false
        document.getElementById('submit-feedback-btn').setAttribute('aria-busy', 'false');
    });

    // fill the loan search modal when search query changes
    document.getElementById('loan-item-search').addEventListener('input', async function () {
        await loanSearchNameChanged();
    });

    document.getElementById('loan-item-search').addEventListener('keydown', async function (event) {
        await selectCurrentItemLoanStudent(event);
    });

    document.getElementById('batch-search').addEventListener('input', async function () {
        await batchSearchNameChanged();
    });

    // assign-badge-search
    document.getElementById('assign-badge-search').addEventListener('input', async function () {
        await badgeSearchNameChanged();
    });

    // new-item-type-button - open the new item type modal
    document.getElementById('new-item-type-button').addEventListener('mousedown', async () => {
        console.log('new item type button clicked');
        window.renamingItemTypeID = null;
        // load the new item type modal
        await loadKiosksInItemType();
        // reset the modal
        document.getElementById('new-item-type-title').innerText = 'New Item Type';
        document.getElementById('create-new-item-type-btn').innerHTML = 'Create';
        document.getElementById('new-item-type-name').value = '';
        // show the modal
        openModal(document.getElementById('new-item-type-modal'));
    });

    // create-new-item-type-btn - create a new item type
    document.getElementById('create-new-item-type-btn').addEventListener('mousedown', async () => {
        console.log('Creating new item type...');
        await createNewItemType();
    });

    // end-of-today-btn
    document.getElementById('end-of-today-btn').addEventListener('mousedown', async () => {
        console.log('end-of-today-btn clicked');
        updateDatePicker('end-of-today');
        // remove outline class from this button
        document.getElementById('end-of-today-btn').classList.remove('outline');
        // add outline class to the other buttons
        document.getElementById('end-of-tomorrow-btn').classList.add('outline');
        document.getElementById('end-of-week-btn').classList.add('outline');
        document.getElementById('indefinitely-btn').classList.add('outline');
    });

    // end-of-tomorrow-btn
    document.getElementById('end-of-tomorrow-btn').addEventListener('mousedown', async () => {
        console.log('end-of-tomorrow-btn clicked');
        updateDatePicker('end-of-tomorrow');
        // remove outline class from this button
        document.getElementById('end-of-tomorrow-btn').classList.remove('outline');
        // add outline class to the other buttons
        document.getElementById('end-of-today-btn').classList.add('outline');
        document.getElementById('end-of-week-btn').classList.add('outline');
        document.getElementById('indefinitely-btn').classList.add('outline');
    });

    // end-of-week-btn
    document.getElementById('end-of-week-btn').addEventListener('mousedown', async () => {
        console.log('end-of-week-btn clicked');
        updateDatePicker('end-of-week');
        // remove outline class from this button
        document.getElementById('end-of-week-btn').classList.remove('outline');
        // add outline class to the other buttons
        document.getElementById('end-of-today-btn').classList.add('outline');
        document.getElementById('end-of-tomorrow-btn').classList.add('outline');
        document.getElementById('indefinitely-btn').classList.add('outline');
    });

    // indefinitely-btn
    document.getElementById('indefinitely-btn').addEventListener('mousedown', async () => {
        console.log('indefinitely-btn clicked');
        updateDatePicker('indefinitely');
        // remove outline class from this button
        document.getElementById('indefinitely-btn').classList.remove('outline');
        // add outline class to the other buttons
        document.getElementById('end-of-today-btn').classList.add('outline');
        document.getElementById('end-of-tomorrow-btn').classList.add('outline');
        document.getElementById('end-of-week-btn').classList.add('outline');
    });

    // loan-ban-btn - save the loan ban
    document.getElementById('loan-ban-btn').addEventListener('mousedown', async () => {
        console.log('loan-ban-btn clicked');
        // set aria-busy to true
        document.getElementById('loan-ban-btn').setAttribute('aria-busy', 'true');
        await saveLoanBan();
        // set aria-busy to false
        document.getElementById('loan-ban-btn').setAttribute('aria-busy', 'false');
    });

    // batch-add-button - open the modal
    document.getElementById('batch-add-button').addEventListener('mousedown', async () => {
        console.log('batch-add-button clicked');
        document.getElementById('batch-add-button').setAttribute('aria-busy', 'true');
        await prepareBatchStudentSearch();
        // show the modal
        openModal(document.getElementById('batch-add-modal'));
        document.getElementById('batch-add-button').setAttribute('aria-busy', 'false');
        await delay(200);
        // focus the search box
        document.getElementById('batch-search').focus();
    });

    // identify-badge-button - open the modal
    document.getElementById('identify-badge-button').addEventListener('mousedown', async () => {
        console.log('identify-badge-button clicked');
        loadIdentifyBadge();
        // show the modal
        openModal(document.getElementById('identify-badge-modal'));
    });

    document.querySelector('.clear-input').addEventListener('mousedown', async function () {
        document.getElementById('batch-search').value = ''; // Clear the input
        document.getElementById('batch-search').focus(); // Refocus the input
        await batchSearchNameChanged();
    });

    // undo-batch-add - undo the last batch add
    document.getElementById('undo-batch-add').addEventListener('mousedown', async () => {
        console.log('undo-batch-add clicked');
        // set aria-busy to true
        document.getElementById('undo-batch-add').setAttribute('aria-busy', 'true');
        await undoBatchAdd();
        // set aria-busy to false
        document.getElementById('undo-batch-add').setAttribute('aria-busy', 'false');
    });

    document.getElementById('batch-search').addEventListener('keydown', async function (event) {
        const rows = document.querySelectorAll('#batch-table tbody tr');

        if (event.key === 'Enter') {
            event.preventDefault();
            if (batchTableIndex >= 0) {
                await selectCurrentResult();
            }
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (batchTableIndex < rows.length - 1) {
                batchTableIndex++;
                updateRowSelection(rows);
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (batchTableIndex > 0) {
                batchTableIndex--;
                updateRowSelection(rows);
            }
        }
    });

    // new-temp-badge-button - open the modal
    document.getElementById('new-temp-badge-button').addEventListener('mousedown', async () => {
        console.log('new-temp-badge-button clicked');
        loadNewTempBadge();
        // show the modal
        openModal(document.getElementById('new-temp-badge-modal'));
    });

    // confirm-create-temp-badge-button - create the temp badge
    document.getElementById('confirm-create-temp-badge-button').addEventListener('mousedown', async () => {
        console.log('confirm-create-temp-badge-button clicked');
        // set aria-busy to true
        document.getElementById('confirm-create-temp-badge-button').setAttribute('aria-busy', 'true');
        await createTempBadge();
        // set aria-busy to false
        document.getElementById('confirm-create-temp-badge-button').setAttribute('aria-busy', 'false');
        // close the modal
        closeModal(document.getElementById('new-temp-badge-modal'));
    });

    // new-event-button
    document.getElementById('new-event-button').addEventListener('mousedown', async () => {
        console.log('new-event-button clicked');
        // set aria-busy to true
        document.getElementById('new-event-button').setAttribute('aria-busy', 'true');
        await fillNewEventModal();
        // show the modal
        openModal(document.getElementById('new-event-modal'));
        document.getElementById('new-event-button').setAttribute('aria-busy', 'false');
    });

    // create-new-event-btn
    document.getElementById('create-new-event-btn').addEventListener('mousedown', async () => {
        console.log('create-new-event-btn clicked');
        // set aria-busy to true
        document.getElementById('create-new-event-btn').setAttribute('aria-busy', 'true');
        await createNewEvent();
        // set aria-busy to false
        document.getElementById('create-new-event-btn').setAttribute('aria-busy', 'false');
    });


    // fire-archived
    var checkbox = document.getElementById('fire-archived');
    checkbox.addEventListener('change', async function () {
        await loadFireRegisters();
    });

    // event-archived
    var eventCheckbox = document.getElementById('event-archived');
    eventCheckbox.addEventListener('change', async function () {
        await loadEvents(eventCheckbox.checked);
    });

    // edit-event-btn
    document.getElementById('edit-event-btn').addEventListener('mousedown', async () => {
        console.log('edit-event-btn clicked');
        // set aria-busy to true
        document.getElementById('edit-event-btn').setAttribute('aria-busy', 'true');
        await editEvent(); // don't close after, as there may be an error editing the event that the user wants to fix
        // set aria-busy to false
        document.getElementById('edit-event-btn').setAttribute('aria-busy', 'false');
    });

    // download-badges-button
    document.getElementById('download-badges-button').addEventListener('mousedown', async () => {
        console.log('download-badges-button clicked');
        // set aria-busy to true
        document.getElementById('download-badges-button').setAttribute('aria-busy', 'true');
        // open modal
        openModal(document.getElementById('student-export-modal'));
        // set aria-busy to false
        document.getElementById('download-badges-button').setAttribute('aria-busy', 'false');
    });

    // export-history-button
    document.getElementById('export-history-button').addEventListener('mousedown', async () => {
        console.log('export-history-button clicked');
        // set aria-busy to true
        document.getElementById('export-history-button').setAttribute('aria-busy', 'true');
        await countHistoryExports();
        // open modal
        openModal(document.getElementById('history-export-modal'));
        // set aria-busy to false
        document.getElementById('export-history-button').setAttribute('aria-busy', 'false');
    });

    // history-download-pdf
    document.getElementById('history-download-pdf').addEventListener('mousedown', async () => {
        console.log('history-download-pdf clicked');
        // set aria-busy to true
        document.getElementById('history-download-pdf').setAttribute('aria-busy', 'true');
        await exportHistory('pdf');
        // set aria-busy to false
        document.getElementById('history-download-pdf').setAttribute('aria-busy', 'false');
    });

    // history-download-csv
    document.getElementById('history-download-csv').addEventListener('mousedown', async () => {
        console.log('history-download-csv clicked');
        // set aria-busy to true
        document.getElementById('history-download-csv').setAttribute('aria-busy', 'true');
        await exportHistory('csv');
        // set aria-busy to false
        document.getElementById('history-download-csv').setAttribute('aria-busy', 'false');
    });

    // history-download-sheet
    document.getElementById('history-download-sheet').addEventListener('mousedown', async () => {
        console.log('history-download-sheet clicked');
        // set aria-busy to true
        document.getElementById('history-download-sheet').setAttribute('aria-busy', 'true');
        await exportHistory('sheet');
        // set aria-busy to false
        document.getElementById('history-download-sheet').setAttribute('aria-busy', 'false');
    });

    // export-item-history-button
    document.getElementById('export-item-history-button').addEventListener('mousedown', async () => {
        console.log('export-item-history-button clicked');
        // close item history modal, open item history export modal
        closeModal(document.getElementById('item-history-modal'));
        await delay(500);
        openModal(document.getElementById('item-history-export-modal'));
    });

    // item-history-download-pdf
    document.getElementById('item-history-download-pdf').addEventListener('mousedown', async () => {
        console.log('item-history-download-pdf clicked');
        // set aria-busy to true
        document.getElementById('item-history-download-pdf').setAttribute('aria-busy', 'true');
        // get the data-item-id
        const itemID = document.getElementById('export-item-history-button').getAttribute('data-item-id');
        await exportItemHistory(itemID, 'pdf');
        // set aria-busy to false
        document.getElementById('item-history-download-pdf').setAttribute('aria-busy', 'false');
    });

    // item-history-download-csv
    document.getElementById('item-history-download-csv').addEventListener('mousedown', async () => {
        console.log('item-history-download-csv clicked');
        // set aria-busy to true
        document.getElementById('item-history-download-csv').setAttribute('aria-busy', 'true');
        // get the data-item-id
        const itemID = document.getElementById('export-item-history-button').getAttribute('data-item-id');
        await exportItemHistory(itemID, 'csv');
        // set aria-busy to false
        document.getElementById('item-history-download-csv').setAttribute('aria-busy', 'false');
    });

    // item-history-download-sheet
    document.getElementById('item-history-download-sheet').addEventListener('mousedown', async () => {
        console.log('item-history-download-sheet clicked');
        // set aria-busy to true
        document.getElementById('item-history-download-sheet').setAttribute('aria-busy', 'true');
        // get the data-item-id
        const itemID = document.getElementById('export-item-history-button').getAttribute('data-item-id');
        await exportItemHistory(itemID, 'sheet');
        // set aria-busy to false
        document.getElementById('item-history-download-sheet').setAttribute('aria-busy', 'false');
    });

    // student-download-pdf
    document.getElementById('student-download-pdf').addEventListener('mousedown', async () => {
        console.log('student-download-pdf clicked');
        // set aria-busy to true
        document.getElementById('student-download-pdf').setAttribute('aria-busy', 'true');
        // get the data-item-id
        await exportStudents('pdf');
        // set aria-busy to false
        document.getElementById('student-download-pdf').setAttribute('aria-busy', 'false');
    });

    // student-download-csv
    document.getElementById('student-download-csv').addEventListener('mousedown', async () => {
        console.log('student-download-csv clicked');
        // set aria-busy to true
        document.getElementById('student-download-csv').setAttribute('aria-busy', 'true');
        // get the data-item-id
        await exportStudents('csv');
        // set aria-busy to false
        document.getElementById('student-download-csv').setAttribute('aria-busy', 'false');
    });

    // student-download-sheet
    document.getElementById('student-download-sheet').addEventListener('mousedown', async () => {
        console.log('student-download-sheet clicked');
        // set aria-busy to true
        document.getElementById('student-download-sheet').setAttribute('aria-busy', 'true');
        // get the data-item-id
        await exportStudents('sheet');
        // set aria-busy to false
        document.getElementById('student-download-sheet').setAttribute('aria-busy', 'false');
    });

    // history-registration - toggle
    document.getElementById('history-registration').addEventListener('mousedown', async () => {
        console.log('history-registration clicked');
        await fillHistory(true);
    });

    const refreshButton = document.getElementById('refresh-history-button');

    refreshButton.addEventListener('mousedown', async () => {
        console.log('refresh-history-button clicked');
        document.getElementById('refresh-history-button').setAttribute('aria-busy', 'true');
        await fillHistory(true);
        document.getElementById('refresh-history-button').setAttribute('aria-busy', 'false');
    });

    // Ensure tooltip data attributes are set
    refreshButton.setAttribute('data-tooltip-custom', 'There are too many records to automatically refresh. Click here to update.');
    refreshButton.setAttribute('data-placement-tooltip', 'left');

    // Show tooltip on hover
    refreshButton.addEventListener('mouseenter', () => simulateTooltipHover(refreshButton, false));
    refreshButton.addEventListener('mouseleave', () => hideTooltip(refreshButton));

    // History back to top button
    document.getElementById('back-to-top').addEventListener('mousedown', () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    window.addEventListener('scroll', toggleBackToTopButton);
    // Initial check in case the user reloads the page and it's already scrolled down
    toggleBackToTopButton();

    // when an item import CSV is selected, validate it
    document.getElementById('items-csv-import').addEventListener('change', async function () {
        const file = document.getElementById('items-csv-import').files[0] || null;
        // if there's a file, call the function
        if (file) {
            await validateCSVImport();
        }
    });

    // loan-item-admin-button - enter custom note
    document.getElementById('loan-item-admin-button').addEventListener('click', async function () {
        if (window.loanItemAdminNote) {
            // currently showing note - switch back to table
            document.getElementById('loan-item-table').style.display = 'table';
            document.getElementById('loan-item-search').style.display = 'block';
            document.getElementById('loan-item-admin-note').style.display = 'none';
            // change the button to go back to the admin note
            document.getElementById('loan-item-admin-button').innerText = 'Enter custom note...';
            // hide the save button
            document.getElementById('loan-item-admin-save').style.display = 'none';
            document.getElementById('loan-item-title').innerText = 'Select Student';
            window.loanItemAdminNote = false;
        } else {
            // hide the table and search, show the custom note input
            document.getElementById('loan-item-table').style.display = 'none';
            document.getElementById('loan-item-search').style.display = 'none';
            document.getElementById('loan-item-admin-note').style.display = 'block';
            // change the button to go back to the table
            document.getElementById('loan-item-admin-button').innerText = 'Select a student...';
            // show the save button
            document.getElementById('loan-item-admin-save').style.display = 'block';
            document.getElementById('loan-item-title').innerText = 'Enter Note (optional)';
            window.loanItemAdminNote = true;
        }
    });

    document.getElementById('loan-item-admin-save').addEventListener('click', async function () {
        console.log('loan-item-admin-save clicked');
        // set aria-busy to true
        document.getElementById('loan-item-admin-save').setAttribute('aria-busy', 'true');
        await processManualLoan();
        // set aria-busy to false
        document.getElementById('loan-item-admin-save').setAttribute('aria-busy', 'false');
    });

    // on blur, remove the active class
    window.addEventListener('blur', function () {
        document.getElementById('uipane').classList.remove('active-tab');
    });

    // switch interval button
    document.getElementById('switch-interval-button').addEventListener('mousedown', async () => {
        console.log('Switching interval...');
        document.getElementById('switch-interval-button').setAttribute('aria-busy', 'true');

        // load all the intervals from the DB
        const intervalsRef = query(collection(db, 'domains', window.userDomain, 'intervals'), orderBy('name'));
        const intervalsSnapshot = await getDocs(intervalsRef);

        // get the default interval
        let defaultInterval = null;
        const domainRef = doc(db, 'domains', window.userDomain);
        const domainDoc = await getDoc(domainRef);
        if (domainDoc.exists()) {
            const domainData = domainDoc.data();
            defaultInterval = domainData.currentInterval;
            console.log('Default interval:', defaultInterval);
        } else {
            console.error('No such document!');
            showError('4004', 'Error Loading Academic Years', 'There was an error loading the academic years.', null, false, false, false);
            return;
        }

        const intervals = [];
        intervalsSnapshot.docs.forEach(doc => {
            let name = doc.data().name;
            const originalName = name;  // Keep the original name for comparison

            // if current interval, add ' - Selected' to the name
            if (originalName.replace('-', '_') === window.currentInterval) {
                name += ' - Selected';
            }

            // if the default interval, add ' - Default' to the name
            if (originalName.replace('-', '_') === defaultInterval) {
                name += ' - Default';
            }

            intervals.push(name);
        });

        // show in a list dialog
        await showListDialog('Select Academic Year', 'Select an academic year to view all data within that year.', intervals, false).then(async (result) => {
            if (result) {
                // Successfully selected an interval
                console.log('selected interval:', result);

                // if it contains selected, ignore - we're already on the selected interval
                if (result.includes(' - Selected')) {
                    console.log('ignoring current interval');
                    return;
                }

                // remove selected from the name
                result = result.replace(' - Selected', '').replace(' - Default', '').replace('-', '_');
                console.log('selected parsed interval:', result);

                if (result === defaultInterval) {
                    console.log('reloading with default interval');
                    // remove the interval override param to revert to the default
                    redirectWithDomainParam('', {}, ['interval']);
                    return;
                } else {
                    console.log('redirecting with override interval');
                    // user has selected a different interval, reload to that
                    redirectWithDomainParam('', { interval: result });
                    return;
                }
            }
        }).catch(async error => {
            console.error('Error selecting interval:', error);
        });

        document.getElementById('switch-interval-button').setAttribute('aria-busy', 'false');
    });

}

export async function exportStudents(format) {
    console.log('downloading student badges...');

    var exportData = [];

    // get all the students
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), orderBy('index', 'asc'));
    const studentsSnapshot = await getDocs(studentsRef);

    studentsSnapshot.forEach(async (doc) => {
        const studentData = doc.data();

        const misID = studentData.misID;
        const studentName = studentData.studentName;
        const formGroup = studentData.formGroup;
        const personalBadgeID = studentData.personalBadgeID || 'None';
        const badgeDisabled = studentData.badgeDisabled ? 'Yes' : 'No';
        const tempBadge = studentData.tempBadgeAssigned ? 'Yes' : 'No';
        const loanPermission = studentData.loanPermission ? 'Yes' : 'No';

        if (format === 'sheet') {
            exportData.push([
                misID,
                studentName,
                formGroup,
                personalBadgeID,
                badgeDisabled,
                tempBadge,
                loanPermission
            ]);
        } else {
            exportData.push({
                StudentID: misID,
                Name: studentName,
                FormGroup: formGroup,
                BadgeID: personalBadgeID,
                BadgeDisabled: badgeDisabled,
                TemporaryBadge: tempBadge,
                LoanPermission: loanPermission
            });
        }
    });

    if (format === 'pdf') {
        downloadPDF('Student Export', '', ['StudentID', 'Name', 'FormGroup', 'BadgeID', 'BadgeDisabled', 'TemporaryBadge', 'LoanPermission'], exportData, 'student-export');
    } else if (format === 'csv') {
        downloadCSV(exportData, ['StudentID', 'Name', 'FormGroup', 'BadgeID', 'BadgeDisabled', 'TemporaryBadge', 'LoanPermission'], 'student-export');
    } else if (format === 'sheet') {
        const spreadsheet = await createSpreadsheet('Student Export', ['StudentID', 'Name', 'Form Group', 'Badge ID', 'Badge Disabled', 'Temporary Badge', 'Loan Permission'], exportData);
        if (spreadsheet) {
            console.log('Spreadsheet created - ID:', spreadsheet.spreadsheetId);
            // open the URL - spreadsheetUrl
            window.open(spreadsheet.spreadsheetUrl, '_blank');
        }
        // if there was an error, the function handles it
    }
}

function redirectWithDomainParam(target, extraParams = {}, removeParams = []) {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    // Merge existing params with extraParams, with extraParams taking precedence
    for (const [key, value] of Object.entries(extraParams)) {
        params.set(key, value);
    }

    // Remove any params that are in removeParams
    for (const param of removeParams) {
        params.delete(param);
    }

    // Check if 'domain' is present and ensure it's included in the new URL
    if (params.has('domain')) {
        const domainParam = params.get('domain');
        params.set('domain', domainParam);
    }

    window.location.href = `/${target}?${params.toString()}`;
}

function updateRowSelection(rows) {
    // Reset the background color for all rows
    rows.forEach(row => {
        row.style.backgroundColor = ''; // Reset to default
    });

    // Set the background color for the currently selected row
    if (batchTableIndex >= 0 && batchTableIndex < rows.length) {
        const selectedRow = rows[batchTableIndex];
        selectedRow.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');

        // Ensure the selected row is visible, especially useful for long tables
        selectedRow.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
}

async function selectCurrentResult() {
    const rows = document.querySelectorAll('#batch-table tbody tr');
    if (batchTableIndex >= 0 && batchTableIndex < rows.length) {
        // Defocus the search box
        document.getElementById('batch-search').blur();
        const studentID = rows[batchTableIndex].id.replace('batch-student-', '');
        await batchStudentClicked(studentID);
    }
}



async function undoBatchAdd() {
    console.log(`undoing batch add for ${lastBatchStudentID}`);

    if (lastBatchStudentID == null) {
        console.error('No last batch add');
        document.getElementById('batch-status').innerHTML = 'Nothing to undo';
        return;
    }

    // lastBatchAdd is the student ID - remove the badge from them

    // get the student's document
    const studentDocRef = doc(db, 'domains', window.userDomain, 'students', lastBatchStudentID);
    const studentDocSnap = await getDoc(studentDocRef);

    if (studentDocSnap.exists()) {
        // Student exists - remove the badge
        await updateDoc(studentDocRef, {
            badgeID: null,
            personalBadgeID: null
        });

        lastBatchStudentID = null;

        // focus the search box
        document.getElementById('batch-search').focus();

        document.getElementById('batch-status').innerHTML = `Removed badge from ${studentDocSnap.data().studentName}`;
    }
}

async function loadIdentifyBadge() {
    // reset identify-detail
    document.getElementById('identify-detail').innerHTML = 'Tap a badge...';
    document.getElementById('identify-detail').setAttribute('aria-busy', 'true');

    // deselect the button so badge reader enter doesn't trigger it
    document.getElementById('identify-badge-button').blur();

    // start listening for a badge
    const badgeID = await enableBadgeListener();
    console.log('Tapped Badge ID:', badgeID);

    // call getBadgeOwner cloud function
    const getBadgeOwner = httpsCallable(functions, 'getBadgeOwner');
    document.getElementById('identify-detail').innerHTML = 'Identifying badge...';
    const result = await getBadgeOwner({ badgeID: badgeID, domainId: window.userDomain });

    // will return owner, ownerID
    console.log('getBadgeOwner result:', result);

    // if no result, error
    if (!result) {
        console.error('No result from getBadgeOwner');
        document.getElementById('identify-detail').innerHTML = 'There was an error identifying the badge. Please try again.';
        document.getElementById('identify-detail').setAttribute('aria-busy', 'false');
        return;
    }

    else if (result.data.owner === 'student') {
        // if the badge is a student badge, show the student name
        document.getElementById('identify-detail').innerHTML = `${result.data.ownerName} (${result.data.ownerFormGroup})`;
        document.getElementById('identify-detail').setAttribute('aria-busy', 'false');
    } else if (result.data.owner === 'user') {
        // if the badge is a user badge, show the user name
        document.getElementById('identify-detail').innerHTML = `${result.data.ownerName} (Admin)`;
        document.getElementById('identify-detail').setAttribute('aria-busy', 'false');
    } else if (result.data.owner === 'badge') {
        // if the badge is a badge badge, show the badge name
        document.getElementById('identify-detail').innerHTML = `${result.data.ownerName} - ${result.data.ownerAssignedTo}`;
        document.getElementById('identify-detail').setAttribute('aria-busy', 'false');
    } else {
        // unknown result
        document.getElementById('identify-detail').innerHTML = 'There was an error identifying the badge. Please try again.';
        document.getElementById('identify-detail').setAttribute('aria-busy', 'false');
    }
}
async function batchSearchNameChanged() {
    // if the search query is empty, clear the table
    if (document.getElementById('batch-search').value === '') {
        document.querySelector('#batch-table tbody').innerHTML = '<tr><td colspan="2" style="text-align: center;" id="batch-no-records">Search for a student...</td></tr>';
        return;
    }

    const tableBody = document.querySelector('#batch-table tbody');

    document.getElementById('batch-status').value = '';

    // check if the query has changed
    if (document.getElementById('batch-search').value !== lastBatchSearchQuery) {
        lastBatchSearchQuery = document.getElementById('batch-search').value;
        tableBody.innerHTML = '';
        batchStudentData.forEach(async (student) => {
            // if the student name contains the search query, and they have no personal badge
            if (student.studentName.toLowerCase().includes(lastBatchSearchQuery.toLowerCase()) && (student.personalBadgeID === null || student.personalBadgeID === undefined)) {
                await addStudentToTable(student, student.misID);
            }
        });

        // if no students are found, show a message
        if (tableBody.innerHTML === '') {
            tableBody.innerHTML = '<tr><td colspan="2" style="text-align: center;" id="batch-no-records">No students found</td></tr>';
        }

    } else {
        return;
    }
}

// start listening for a badge (batch add)

// Fill feedback modal
async function fillFeedbackModal() {
    console.log('filling feedback modal');
    // set the feedback name and email
    document.getElementById('feedback-name').value = window.userName;
    document.getElementById('feedback-email').value = auth.currentUser.email;

    /*
    const allStudentsRef = collection(db, 'domains', window.userDomain, 'students');
    const allStudentsSnapshot = await getDocs(allStudentsRef);

    allStudentsSnapshot.forEach(async (docSnapshot) => {
        const data = docSnapshot.data();

        // get the badge ID - string
        try {
            const badgeID = data.badgeID;
            const personalBadgeID = data.personalBadgeID;

            // get the length
            const badgeIDLength = badgeID.toString().length;
            const personalBadgeIDLength = personalBadgeID.toString().length;

            console.log(`badgeID FOR ${data.studentName}: ${badgeID} (${badgeIDLength}), personalBadgeID: ${personalBadgeID} (${personalBadgeIDLength})`);

            // if the badgeID is not 10 characters long
            if (badgeIDLength !== 10) {
                // add a 0 to the start
                const newBadgeID = `0${badgeID}`;
                console.log(`new badgeID: ${newBadgeID}`);
                // update doc
                await updateDoc(docSnapshot.ref, {
                    badgeID: newBadgeID,
                    personalBadgeID: personalBadgeID
                });
            }
        } catch (error) {
            console.log(`Error updating student ${docSnapshot.id}: ${error}`);
        } 
    })

    const allUsersRef = collection(db, "users");
    const allUsersSnapshot = await getDocs(allUsersRef);

    allUsersSnapshot.forEach(async (docSnapshot) => {
        const data = docSnapshot.data();

        // get the badge ID
        try {
            const badgeID = data.badgeID;
            const personalBadgeID = data.personalBadgeID;

            // convert to string
            const badgeIDString = badgeID.toString();
            const personalBadgeIDString = personalBadgeID.toString();

            // update doc
            await updateDoc(docSnapshot.ref, {
                badgeID: badgeIDString,
                personalBadgeID: personalBadgeIDString
            });

            console.log(`Updated admin ${docSnapshot.id}`);
        } catch (error) {
            console.log(`Error updating admin ${docSnapshot.id}: ${error}`);
        }
    }) */
}

// Submit feedback
async function submitFeedback() {
    // get the feedback name, email, message
    const feedbackName = document.getElementById('feedback-name').value;
    const feedbackEmail = document.getElementById('feedback-email').value;
    const feedbackMessage = document.getElementById('feedback-message').value;

    // check if the name and message are empty
    if (feedbackName === '' || feedbackMessage === '') {
        showError('', 'Feedback', 'Please enter a name and message', null, false, false);
        return;
    }

    // check if the email is valid
    if (!validateEmail(feedbackEmail)) {
        showError('', 'Feedback', 'Please enter a valid email address', null, false, false);
        return;
    }

    console.log(`valid feedback: ${feedbackName}, ${feedbackEmail}, ${feedbackMessage}`);

    // submit feedback
    const eventId = Sentry.captureMessage('User Feedback');
    // OR: const eventId = Sentry.lastEventId();

    const userFeedback = {
        event_id: eventId,
        name: feedbackName,
        email: feedbackEmail,
        comments: feedbackMessage,
        version: document.getElementById('version').innerHTML,
        posthogID: posthog.get_session_id(),
        posthogReplay: posthog.get_session_replay_url()
    };

    try {
        Sentry.captureUserFeedback(userFeedback);
    } catch (error) {
        console.error('Error submitting feedback:', error);
        showError('2022', 'Feedback', 'Error submitting feedback', error, false, false);
        return;
    }

    console.log('feedback sent!');

    // wait 200ms
    await delay(200);

    // close the modal
    closeModal(document.getElementById('feedback-modal'));
}

// Reload and filter all tables
async function reloadData() {
    // check for maintenance - this will reload the page if maintenance is ongoing
    await checkForMaintenance(true);

    // check the user is authenticated
    if (!window.userID || !window.userName || !window.userDomain) {
        console.warn('User not authenticated, cannot reload data');
        return;
    }

    // check if the user account has been deleted
    const [result, role, domain, status] = await checkUserExists(window.userID, window.userDomain);
    if (!result) {
        console.error('User account has been deleted, cannot reload data');
        await signOutUser('You are no longer authorised to access Inscribe.<br>Please try again later.');
        return;
    }

    // if the role has changed, show an error and force a reload
    if (role !== window.userRole) {
        console.error('Role has changed');
        showError('', 'Access Role Changed', 'Your role has changed. You must reload the page to continue.', null, false, false, true);
        return;
    }

    // if the domain has changed, show an error and force a reload - unless the user is a super admin, who will be using a domain param override
    if (domain !== window.userDomain && window.userRole !== 'superadmin') {
        console.error('Domain has changed');
        showError('', 'Access Domain Changed', 'Your domain has changed. You must reload the page to continue.', null, false, false, true);
        return;
    }

    // if the status has changed, show an error and force a reload
    if (status !== window.userStatus) {
        console.error('Status has changed from', window.userStatus, 'to', status);
        showError('', 'Access Status Changed', 'Your status has changed. You must reload the page to continue.', null, false, false, true);
        return;
    }

    // Identify and reload the current page first
    if (currentPage) {
        switch (currentPage) {
        case 'home':
            refreshAllHomeRows();
            break;
        case 'onsite':
            refreshOnsiteStudentRows();
            break;
        case 'itemsManager':
            refreshAllItems();
            break;
        case 'history':
            // if there's more than 1,000 current records, and a modal is not open, don't reload
            if (window.displayingHistoryRecordCount > 1000) {
                // if a modal is open, don't show the tooltip
                if (!document.documentElement.classList.contains('modal-is-open')) {
                    console.log('history table has more than 1,000 records - not refreshing');
                    simulateTooltipHover(document.getElementById('refresh-history-button'), true);
                }
                break; // still refresh the other pages
            }

            fillHistory(); // this just inserts/removes the appropriate rows
            break;
        case 'studentManager':
            fillStudentManagerTable();
            break;
        case 'events':
            loadEvents(document.getElementById('event-archived').checked);
            break;
        case 'manageBadges':
            loadBadges();
            break;
        case 'fireRegister':
            loadFireRegisters();
            break;
        case 'admin':
            if (window.userRole === 'domainadmin' || window.userRole === 'superadmin') {
                loadAdmin();
            }
            break;
        case 'kiosks':
            loadKiosks();
            break;
        case 'enrolmentMode':
            // remove all other loaded pages - no need to load anything else in enrolment mode
            loadedPages = {};
            loadedPages.enrolmentMode = true;
            currentPage = 'enrolmentMode';
            // add the active tab border
            document.getElementById('uipane').classList.add('active-tab');
            // focus the student name input
            document.getElementById('staff-name').focus();
            break;
        case 'viewEnrolmentMode':
            loadViewEnrolmentMode();
            break;
        default:
            console.warn('Unknown page:', currentPage);
        }
    }

    // Reload other pages if they have already been loaded, excluding the current page - this has already been reloaded above

    if (loadedPages.home && currentPage !== 'home') {
        refreshAllHomeRows();
    }

    if (loadedPages.onsite && currentPage !== 'onsite') {
        refreshOnsiteStudentRows();
    }

    if (loadedPages.itemsManager && currentPage !== 'itemsManager') {
        refreshAllItems();
    }

    if (loadedPages.history && currentPage !== 'history') {
        // if there's more than 1,000 current records, don't reload
        if (window.displayingHistoryRecordCount > 1000) {
            console.log('history table has more than 1,000 records - not refreshing');
            // don't show the tooltip as this page is not currently being displayed
        } else {
            fillHistory(); // this just inserts/removes the appropriate rows
        }
    }

    if (loadedPages.studentManager && currentPage !== 'studentManager') {
        fillStudentManagerTable();
    }

    if (loadedPages.events && currentPage !== 'events') {
        loadEvents(document.getElementById('event-archived').checked);
    }

    if (loadedPages.manageBadges && currentPage !== 'manageBadges') {
        loadBadges();
    }

    if (loadedPages.fireRegister && currentPage !== 'fireRegister') {
        loadFireRegisters();
    }

    if (loadedPages.kiosks && currentPage !== 'kiosks') {
        loadKiosks();
    }

    // Only admins can load the admin page, and if the page has already been loaded, reload it
    if ((window.userRole === 'domainadmin' || window.userRole === 'superadmin') && loadedPages.admin && currentPage !== 'admin') {
        loadAdmin();
    }

}

async function checkUserExists(userID) {
    console.log('Checking user exists');
    try {
        const user = await getDoc(doc(db, 'users', userID));
        if (!user.exists()) {
            return [false, null, null];
        }
        const role = user.data().role || null;
        const domain = user.data().domain || null;
        const active = user.data().status || null;
        return [user.exists(), role, domain, active];
    } catch (error) {
        // likely the user doesn't exist and has no permission to read user db
        console.error('Error checking if user exists:', error);
        return [false, null, null, null];
    }
}

// load the data into a local variable
// for use in the search function
async function prepareBatchStudentSearch() {
    console.log('preparing batch student search');

    // reset
    document.getElementById('batch-search').value = '';
    lastBatchSearchQuery = '';
    batchTableIndex = 0;
    batchStudentData = [];
    // clear the table
    document.querySelector('#batch-table tbody').innerHTML = '<tr><td colspan="2" style="text-align: center;" id="batch-no-records">Search for a student...</td></tr>';

    // Listen for real-time updates
    const studentsQuery = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), where('archived', '==', false), where('personalBadgeID', '==', null), orderBy('index'));

    batchUnsubscribe = onSnapshot(studentsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
            const studentDocData = change.doc.data();
            const studentID = change.doc.id;

            // Handle added documents
            if (change.type === 'added') {
                await addStudentToTable(studentDocData, studentID);
            }
            // Handle modified documents
            else if (change.type === 'modified') {
                // If the student now has a personal badge, remove them from the table
                if (studentDocData.personalBadgeID) {
                    const elementToRemove = document.getElementById(`batch-student-${studentID}`);
                    if (elementToRemove) elementToRemove.remove();
                } else if (studentDocData.personalBadgeID === null) {
                    // Student's personal badge has been removed - re-add them to the table
                    await addStudentToTable(studentDocData, studentID);
                }
            }
            // Handle removed documents
            else if (change.type === 'removed') {
                const elementToRemove = document.getElementById(`batch-student-${studentID}`);
                if (elementToRemove) elementToRemove.remove();
            }
        });

        batchSnapshot = snapshot;
        // After handling changes, refresh the batchStudentData array and UI if necessary
        refreshStudentDataAndUI(snapshot);
    }, err => {
        console.error(`Encountered error: ${err}`);
    });

    // disable the undo button
    document.getElementById('undo-batch-add').disabled = true;

    console.log('batch student search data prepared');
}

// when a student is clicked in the batch add table
// or enter is pressed, selecting the top result
async function batchStudentClicked(studentID) {
    // if no student ID, return
    if (!studentID || studentID === '') {
        document.getElementById('batch-search').focus();
        return;
    }
    console.log(`row for student ${studentID} has been clicked`);


    if (document.getElementById(`batch-student-${studentID}`)) {
        document.getElementById(`batch-student-${studentID}`).style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');
    }

    const result = await assignNewBadge(studentID, 'batch-status', true);

    // if the badge was assigned
    if (result) {
        // play the success sound
        const audioURL = new URL('../sound/success.mp3', import.meta.url);
        var audio = new Audio(audioURL);
        audio.volume = 1;
        await audio.play();

        // remove the student from batchStudentData
        batchStudentData = batchStudentData.filter(function (value) {
            return value.misID !== studentID;
        });

        // enable the undo button
        document.getElementById('undo-batch-add').disabled = false;
        lastBatchStudentID = studentID;

        // clear the input
        document.getElementById('batch-search').value = '';
        // update the table
        refreshStudentDataAndUI();
    } else {
        // function shows its own alert(), and plays the error sound

        // reset the search query & table
        lastBatchStudentID = null;
        document.getElementById('undo-batch-add').disabled = true;
    }

    // reset the status
    document.getElementById('batch-status').value = '';
    // focus the input
    document.getElementById('batch-search').focus();
}

async function addStudentToTable(studentData, studentID) {
    const tableBody = document.querySelector('#batch-table tbody');

    batchTableIndex = 0;

    // remove the 'no students found' row if it exists
    if (document.getElementById('batch-no-records')) {
        document.getElementById('batch-no-records').remove();
    }
    // Ensure the student is not already in the table
    if (!document.getElementById(`batch-student-${studentID}`)) {
        const row = document.createElement('tr');
        row.id = `batch-student-${studentID}`;
        row.innerHTML = `
            <td>${studentData.studentName}</td>
            <td>${studentData.formGroup}</td>
        `;
        row.setAttribute('data-last-initial', studentData.lastInitial);

        // add the row to the table
        tableBody.appendChild(row);

        document.getElementById(`batch-student-${studentID}`).addEventListener('mousedown', async () => {
            await batchStudentClicked(studentID);
        });
    }
}
function refreshStudentDataAndUI() {
    // refresh the student data
    batchStudentData = batchSnapshot.docs.map(doc => ({
        studentName: doc.data().studentName,
        formGroup: doc.data().formGroup,
        misID: doc.id,
        personalBadgeID: doc.data().personalBadgeID,
        badgeID: doc.data().badgeID,
        lastInitial: doc.data().lastInitial
    }));

    // sort by last initial
    batchStudentData.sort((a, b) => {
        return a.lastInitial.localeCompare(b.lastInitial);
    });

}

// Function to set a button as active
function setActiveButton(buttonId) {
    resetButtonStyles();
    const button = document.getElementById(buttonId);
    if (button) {
        button.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-focus');
        activeButtonId = buttonId;
    }
}


// Define a function to reset all button styles
function resetButtonStyles() {
    const buttons = ['history-button', 'students-button', 'admin-button', 'onsite-button', 'home-button', 'items-button', 'manage-badges-button', 'events-button', 'fire-register-button', 'kiosk-button', 'kiosk-install-button', 'feedbackButton', 'enrolment-mode-button'];
    buttons.forEach(buttonId => {
        const button = document.getElementById(buttonId);
        if (button) {
            button.style.backgroundColor = 'transparent';
        }
    });
}

async function applyAllStudentFilters(year, filters) {
    console.log('applying all student filters:', filters);

    // get every row in the table
    var table = document.querySelector(`#all-y${year}-table tbody`).getElementsByTagName('tr');

    // remove the 'no students found' row if it exists
    if (document.getElementById(`all-y${year}-no-records`)) {
        console.log(`removing: all-y${year}-no-records`);
        document.getElementById(`all-y${year}-no-records`).remove();
    }

    // Loop through all table rows, and hide those who don't match the filterd
    for (var i = 0; i < table.length; i++) {
        var name = table[i].getElementsByTagName('th')[0];
        var formGroup = table[i].getElementsByTagName('td')[0];

        // if show all forms is selected, only filter by name
        if (filters.formGroup === 'Show All') {
            if (name) {
                if (name.innerHTML.toLowerCase().indexOf(filters.name.toLowerCase()) > -1) {
                    table[i].style.display = '';
                } else {
                    table[i].style.display = 'none';
                }
            }
        } else {
            // if a form is selected, filter by name and form
            console.log('filtering by form');

            if (name) {
                if (name.innerHTML.toLowerCase().indexOf(filters.name.toLowerCase()) > -1 && formGroup.innerHTML.toLowerCase().indexOf(filters.formGroup.toLowerCase()) > -1) {
                    table[i].style.display = '';
                } else {
                    table[i].style.display = 'none';
                }
            } else {
                // no name, just form
                if (formGroup.innerHTML.toLowerCase().indexOf(filters.formGroup.toLowerCase()) > -1) {
                    table[i].style.display = '';
                } else {
                    table[i].style.display = 'none';

                }
            }
        }
    }

    // if no rows are visible, show a message
    const tableBody = document.querySelector(`#all-y${year}-table tbody`);
    const rows = tableBody.querySelectorAll('tr');

    let hiddenRowsCount = 0;
    rows.forEach(row => {
        if (window.getComputedStyle(row).display === 'none') {
            hiddenRowsCount++;
        }
    });

    console.log(`found ${rows.length} rows, ${hiddenRowsCount} hidden`);

    // if all rows are hidden, show a message
    if (rows.length === hiddenRowsCount) {
        // add a row to the table
        console.log('no records found');
        const row = document.createElement('tr');
        row.id = `all-y${year}-no-records`;
        row.innerHTML = `
        <th scope="row" colspan="5" style="text-align: center;" id="all-y${year}-no-records">No students found</th>
        `;
        document.querySelector(`#all-y${year}-table tbody`).appendChild(row);
    }
}


// caller determins which table to reload
// home, onsite etc do their own reload logic
export async function updateStudentOnsite(studentID, onsite) {
    console.log('updating student onsite');
    console.log('studentID:', studentID);
    console.log('onsite:', onsite);

    // get the student's document
    const studentDocRef = doc(db, 'domains', window.userDomain, 'students', studentID);
    const studentDocSnap = await getDoc(studentDocRef);
    const studentData = studentDocSnap.data();
    console.log('studentData:', studentData);

    // use this as a static time across all DB calls
    const now = Timestamp.now();

    // update the onsite status
    // to the opposite of what it currently is
    await updateDoc(studentDocRef, {
        onsite: !onsite,
        lastTap: now,
        lastUpdateType: 'admin'
    });

    // create an attendance record
    await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'), {
        studentID: studentID,
        time: now,
        action: !onsite ? 'in' : 'out',
        type: 'admin',
        adminID: window.userID,
        archived: false,
    });

    reloadData();
}

// apply all filters, including search, to the onsite students tables
async function applyOnsiteFilters(year) {
    console.log('applying onsite filters for year', year);

    // get every row in the table
    var table = document.getElementById(`onsite-table-y${year}`).getElementsByTagName('tr');
    // remove the first row (table headers)
    table = Array.from(table).slice(1);

    // if the row no-onsite-yxx exists, there are no students onsite now
    // so nothing to filter
    if (document.getElementById(`no-onsite-y${year}`)) {
        console.log('no students onsite to filter');
        return;
    }


    var found = false;

    // get the applied filters depending on the year input
    var filters = y12OnsiteFilters;
    if (year === '13') {
        filters = y13OnsiteFilters;
    }

    // Loop through all table rows, and hide those who don't match the filterd
    for (var i = 0; i < table.length; i++) {
        var name = table[i].getElementsByTagName('th')[0];
        var formGroup = table[i].getElementsByTagName('td')[0];

        // if show all forms is selected, only filter by name
        if (filters.formGroup === 'Show All') {
            if (name) {
                if (name.innerHTML.toLowerCase().indexOf(filters.name.toLowerCase()) > -1) {
                    table[i].style.display = '';
                    found = true;
                } else {
                    table[i].style.display = 'none';
                }
            }
        } else {
            // if a form is selected, filter by name and form
            if (name && formGroup) {
                if (name.innerHTML.toLowerCase().indexOf(filters.name.toLowerCase()) > -1 && formGroup.innerHTML.toLowerCase().indexOf(filters.formGroup.toLowerCase()) > -1) {
                    table[i].style.display = '';
                    found = true;
                } else {
                    table[i].style.display = 'none';
                }
            }
        }
    }

    if (!found) {
        console.log('no students found for the filters - year', year);
    }
}

// load form groups into dropdown
async function loadFormGroups() {
    // Utility function to create and append a 'Show All' option
    function appendShowAllOption(dropdownId) {
        const li = document.createElement('li');
        const label = document.createElement('label');
        const input = document.createElement('input');
        input.type = 'radio';
        input.id = `show-all-${dropdownId}`;
        input.name = 'size';
        input.value = 'Show All';
        input.checked = true; // set as checked by default
        input.setAttribute('checked', '');
        label.htmlFor = input.id;
        label.append(input, document.createTextNode('Show All'));
        li.appendChild(label);
        document.getElementById(dropdownId).appendChild(li);
    }

    // Utility function to append form groups to dropdowns
    function appendFormGroupOption(formGroup, dropdownIds) {
        dropdownIds.forEach(dropdownId => {
            const li = document.createElement('li');
            const label = document.createElement('label');
            const input = document.createElement('input');
            input.type = 'radio';
            input.id = formGroup;
            input.name = 'size';
            input.value = formGroup;
            label.htmlFor = formGroup;
            label.append(input, document.createTextNode(formGroup));
            li.appendChild(label);
            document.getElementById(dropdownId).appendChild(li);
        });
    }

    // Get form groups
    const formGroups = await getFormGroups();

    // dropdowns to show all form groups
    const allGroupsDropdowns = ['form-group-dropdown', 'item-history-form-group-dropdown'];
    // dropdowns to show y12 form groups
    const y12GroupsDropdowns = ['y12-form-group-dropdown', 'y12-all-form-group-dropdown'];
    // dropdowns to show y13 form groups
    const y13GroupsDropdowns = ['y13-form-group-dropdown', 'y13-all-form-group-dropdown'];

    // Clear every dropdown and add 'Show All' option
    [...allGroupsDropdowns, ...y12GroupsDropdowns, ...y13GroupsDropdowns].forEach((dropdown) => {
        document.getElementById(dropdown).innerHTML = '';
        appendShowAllOption(dropdown);
    });

    // Add form groups to dropdowns
    formGroups.forEach(formGroup => {
        // ensure all groups are in allGroupsDropdowns
        appendFormGroupOption(formGroup, allGroupsDropdowns);

        // if it's a y12 group, add it to y12GroupsDropdowns
        if (formGroup.includes('12')) {
            appendFormGroupOption(formGroup, y12GroupsDropdowns);
        }

        // if it's a y13 group, add it to y13GroupsDropdowns
        else if (formGroup.includes('13')) {
            appendFormGroupOption(formGroup, y13GroupsDropdowns);
        }

    });
}

// fill the student-manager-table from firestore
async function fillStudentManagerTable() {
    console.log('filling student manager table');

    // if the user is a viewer, hide the edit, disable, loan columns
    if (window.userRole === 'viewer') {
        if (document.getElementById('y12-edit-student-badge-column')) {
            document.getElementById('y12-edit-student-badge-column').remove();
        }
        if (document.getElementById('y12-disable-student-badge-column')) {
            document.getElementById('y12-disable-student-badge-column').remove();
        }
        if (document.getElementById('y12-loan-student-column')) {
            document.getElementById('y12-loan-student-column').remove();
        }
        if (document.getElementById('y13-edit-student-badge-column')) {
            document.getElementById('y13-edit-student-badge-column').remove();
        }
        if (document.getElementById('y13-disable-student-badge-column')) {
            document.getElementById('y13-disable-student-badge-column').remove();
        }
        if (document.getElementById('y13-loan-student-column')) {
            document.getElementById('y13-loan-student-column').remove();
        }
        if (document.getElementById('batch-add-button')) {
            document.getElementById('batch-add-button').remove();
        }
    }
    // get data from firestore
    // records under 'students' collection
    const y12q = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval), where('yearGroup', '==', '12'), orderBy('yearIndex'));
    const y13q = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval), where('yearGroup', '==', '13'), orderBy('yearIndex'));

    // get table
    const y12Table = document.querySelector('#y12-student-manager-table tbody');
    const y13Table = document.querySelector('#y13-student-manager-table tbody');

    var tempBadgeStudents = [];

    // clear tables
    y12Table.innerHTML = '';
    y13Table.innerHTML = '';

    await getDocs(y12q)
        .then(async (querySnapshot) => {
            const studentPromises = querySnapshot.docs.map(async (docu) => {
                // get data from doc
                const data = docu.data();

                // for each student, add a row to the table
                const row = document.createElement('tr');

                // badge assigned - if badgeID is null, no badge is assigned
                var badgeAssigned = data.badgeID ? 'Yes' : 'No';
                // badge enabled - if badgeDisabled is true, the badge is disabled
                const badgeEnabled = data.badgeDisabled ? 'No' : 'Yes';
                // temp badge assigned - tempBadgeAssigned

                var disableHTML = '';

                if (window.userRole !== 'viewer') {
                    var disableButtonPrefix = '';
                    if (data.badgeDisabled) {
                        disableHTML = `<td><a style='color: green;' id="enable-badge-${docu.id}">Enable</a></td>`;
                        disableButtonPrefix = 'enable';
                    } else {
                        disableHTML = `<td><a style='color: red;' id="disable-badge-${docu.id}">Disable</a></td>`;
                        disableButtonPrefix = 'disable';
                    }
                }

                // if a temp badge
                if (data.tempBadgeAssigned) {
                    tempBadgeStudents.push(data);
                }

                // if the student has permission to loan items, show a button (toggle)
                var loanItem = '';
                if (window.userRole !== 'viewer') {
                    if (data.loanPermission) {
                        loanItem = `<td><a id="loan-permission-${docu.id}" style='color: green;'>Yes</a></td>`;
                    } else {
                        loanItem = `<td><a id="loan-permission-${docu.id}" style='color: red;'>No</a></td>`;
                    }
                }

                var editButton = '';
                if (window.userRole !== 'viewer') {
                    editButton = `<td><a id="edit-student-badge-${docu.id}">Edit</a></td>`;
                }

                // Name, Form Group, Badge Assigned (yes/no), Badge Enabled (yes/no) Edit button, Disable button, Loan Item
                row.innerHTML = `
                <th scope="row">${data.studentName}</th>
                <td>${data.formGroup}</td>
                <td>${badgeAssigned}</td>
                <td>${badgeEnabled}</td>
                ${editButton}
                ${disableHTML}
                ${loanItem}
                `;

                row.id = `student-manager-${docu.id}`;
                y12Table.appendChild(row);

                if (window.userRole !== 'viewer') {
                    // event listener for edit button
                    document.getElementById(`edit-student-badge-${docu.id}`).addEventListener('mousedown', async () => {
                        await editBadge(docu.id);
                    });

                    // event listener for disable button
                    document.getElementById(`${disableButtonPrefix}-badge-${docu.id}`).addEventListener('mousedown', async () => {
                        if (data.badgeDisabled) {
                            console.log('enabling badge for', window.userID);

                            document.getElementById('disable-badge-title').innerHTML = 'Enable Badge';
                            document.getElementById('disable-badge-detail').innerHTML = '';
                            document.getElementById('disable-badge-detail').innerHTML = `
                    <h4>Are you sure you would like to re-enable <i>${data.studentName}'s</i> badge?</h4>
                    <p>Their badge will again work to sign in or out.
                    `;
                            document.getElementById('disable-badge-confirm').innerHTML = 'Enable Badge';
                            document.getElementById('disable-badge-confirm').style.backgroundColor = 'green';
                            document.getElementById('disable-badge-confirm').style.borderColor = 'green';

                            disablingBadgeUserID = data.misID;
                            disablingOrEnabling = 'enable';

                            // show modal
                            openModal(document.getElementById('disable-badge-modal'));
                        } else {
                            console.log('disabling badge for', window.userID);

                            document.getElementById('disable-badge-title').innerHTML = 'Disable Badge';
                            document.getElementById('disable-badge-detail').innerHTML = '';
                            document.getElementById('disable-badge-detail').innerHTML = `
                    <h4>Are you sure you would like to disable <i>${data.studentName}'s</i> badge?</h4>
                    <p>They will be unable to sign in or out.
                    `;

                            document.getElementById('disable-badge-confirm').innerHTML = 'Disable Badge';
                            document.getElementById('disable-badge-confirm').style.backgroundColor = 'red';
                            document.getElementById('disable-badge-confirm').style.borderColor = 'red';

                            disablingBadgeUserID = data.misID;
                            disablingOrEnabling = 'disable';

                            // show modal
                            openModal(document.getElementById('disable-badge-modal'));
                        }
                    });

                    // event listener for loan permission button
                    document.getElementById(`loan-permission-${docu.id}`).addEventListener('mousedown', async () => {
                        console.log(`loan-permission-${docu.id} clicked`);
                        document.getElementById(`loan-permission-${docu.id}`).setAttribute('aria-busy', 'true');
                        banningLoanStudentID = docu.id;
                        await loadLoanBanModal();
                        // open loan-ban-modal
                        openModal(document.getElementById('loan-ban-modal'));
                        document.getElementById(`loan-permission-${docu.id}`).setAttribute('aria-busy', 'false');
                    });
                }

            });

            // Wait for all promises to resolve
            console.log('waiting for all students to resolve');
            await Promise.all(studentPromises);
            console.log('all students resolved');

        }).catch((error) => {
            console.error('Error fetching documents:', error);
        });

    await getDocs(y13q)
        .then(async (querySnapshot) => {
            const studentPromises = querySnapshot.docs.map(async (docu) => {
                // get data from doc
                const data = docu.data();

                // for each student, add a row to the table
                const row = document.createElement('tr');

                // badge assigned - if badgeID is null, no badge is assigned
                var badgeAssigned = data.badgeID ? 'Yes' : 'No';
                // badge enabled - if badgeDisabled is true, the badge is disabled
                const badgeEnabled = data.badgeDisabled ? 'No' : 'Yes';
                // temp badge assigned - tempBadgeAssigned

                var disableHTML = '';

                if (window.userRole !== 'viewer') {
                    var disableButtonPrefix = '';
                    if (data.badgeDisabled) {
                        disableHTML = `<td><a style='color: green;' id="enable-badge-${docu.id}">Enable</a></td>`;
                        disableButtonPrefix = 'enable';
                    } else {
                        disableHTML = `<td><a style='color: red;' id="disable-badge-${docu.id}">Disable</a></td>`;
                        disableButtonPrefix = 'disable';
                    }
                }

                // if a temp badge
                if (data.tempBadgeAssigned) {
                    tempBadgeStudents.push(data);
                }

                // if the student has permission to loan items, show a button (toggle)
                var loanItem = '';
                if (window.userRole !== 'viewer') {
                    if (data.loanPermission) {
                        loanItem = `<td><a id="loan-permission-${docu.id}" style='color: green;'>Yes</a></td>`;
                    } else {
                        loanItem = `<td><a id="loan-permission-${docu.id}" style='color: red;'>No</a></td>`;
                    }
                }

                var editButton = '';
                if (window.userRole !== 'viewer') {
                    editButton = `<td><a id="edit-student-badge-${docu.id}">Edit</a></td>`;
                }

                // Name, Form Group, Badge Assigned (yes/no), Badge Enabled (yes/no) Edit button, Disable button, Loan Item
                row.innerHTML = `
                <th scope="row">${data.studentName}</th>
                <td>${data.formGroup}</td>
                <td>${badgeAssigned}</td>
                <td>${badgeEnabled}</td>
                ${editButton}
                ${disableHTML}
                ${loanItem}
                `;

                row.id = `student-manager-${docu.id}`;
                y13Table.appendChild(row);

                if (window.userRole !== 'viewer') {
                    // event listener for edit button
                    document.getElementById(`edit-student-badge-${docu.id}`).addEventListener('mousedown', async () => {
                        await editBadge(docu.id);
                    });

                    // event listener for disable button
                    document.getElementById(`${disableButtonPrefix}-badge-${docu.id}`).addEventListener('mousedown', async () => {
                        if (data.badgeDisabled) {
                            console.log('enabling badge for', window.userID);

                            document.getElementById('disable-badge-title').innerHTML = 'Enable Badge';
                            document.getElementById('disable-badge-detail').innerHTML = '';
                            document.getElementById('disable-badge-detail').innerHTML = `
                    <h4>Are you sure you would like to re-enable <i>${data.studentName}'s</i> badge?</h4>
                    <p>Their badge will again work to sign in or out.
                    `;
                            document.getElementById('disable-badge-confirm').innerHTML = 'Enable Badge';
                            document.getElementById('disable-badge-confirm').style.backgroundColor = 'green';
                            document.getElementById('disable-badge-confirm').style.borderColor = 'green';

                            disablingBadgeUserID = data.misID;
                            disablingOrEnabling = 'enable';

                            // show modal
                            openModal(document.getElementById('disable-badge-modal'));
                        } else {
                            console.log('disabling badge for', window.userID);

                            document.getElementById('disable-badge-title').innerHTML = 'Disable Badge';
                            document.getElementById('disable-badge-detail').innerHTML = '';
                            document.getElementById('disable-badge-detail').innerHTML = `
                    <h4>Are you sure you would like to disable <i>${data.studentName}'s</i> badge?</h4>
                    <p>They will be unable to sign in or out.
                    `;

                            document.getElementById('disable-badge-confirm').innerHTML = 'Disable Badge';
                            document.getElementById('disable-badge-confirm').style.backgroundColor = 'red';
                            document.getElementById('disable-badge-confirm').style.borderColor = 'red';

                            disablingBadgeUserID = data.misID;
                            disablingOrEnabling = 'disable';

                            // show modal
                            openModal(document.getElementById('disable-badge-modal'));
                        }
                    });

                    // event listener for loan permission button
                    document.getElementById(`loan-permission-${docu.id}`).addEventListener('mousedown', async () => {
                        console.log(`loan-permission-${docu.id} clicked`);
                        document.getElementById(`loan-permission-${docu.id}`).setAttribute('aria-busy', 'true');
                        banningLoanStudentID = docu.id;
                        await loadLoanBanModal();
                        // open loan-ban-modal
                        openModal(document.getElementById('loan-ban-modal'));
                        document.getElementById(`loan-permission-${docu.id}`).setAttribute('aria-busy', 'false');
                    });
                }

            });

            // Wait for all promises to resolve
            console.log('waiting for all students to resolve');
            await Promise.all(studentPromises);
            console.log('all students resolved');

        }).catch((error) => {
            console.error('Error fetching documents:', error);
        });


    // if there are temp badge students, change the text

    if (tempBadgeStudents.length > 0) {
        tempBadgeStudents.forEach(async (student) => {
            // get temp badge name from /domains/{domain}/badges/{tempBadgeID} in firestore
            const tempBadgeRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('assignedTo', '==', student.misID));

            await getDocs(tempBadgeRef).then((querySnapshot) => {
                querySnapshot.forEach((docu) => {
                    const tempBadgeData = docu.data();
                    console.log(`student ${student.misID} has a temp badge with name ${tempBadgeData.name}`);
                    try {
                        const originalRow = document.getElementById(`student-manager-${student.misID}`);
                        // get the third TD
                        const thirdTD = originalRow.getElementsByTagName('td')[1];
                        // update the third TD
                        thirdTD.innerHTML = `Yes - ${tempBadgeData.name}`;
                    } catch (error) {
                        console.error(`Error updating temp badge in table for ${student.misID}:`, error);

                    }
                });
            });
        });
    }
}

// check if a student already has a ban
async function loadLoanBanModal() {
    console.log('loading loan ban modal for', banningLoanStudentID);

    // get data from firestore
    const docRef = doc(db, 'domains', window.userDomain, 'students', banningLoanStudentID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        // if the student has a loan ban, show the details
        if (data.loanPermission === false) {
            document.getElementById('loan-ban-detail').innerHTML = '<strong>This student is currently banned from loaning items.</strong>';


            // if the ban has an end date, show it
            if (data.loanBanEnd) {
                const banEnd = data.loanBanEnd.toDate().toLocaleDateString() + ' at ' + data.loanBanEnd.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                document.getElementById('loan-ban-detail').innerHTML += `<br>The ban will end on <strong>${banEnd}</strong>.`;
            } else {
                document.getElementById('loan-ban-detail').innerHTML += '<br>The ban is indefinite.';
            }

            // set loan-ban-btn to 'Remove Ban'
            document.getElementById('loan-ban-btn').innerHTML = 'Remove Ban';
            document.getElementById('loan-ban-btn').style.backgroundColor = 'green';
            document.getElementById('loan-ban-btn').style.borderColor = 'green';

            // hide the form
            document.getElementById('loan-ban-form').style.display = 'none';
        } else {
            document.getElementById('loan-ban-detail').innerHTML = 'This student is not currently banned from loaning items.';

            // set loan-ban-btn to 'Ban'
            document.getElementById('loan-ban-btn').innerHTML = 'Save Ban';
            document.getElementById('loan-ban-btn').style.backgroundColor = 'red';
            document.getElementById('loan-ban-btn').style.borderColor = 'red';

            // show the form
            document.getElementById('loan-ban-form').style.display = 'block';
        }
    } else {
        console.error('No such document!');
    }
}

// save a loan ban
async function saveLoanBan() {
    // if the student is already banned, remove the ban
    if (document.getElementById('loan-ban-btn').innerHTML === 'Remove Ban') {
        // remove ban
        console.log('removing ban for', banningLoanStudentID);

        // update firestore
        const docRef = doc(db, 'domains', window.userDomain, 'students', banningLoanStudentID);
        await updateDoc(docRef, {
            lastUpdateType: 'admin-loan-ban-remove',
            lastUpdateDate: Timestamp.now(),
            loanPermission: true,
            loanBanEnd: null
        });
    } else {
        console.log('saving loan ban for', banningLoanStudentID);

        const datePicker = document.getElementById('ban-end-datetime');
        const dateStr = datePicker.value;

        // If a date is selected, create a Date object from the date picker value
        let loanBanEnd = null;
        if (dateStr) {
            // Create a Date object from the date picker value
            const date = new Date(dateStr);

            // Convert the Date object to a UTC string and then to Firestore Timestamp
            loanBanEnd = Timestamp.fromDate(new Date(date.toISOString()));
        }

        // Update Firestore document
        const docRef = doc(db, 'domains', window.userDomain, 'students', banningLoanStudentID);
        await updateDoc(docRef, {
            lastUpdateType: 'admin-loan-ban',
            lastUpdateDate: Timestamp.now(),
            loanPermission: false,
            loanBanEnd: loanBanEnd
        });
    }

    // reload badge manager table
    await fillStudentManagerTable();

    // close modal
    closeModal(document.getElementById('loan-ban-modal'));

    // clear the date picker
    document.getElementById('ban-end-datetime').value = '';

    // clear the banningLoanStudentID
    banningLoanStudentID = null;

    // reset the button classes
    document.getElementById('end-of-today-btn').classList.add('outline');
    document.getElementById('end-of-tomorrow-btn').classList.add('outline');
    document.getElementById('end-of-week-btn').classList.add('outline');
    document.getElementById('indefinitely-btn').classList.add('outline');
}

function updateDatePicker(date) {
    console.log('updating date picker to', date);

    let targetDate;
    const datePicker = document.getElementById('ban-end-datetime');

    switch (date) {
    case 'end-of-today':
        targetDate = new Date();
        targetDate.setHours(23, 59, 0, 0); // Set to 23:59:00
        break;
    case 'end-of-tomorrow':
        targetDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // Add one day
        targetDate.setHours(23, 59, 0, 0); // Set to 23:59:00
        break;
    case 'end-of-week':
        targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + (7 - targetDate.getDay())); // Add days until end of week
        targetDate.setHours(23, 59, 0, 0); // Set to 23:59:00
        break;
    case 'indefinitely':
        datePicker.value = ''; // Clear the input for 'indefinitely'
        return; // Exit the function as we don't want to set a specific date
    default:
        console.log('Unknown date option');
        return;
    }

    // Format the date to YYYY-MM-DDTHH:MM in local time
    const year = targetDate.getFullYear();
    const month = String(targetDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(targetDate.getDate()).padStart(2, '0');
    const hours = String(targetDate.getHours()).padStart(2, '0');
    const minutes = String(targetDate.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    datePicker.value = formattedDate;
}



// function to disable a badge
// this just sets a flag - it does not remove the badge from the student
// as badges are printed with the student's name, it is not possible to re-use a badge
// loaned badges are handled separately
async function disableBadge() {
    if (disablingOrEnabling === 'disable') {
        // disable badge
        console.log('disabling badge for', disablingBadgeUserID);

        // get data from firestore
        const docRef = doc(db, 'domains', window.userDomain, 'students', disablingBadgeUserID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // disable the badge
            await updateDoc(docRef, {
                lastUpdateType: 'admin',
                lastUpdateDate: Timestamp.now(),
                badgeDisabled: true
            });

            // close modal
            closeModal(document.getElementById('disable-badge-modal'));

            // refresh the badge manager table
            await fillStudentManagerTable();
        } else {
            console.error('No such document!');
            showError('2027', 'Error Disabling Badge', 'Please try again.', '', false, true);
        }
    } else if (disablingOrEnabling === 'enable') {
        // enable badge
        console.log('enabling badge for', disablingBadgeUserID);

        // get data from firestore
        const docRef = doc(db, 'domains', window.userDomain, 'students', disablingBadgeUserID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // enable the badge
            await updateDoc(docRef, {
                lastUpdateType: 'admin',
                lastUpdateDate: Timestamp.now(),
                badgeDisabled: false
            });

            // close modal
            closeModal(document.getElementById('disable-badge-modal'));

            // refresh the badge manager table
            await fillStudentManagerTable();
        } else {
            console.error('No such document!');
            showError('2028', 'Error Enabling Badge', 'Please try again.', '', false, true);
        }
    } else {
        console.error('unknown disablingOrEnabling value:', disablingOrEnabling);
        showError('2029', 'Error Changing Badge', 'Please try again.', '', false, true);
    }

}

async function editBadge(userID) {
    console.log('editing badge for', userID);

    // load data into edit-badge-modal
    const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();

        // clear HTML under edit-badge-detail
        document.getElementById('edit-badge-detail').innerHTML = '';

        // if the student has a temp badge, show a message
        if (data.tempBadgeAssigned) {
            // get temp badge name from /domains/{domain}/badges/{tempBadgeID} in firestore
            const tempBadgeRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('assignedTo', '==', data.misID));

            await getDocs(tempBadgeRef).then((querySnapshot) => {
                if (querySnapshot.size === 0) {
                    console.error('Cannot find temp badge data');
                    return;
                }

                const doc = querySnapshot.docs[0];
                const tempBadgeData = doc.data();
                console.log(`found temp badge ${tempBadgeData.name} for ${data.studentName}`);

                document.getElementById('edit-badge-detail').innerHTML = `
                    <h2>${data.studentName} (${data.formGroup})</h2>
                    <h4>Temporary Badge Assigned</h4>
                    <p>${tempBadgeData.name}</p>
                    <a href="#" id="assign-badge-button-${userID}" role="button">Assign New Personal Badge</a>
                    <a href="#" id="remove-badge-button-${userID}" role="button" style="background-color: red; border-color: red;">Remove Personal Badge</a>
                    <a href="#" id="remove-temp-badge-button-${userID}" role="button" style="background-color: red; border-color: red;" >Remove Temporary Badge</a>
                    `;

                // event listener for remove temp badge button
                document.getElementById(`remove-temp-badge-button-${userID}`).addEventListener('mousedown', async () => {
                    // if this returns true, badge was assigned successfully
                    if (await removeTempBadge(userID, `remove-temp-badge-button-${userID}`)) {
                        // wait 1 sec, and close modal
                        await delay(1000);
                        // clear HTML under edit-badge-detail
                        closeModal(document.getElementById('edit-badge-modal'));
                        document.getElementById('edit-badge-detail').innerHTML = '';
                    }

                    // if this returns false, badge was not assigned so don't close modal (try again)

                });
            });
        } else {
            // student does not have a temp badge

            // create HTML under edit-badge-detail
            document.getElementById('edit-badge-detail').innerHTML = `
        <h2>${data.studentName} (${data.formGroup})</h2>
        <p>Badge Currently Assigned: ${data.badgeID ? 'Yes' : 'No'}</p>
        <a href="#" id="assign-badge-button-${userID}" role="button">Assign New Personal Badge</a>
        <a href="#" id="remove-badge-button-${userID}" role="button" style="background-color: red; border-color: red;">Remove Personal Badge</a>
        `;
        }

        // show modal
        openModal(document.getElementById('edit-badge-modal'));

        // event listener for assign badge button
        document.getElementById(`assign-badge-button-${userID}`).addEventListener('mousedown', async () => {
            console.log('assigning badge for', userID);
            // if this returns true, badge was assigned successfully
            if (await assignNewBadge(userID, `assign-badge-button-${userID}`)) {
                await fillStudentManagerTable();
                // clear HTML under edit-badge-detail
                closeModal(document.getElementById('edit-badge-modal'));
                document.getElementById('edit-badge-detail').innerHTML = '';
            }

            // if this returns false, badge was not assigned so don't close modal (try again)

        });

        // event listener for remove badge personal button
        document.getElementById(`remove-badge-button-${userID}`).addEventListener('mousedown', async () => {
            // if this returns true, badge was assigned successfully

            if (await removeBadge(userID, `remove-badge-button-${userID}`, true, true)) {
                await fillStudentManagerTable();
                // clear HTML under edit-badge-detail
                closeModal(document.getElementById('edit-badge-modal'));
                document.getElementById('edit-badge-detail').innerHTML = '';
            }

            // if this returns false, badge was not assigned so don't close modal (try again)

        });

    } else {
        // doc.data() will be undefined in this case
        console.error('No such document!');
    }
}

// function to remove a temporary badge from a student
// put the badge back into the pool of unassigned badges
// and reset the student's badgeID to their personal badge
async function removeTempBadge(userID, statusID) {
    console.log('removing temp badge for', userID);
    console.log('status ID:', statusID);

    try {
        // change the text
        document.getElementById(statusID).innerHTML = 'Removing...';
        document.getElementById(statusID).ariaBusy = true;

        await delay(500);

        // get data from firestore
        const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            console.error('No such document!');
            showError('2030', 'Error Removing Temporary Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Remove Temporary Badge';
            document.getElementById(statusID).ariaBusy = false;
            return false;
        }

        const data = docSnap.data();

        // update the student's badgeID
        await updateDoc(docRef, {
            badgeID: data.personalBadgeID,
            lastUpdateType: 'admin-remove-temp-badge',
            lastUpdateDate: Timestamp.now(),
            tempBadgeAssigned: false
        });

        // Update the badge doc
        // get the badge doc ID
        const badgeRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('assignedTo', '==', data.misID));
        const querySnapshot = await getDocs(badgeRef);

        if (querySnapshot.size === 0) {
            console.error('Cannot find temp badge data');
            showError('2031', 'Error Removing Temporary Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Remove Temporary Badge';
            document.getElementById(statusID).ariaBusy = false;
            return false;
        }

        const badgeDocID = querySnapshot.docs[0].id;

        // update the badge doc
        const badgeDocRef = doc(db, 'domains', window.userDomain, 'badges', badgeDocID);
        const badgeDocSnap = await getDoc(badgeDocRef);

        if (!badgeDocSnap.exists()) {
            console.error('No such document!');
            showError('2032', 'Error Removing Temporary Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Remove Temporary Badge';
            document.getElementById(statusID).ariaBusy = false;
            return false;
        }

        // update the badge doc
        await updateDoc(badgeDocRef, {
            assignedTo: null,
            assignedAt: null,
        });

        // create a history record
        const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'badgeHistory');
        // action (assigned,unassigned), badge doc ID, student ID, timestamp
        await addDoc(historyRef, {
            action: 'unassigned',
            badgeID: badgeDocID,
            studentID: userID,
            timestamp: Timestamp.now(),
            adminID: window.userID,
            archived: false
        });


        // change the text
        document.getElementById(statusID).innerHTML = 'Removed!';
        document.getElementById(statusID).ariaBusy = false;

        // add a <p> to the edit-badge-detail div
        document.getElementById('edit-badge-detail').innerHTML += `
        <p>This student's personal badge has been re-enabled.</p>
        `;

        // reload the badge manager table
        await fillStudentManagerTable();

        return true;

    } catch (error) {
        console.error('Error in badge removal:', error);
        showError('2033', 'Error Removing Temporary Badge', 'Please try again.', '', false, true);

        // change the text
        document.getElementById(statusID).innerHTML = 'Remove Temporary Badge';
        document.getElementById(statusID).ariaBusy = false;

        return false;
    }
}


// function to assign a temporary badge to a student
// status ID is the HTML element to change the text of
// badge ID is the firestore doc ID - NOT the badge number
async function assignTempBadge(userID, badgeID, statusID) {
    console.log('assigning temp badge', badgeID, 'to', userID);
    console.log('status ID:', statusID);

    try {
        // change the text
        document.getElementById(statusID).innerHTML = 'Assigning...';
        document.getElementById(statusID).ariaBusy = true;

        // get the ID of the badge to assign
        // get data from firestore
        const badgeRef = doc(db, 'domains', window.userDomain, 'badges', badgeID);
        const badgeDoc = await getDoc(badgeRef);

        if (!badgeDoc.exists()) {
            console.error('No such document!');
            showError('2034', 'Error Assigning Temporary Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Assign Temporary Badge';
            document.getElementById(statusID).ariaBusy = false;
            return false;
        }

        const badgeData = badgeDoc.data();
        const badgeNumber = badgeData.badgeID; // terrible name in comparison to everything else..


        const badgeName = await assignTempBadgeToUser(userID, badgeNumber, badgeID, statusID);
        if (badgeName) {
            console.log('Badge assigned successfully');
            // change the text
            document.getElementById(statusID).innerHTML = 'Assigned!';
            document.getElementById(statusID).ariaBusy = false;

            // return false so the modal doesn't close, even though we had success
            return false;
        } else if (!badgeName) {
            showError('', 'Error Assigning Temporary Badge', 'This badge is already assigned to a student.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Assign Temp Badge';
            document.getElementById(statusID).ariaBusy = false;

            return false;
        } else {
            console.error('Badge not assigned - unknown error');
            showError('2035', 'Error Assigning Temporary Badge', 'There was an unknown error. Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Assign Temp Badge';
            document.getElementById(statusID).ariaBusy = false;

            return false;
        }
    } catch (error) {
        console.error('Error in badge assignment:', error);
        showError('2036', 'Error Assigning Temporary Badge', 'There was an unknown error. Please try again.', '', false, true);

        // change the text
        document.getElementById(statusID).innerHTML = 'Assign Temp Badge';
        document.getElementById(statusID).ariaBusy = false;

        return false;
    }
}

// Function to assign a temporary badge to a student
// badge ID is the ID of the badge to assign
// badgeDocID is the ID of the badge document
async function assignTempBadgeToUser(userID, badgeID, badgeDocID, statusID) {
    console.log('assigning temp badge', badgeID, 'to', userID);

    try {

        // Check if badgeID is already assigned to another student
        const badgeRef = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), where('badgeID', '==', badgeID));
        const querySnapshot = await getDocs(badgeRef);

        // if there are any docs, badge is already assigned
        if (!querySnapshot.empty) {

            // check if the badge is assigned to the current student
            // if the doc ID is the same as the userID, it's the same student
            if (querySnapshot.docs[0].id === userID) {
                // Badge is already assigned to the current student
                console.log('badge', badgeID, 'is already assigned to current student -', userID);
                return true;
            }
            // Badge is already assigned to another student
            // get the student's name
            const data = querySnapshot.docs[0].data();

            // remove the temp badge from the student
            await removeTempBadge(data.misID, statusID);
        }

        // get this student's doc
        const docRef2 = doc(db, 'domains', window.userDomain, 'students', userID);
        const docSnap2 = await getDoc(docRef2);

        if (!docSnap2.exists()) {
            console.error('No such document!');
            return false;
        }

        // check if this student already has a temp badge
        const data2 = docSnap2.data();
        if (data2.tempBadgeAssigned) {
            console.log('student', userID, 'already has a temp badge');
            return false;
        }

        const newBadgeRef = doc(db, 'domains', window.userDomain, 'badges', badgeDocID);
        const newBadgeDoc = await getDoc(newBadgeRef);

        if (!newBadgeDoc.exists()) {
            console.error('No such document!');
            return false;
        }

        const newBadgeData = newBadgeDoc.data();
        const newBadgeName = newBadgeData.name;

        // Badge is not assigned to another student, assign it
        const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            console.error('No such document!');
            return false;
        }

        // update the student's badgeID
        await updateDoc(docRef, {
            badgeID: badgeID,
            lastUpdateType: 'admin-temp-badge',
            lastUpdateDate: Timestamp.now(),
            tempBadgeAssigned: true
        });

        // Update the badge doc
        const badgeDocRef = doc(db, 'domains', window.userDomain, 'badges', badgeDocID);
        await updateDoc(badgeDocRef, {
            assignedTo: userID,
            assignedAt: Timestamp.now()
        });

        // create a history record
        const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'badgeHistory');
        // action (assigned,unassigned), badge doc ID, student ID, timestamp
        await addDoc(historyRef, {
            action: 'assigned',
            badgeID: badgeDocID,
            studentID: userID,
            timestamp: Timestamp.now(),
            adminID: window.userID,
            archived: false,
        });

        // reload the badge manager table
        await loadBadges();

        return newBadgeName;

    } catch (error) {
        console.error('Error assigning badge:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}

async function assignBadgeToAdmin(userID, badgeID) {
    console.log('assigning badge', badgeID, 'to admin', userID);

    try {

        // run the checkBadgeExists cloud function
        const checkBadgeExists = httpsCallable(functions, 'checkBadgeExists');
        const result = await checkBadgeExists({
            badgeID: badgeID,
            domainId: window.userDomain,
            adminID: userID // the admin to assign the badge to
        });

        // will return exists, a bool - if it does exist, we cannot assign a badge to an admin
        console.log('checkBadgeExists result:', result);

        // if we didn't get a response
        if (!result) {
            console.error('No response from checkBadgeExists');
            showError('4017', 'Assign Badge', 'There was an error assigning the badge. Please try again.', '', false, false);
            return [false, null];
        }

        // if we got a response
        else if (result.data.success) {
            console.log('Badge assigned successfully');
            // change the text
            document.getElementById('admin-badge-assign-text').innerHTML = 'This badge has been assigned to you.';
            document.getElementById('admin-badge-assign-text').setAttribute('aria-busy', 'false');

            // reload the users table
            await loadUsers();

            return [true, null];
        } else {
            console.error('Unknown result from checkBadgeExists');
            showError('4019', 'Assign Badge', 'There was an error assigning the badge. Please try again.', '', false, false);
            document.getElementById('admin-badge-assign-text').setAttribute('aria-busy', 'false');

            return [false, null];
        }

    } catch (error) {
        console.error('Error assigning badge:', error);
        document.getElementById('admin-badge-assign-text').setAttribute('aria-busy', 'false');

        throw error; // Re-throw the error for the caller to handle
    }
}



// function to assign a new badge to a student
// status ID is the HTML element to change the text of
// if we're assigning to a student
async function assignNewBadge(userID, statusID, student = true) {
    console.log('assigning new badge for', userID);
    console.log('status ID:', statusID);

    try {
        // if a student, get their name for the batch status
        if (statusID === 'batch-status') {
            if (student) {
                // get data from firestore
                const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    console.error('No such document!');
                    showError('2037', 'Error Assigning New Badge', 'There was an error assigning the badge. Please try again.', '', false, true);
                    // change the text
                    document.getElementById(statusID).innerHTML = 'Assign New Badge';
                    document.getElementById(statusID).ariaBusy = false;
                    return false;
                }

                const data = docSnap.data();
                console.log('student name:', data.studentName);
                // Change the text in assign-badge-button-${userID}
                document.getElementById(statusID).innerHTML = `Tap a badge for ${data.studentName}...`;
            }
        } else {
            document.getElementById(statusID).innerHTML = 'Tap a badge...';
        }

        // Wait for badge ID from the listener
        const badgeID = await enableBadgeListener();
        console.log('got a badge ID:', badgeID);

        // change the text
        try {
            document.getElementById(statusID).innerHTML = 'Assign New Badge';
            document.getElementById(statusID).ariaBusy = true;
        } catch (error) {
            console.log('statusID not found');
        }

        await delay(500);

        var result = null;

        if (!student) {
            // not assigning to a student
            console.log('not assigning to a student');

            result = await assignBadgeToAdmin(userID, badgeID);
        } else {
            // assigning to a student
            console.log('assigning to a student');

            result = await assignBadgeToStudent(userID, badgeID);
        }

        ///// TODO::: MAKE ADMIN RETURN OBJECT SAME AS STUDENT ^^^^^

        const resultBool = result[0];
        const successAudioURL = new URL('../sound/error.mp3', import.meta.url);
        var successAudio = new Audio(successAudioURL);
        successAudio.volume = 1;

        if (resultBool) {
            console.log('Badge assigned successfully');
            // change the text
            try {
                document.getElementById(statusID).innerHTML = 'Assign New Badge';
                document.getElementById(statusID).ariaBusy = true;
            } catch (error) {
                console.log('statusID not found');
            }

            return true;
        } else if (!resultBool) {
            console.error('Badge not assigned - already assigned');
            // if the status id is batch-status
            if (statusID === 'batch-status') {
                // play the error sound
                await successAudio.play();
                document.getElementById(statusID).innerHTML = '';
            } else {
                // change the text
                document.getElementById(statusID).innerHTML = 'Assign New Badge';
            }

            document.getElementById(statusID).ariaBusy = false;
            const msg = result[1] === 'temp' ? 'This badge is a temporary badge.' : 'This badge is already assigned.';
            showError('', 'Unable to Assign Badge', msg, '', false, true);
            return false;
        } else {
            console.error('Badge not assigned - unknown error');
            // if the status id is batch-status
            if (statusID === 'batch-status') {
                // play the error sound
                await successAudio.play();
                document.getElementById(statusID).innerHTML = '';
            } else {
                // change the text
                document.getElementById(statusID).innerHTML = 'Assign New Badge';
            }

            document.getElementById(statusID).ariaBusy = false;
            showError('2039', 'Error Assigning New Badge', 'There was an unknown error. Please try again.', '', false, true);
            return false;
        }
    } catch (error) {
        console.error('Error in badge assignment:', error);

        // if the status id is batch-status
        if (statusID === 'batch-status') {
            // play the error sound
            const errorAudioURL = new URL('../sound/error.mp3', import.meta.url);
            var errorAudio = new Audio(errorAudioURL);
            errorAudio.volume = 1;
            await errorAudio.play();
            document.getElementById(statusID).innerHTML = '';
        } else {
            // change the text
            try {
                document.getElementById(statusID).innerHTML = 'Assign New Badge';
                document.getElementById(statusID).ariaBusy = false;
            } catch (error) {
                console.log('statusID not found');
            }
        }

        showError('2040', 'Error Assigning New Badge', 'There was an unknown error. Please try again.', '', false, true);
        return false;
    }
}

function enableBadgeListener() {
    console.log('enabling badge listener...');

    let currentInput = ''; // Initialize current input

    return new Promise((resolve) => {
        // Set up the event listener for keydown events
        const keydownHandler = (event) => {
            if (event.key === 'Enter') {
                if (currentInput.length === 10) {
                    resolve(currentInput); // Resolve the promise with the badge ID
                    currentInput = '';
                    document.removeEventListener('keydown', keydownHandler); // Clean up
                }
            } else if (event.key >= '0' && event.key <= '9') {
                if (currentInput.length < 10) {
                    currentInput += event.key; // Append the number to the current input
                }
            }
        };

        document.addEventListener('keydown', keydownHandler);
        keydownHandlerReference = keydownHandler; // Store the reference for later removal

        // Check for skip action every 500 milliseconds
        const skipCheckInterval = setInterval(() => {
            if (skipCurrentStudent) {
                console.log('Skip action detected. Exiting badge listener.');
                clearInterval(skipCheckInterval); // Clear the interval check
                document.removeEventListener('keydown', keydownHandler); // Clean up event listener
                resolve(null); // Resolve with null to indicate skipping
            }
        }, 500);

        // Cleanup function to clear the interval when the promise is settled
        const cleanup = () => {
            clearInterval(skipCheckInterval);
            document.removeEventListener('keydown', keydownHandler);
        };

        // Attach cleanup to both resolve and reject cases of the promise
        cleanup.bind(null, 'resolve');
        cleanup.bind(null, 'reject');
    });
}

// disable the badge listener
function disableBadgeListener() {
    console.log('disabling badge listener...');
    if (keydownHandlerReference) {
        document.removeEventListener('keydown', keydownHandlerReference);
        keydownHandlerReference = null; // Clear the reference after removal
    }
}

// Function to assign a badge to a student
async function assignBadgeToStudent(userID, badgeID) {
    console.log('assigning badge', badgeID, 'to', userID);

    if (skipCurrentStudent) {
        console.log('skipping current student...');
        return null;
    }

    try {
        // Check if badgeID is already assigned to another student
        const badgeRef = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), where('badgeID', '==', badgeID));
        const querySnapshot = await getDocs(badgeRef);

        // if there are any docs, badge is already assigned
        if (!querySnapshot.empty) {

            // check if the badge is assigned to the current student
            // if the doc ID is the same as the userID, it's the same student
            if (querySnapshot.docs[0].id === userID) {
                // Badge is already assigned to the current student
                console.log('badge', badgeID, 'is already assigned to current student -', userID);
                return [true, null];
            }
            // Badge is already assigned to another student
            console.log('badge', badgeID, 'is already assigned to another student');
            const studentName = querySnapshot.docs[0].data().studentName;
            return [false, studentName];
        }

        // check if the badge is already assigned to the another ADMIN
        const adminRef = query(collection(db, 'users'), where('badgeID', '==', badgeID), where('domain', '==', window.userDomain));
        const adminQuerySnapshot = await getDocs(adminRef);

        // if there are any docs, badge is already assigned
        if (!adminQuerySnapshot.empty) {
            // Badge is already assigned to another admin
            console.log('badge', badgeID, 'is already assigned to another admin');
            const name = adminQuerySnapshot.docs[0].data().name + ' (Admin)';
            return [false, name];
        }

        // check if it's a temp badge
        const tempBadgeRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('badgeID', '==', badgeID));
        const tempBadgeQuerySnapshot = await getDocs(tempBadgeRef);

        if (!tempBadgeQuerySnapshot.empty) {
            // Badge is a temp badge
            console.log('badge', badgeID, 'is a temp badge');
            return [false, 'temp'];
        }

        // Badge is not assigned to another student, assign it
        const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
        // if the user has a temp badge, remove it
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            console.error('No such document!');
            return [false, null];
        }

        const data = docSnap.data();

        if (data.tempBadgeAssigned) {
            // update the temp badge doc
            const tempBadgeRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('assignedTo', '==', data.misID));
            const tempBadgeQuerySnapshot = await getDocs(tempBadgeRef);

            if (tempBadgeQuerySnapshot.size === 0) {
                console.error('Cannot find temp badge data');
            }

            const tempBadgeDocID = tempBadgeQuerySnapshot.docs[0].id;

            // update the badge doc
            const tempBadgeDocRef = doc(db, 'domains', window.userDomain, 'badges', tempBadgeDocID);
            await updateDoc(tempBadgeDocRef, {
                assignedTo: null,
            });

        }

        await updateDoc(docRef, {
            badgeID: badgeID,
            personalBadgeID: badgeID, // assuming we are giving them a new badge, this is their personal badge
            lastUpdateType: 'admin-update-badge',
            lastUpdateDate: Timestamp.now(),
            tempBadgeAssigned: false
        });

        return [true, null];

    } catch (error) {
        console.error('Error assigning badge:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}


// Define the event listener function separately
function preventEnterKeyDefault(event) {
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }
}

// Define a function to remove the keydown event listener
function removeKeydownListener() {
    console.log('removing keydown listener');
    document.removeEventListener('keydown', preventEnterKeyDefault);
}

/// admin page stuff
// load the admin page data
async function loadAdmin() {
    console.log('Loading admin...');
    loadUsers();
    loadInvitedUsers();
}


// load users
export async function loadUsers() {
    console.log('Loading users...');
    // add the loading row
    const tableBody = document.querySelector('#users tbody');
    // clear the table
    tableBody.innerHTML = '';

    // get all the users in the domain
    const usersRef = query(collection(db, 'users'), orderBy('name'), where('domain', '==', window.userDomain), where('status', '!=', 'deleted'));
    const usersSnapshot = await getDocs(usersRef);
    console.log('Users in domain', window.userDomain, ':', usersSnapshot.size);
    // clear the table
    document.querySelector('#users tbody').innerHTML = '';

    // if there are no users
    if (usersSnapshot.size == 0) {
        console.log('No users found');
        const tableBody = document.querySelector('#users tbody');
        const row = document.createElement('tr');
        row.innerHTML = `
               <td colspan="6" style="text-align: center;">No users found</td>
               `;
        tableBody.appendChild(row);
    } else {
        // there are users
        console.log('Users found');
        // sort the users by name
        usersSnapshot.forEach(async (docu) => {
            // add to the table
            await addUserToTable(docu.data(), docu.id);
        });
    }
}

async function loadInvitedUsers() {
    console.log('Loading invited users...');
    // get all invited users in the domain
    // users/invitedUsers/users/{userID}
    const invitedUsersRef = query(collection(db, 'users', 'invitedUsers', 'users'), where('domain', '==', window.userDomain), where('status', '!=', 'deleted'));
    const invitedUsersSnapshot = await getDocs(invitedUsersRef);
    console.log('Invited users in domain', window.userDomain, ':', invitedUsersSnapshot.size);
    // clear the table
    document.querySelector('#invited-users tbody').innerHTML = '';

    // if there are no invited users
    if (invitedUsersSnapshot.size == 0) {
        console.log('No invited users found');
        const tableBody = document.querySelector('#invited-users tbody');
        const row = document.createElement('tr');
        row.innerHTML = `
               <td colspan="5" style="text-align: center;">No invited users found</td>
               `;
        tableBody.appendChild(row);
    } else {
        // there are invited users
        console.log('Invited users found');
        // sort the users by name
        invitedUsersSnapshot.forEach(async (docu) => {
            // add to the table
            await addInvitedUserToTable(docu.data(), docu.id);
        });
    }
}

// add an invited user to the table
async function addInvitedUserToTable(userData, ID) {

    const email = userData.email;
    var role = userData.role;

    var status = userData.status;
    // format status
    // created, email_sent
    if (status == 'created') {
        status = 'Created';
    } else if (status == 'email_sent') {
        status = 'Email Sent';
    } else if (status == 'completed') {
        status = 'Completed';
    } else if (status == 'deleted') {
        status = 'Cancelled';
    }

    // format role
    if (role == 'superadmin') {
        role = 'Super Admin';
    } else if (role == 'domainadmin') {
        role = 'Domain Admin';
    } else if (role == 'badgeadmin') {
        role = 'Badge Admin';
    } else if (role == 'viewer') {
        role = 'Viewer';
    } else if (role == 'kiosk') {
        role = 'Kiosk';
    } else if (role == 'firemarshal') {
        role = 'Fire Marshal';
    }

    // get which user invited the user
    const invitedByRef = doc(db, 'users', userData.invitedBy);
    const invitedByDoc = await getDoc(invitedByRef);
    const invitedByName = invitedByDoc.data().name;

    const tableBody = document.querySelector('#invited-users tbody');
    const row = document.createElement('tr');
    row.id = ID;

    row.innerHTML = `
    <td>${email}</td>
    <td>${role}</td>
    <td>${status}</td>
    <td>${invitedByName}</td>
    <td><a id="delete-invited-user-btn-${ID}" style='color: red;'>Cancel Invite</a></td>
    `;

    tableBody.appendChild(row);
    // event listener for delete user button
    document.getElementById(`delete-invited-user-btn-${ID}`).addEventListener('mousedown', async () => {
        console.log(`delete user button for invited user ${ID} has been clicked`);
        // set the global variable
        window.deletingObjectID = ID;
        window.deletingObjectType = 'invitedUser';
        // show the delete confirm modal
        openModal(document.getElementById('confirm-delete-modal'));
    });
}
// add a user to the table
async function addUserToTable(userData, ID) {
    const name = userData.name;

    var role = userData.role;
    // format role
    if (role == 'superadmin') {
        role = 'Super Admin';
    } else if (role == 'domainadmin') {
        role = 'Domain Admin';
    } else if (role == 'badgeadmin') {
        role = 'Badge Admin';
    } else if (role == 'viewer') {
        role = 'Viewer';
    } else if (role == 'firemarshal') {
        role = 'Fire Marshal';
    } else if (role == 'kiosk') {
        return; // don't add kiosk users to the table
    }

    // this is a timestamp - convert to date
    const lastLogin = userData.lastLogin.toDate().toLocaleString();
    const email = userData.email;
    const badgeID = userData.badgeID;
    var badgeHTML = '';

    // a button to open the badge assign modal
    // show Yes or No in green or red
    if (badgeID != null) {
        badgeHTML = `<a id="assign-admin-badge-button-${ID}" style='color: green;'>Yes</a>`;
    } else {
        badgeHTML = `<a id="assign-admin-badge-button-${ID}" style='color: red;'>No</a>`;
    }

    const tableBody = document.querySelector('#users tbody');
    const row = document.createElement('tr');
    row.id = ID;

    // name, email, role, domain, last login, delete button
    // if the user is the current user, dont add the delete button
    if (ID == window.userID) {
        row.innerHTML = `
        <td>${name}</td>
        <td>${email}</td>
        <td>${role}</td>
        <td>${lastLogin}</td>
        <td>${badgeHTML}</td>
        <td>-</td>
        `;

        tableBody.appendChild(row);
    } else {
        row.innerHTML = `
        <td>${name}</td>
        <td>${email}</td>
        <td>${role}</td>
        <td>${lastLogin}</td>
        <td>${badgeHTML}</td>
        <td><a id="delete-user-btn-${ID}" style='color: red;'>Disable User</a></td>
        `;

        tableBody.appendChild(row);

        // add event listener for delete user button
        document.getElementById(`delete-user-btn-${ID}`).addEventListener('mousedown', async () => {
            console.log(`delete user button for user ${ID} has been clicked`);
            // set the global variable
            window.deletingObjectID = ID;
            window.deletingObjectType = 'user';
            // show the delete confirm modal
            openModal(document.getElementById('confirm-delete-modal'));

        });
    }

    // add event listener for assign badge button
    document.getElementById(`assign-admin-badge-button-${ID}`).addEventListener('mousedown', async () => {
        console.log(`assign badge button for user ${ID} has been clicked`);
        // set the global variable
        assigningBadgeUserID = ID;
        await fillAssignBadgeModal();
        // show the badge assign modal
        openModal(document.getElementById('edit-admin-badge-modal'));
    });
}

// fill the badge assign modal
async function fillAssignBadgeModal() {
    console.log('filling admin badge assign modal...');

    // get the user data
    const userRef = doc(db, 'users', assigningBadgeUserID);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
        const data = userDoc.data();
        console.log('User data:', data);

        var removeBadgeButton = '';
        if (data.badgeID != null) {
            // the user has a badge
            removeBadgeButton = `<a href="#" id="remove-admin-badge-button-${assigningBadgeUserID}" role="button" style="background-color: red; border-color: red;" >Remove Badge</a>`;
        }


        document.getElementById('edit-admin-badge-detail').innerHTML = `
        <h2>${data.name}</h2>
        <a href="#" id="admin-assign-badge-button-${assigningBadgeUserID}" role="button">Assign Badge</a>
        ${removeBadgeButton}
        <p id="admin-badge-assign-text"></p>
        `;

        // add event listener for assign badge button
        document.getElementById(`admin-assign-badge-button-${assigningBadgeUserID}`).addEventListener('mousedown', async () => {
            console.log(`assign badge button for user admin-assign-badge-button-${assigningBadgeUserID} has been clicked`);
            // Unfocus the button
            document.getElementById(`admin-assign-badge-button-${assigningBadgeUserID}`).blur();
            // assign a badge
            const result = await assignNewBadge(assigningBadgeUserID, `admin-assign-badge-button-${assigningBadgeUserID}`, false);
            if (result) {
                // close the modal
                closeModal(document.getElementById('edit-admin-badge-modal'));
            }
        });

        if (data.badgeID != null) {
            // add event listener for remove badge button
            document.getElementById(`remove-admin-badge-button-${assigningBadgeUserID}`).addEventListener('mousedown', async () => {
                console.log(`remove badge button for user remove-admin-badge-button-${assigningBadgeUserID} has been clicked`);
                // remove the badge
                const result = await removeBadge(assigningBadgeUserID, `remove-admin-badge-button-${assigningBadgeUserID}`, false);
                if (result) {
                    // close the modal
                    closeModal(document.getElementById('edit-admin-badge-modal'));
                }
            });
        }

    } else {
        console.error('No such document!');
    }
}

// remove a badge
async function removeBadge(userID, statusID, student = true, personalBadge = false) {
    console.log('removing badge for', userID);
    console.log('status ID:', statusID);

    try {
        // Change the text in assign-badge-button-${userID}
        document.getElementById(statusID).innerHTML = 'Removing...';
        document.getElementById(statusID).ariaBusy = true;

        await delay(500);

        var result = null;

        if (!student) {
            // not removing from a student
            console.log('not removing from a student');

            result = await removeBadgeFromAdmin(userID);
        } else {
            // removing from a student
            console.log('removing from a student');

            result = await removeBadgeFromStudent(userID, personalBadge);
        }

        if (result) {
            console.log('Badge removed successfully');
            // change the text
            document.getElementById(statusID).innerHTML = 'Removed!';
            document.getElementById(statusID).ariaBusy = false;

            return true;
        } else if (!result) {
            showError('2034', 'Error Removing Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Remove Badge';
            document.getElementById(statusID).ariaBusy = false;

            return false;
        } else {
            console.error('Badge not removed - unknown error');
            showError('2035', 'Error Removing Badge', 'Please try again.', '', false, true);
            // change the text
            document.getElementById(statusID).innerHTML = 'Remove Badge';
            document.getElementById(statusID).ariaBusy = false;

            return false;
        }
    } catch (error) {
        console.error('Error in badge removal:', error);
        showError('2036', 'Error Removing Badge', 'Please try again.', '', false, true);

        // change the text
        document.getElementById(statusID).innerHTML = 'Remove Badge';
        document.getElementById(statusID).ariaBusy = false;

        return false;
    }
}

async function removeBadgeFromAdmin(userID) {
    console.log('removing badge from admin', userID);

    try {

        // run the checkBadgeExists cloud function
        const checkBadgeExists = httpsCallable(functions, 'checkBadgeExists');
        const result = await checkBadgeExists({
            domainId: window.userDomain,
            adminID: userID, // the admin to remove the badge from,
            removeAdminBadge: true // remove the badge from the admin
        });

        if (result) {
            console.log('Badge removed successfully');
            // change the text
            document.getElementById('admin-badge-assign-text').innerHTML = 'This badge has been removed.';
            document.getElementById('admin-badge-assign-text').setAttribute('aria-busy', 'false');

            // reload the users table
            await loadUsers();

            return true;
        } else {
            console.error('Badge not removed - unknown error');
            showError('4013', 'Error Removing Badge', 'There was an error removing the badge. Please try again.', '', false, true);
            // change the text
            document.getElementById('admin-badge-assign-text').innerHTML = 'This badge has not been removed.';
            document.getElementById('admin-badge-assign-text').setAttribute('aria-busy', 'false');

            return false;
        }
    } catch (error) {
        console.error('Error removing badge:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}

async function removeBadgeFromStudent(userID, personalBadge = false) {
    console.log('removing badge from student', userID);

    try {
        const docRef = doc(db, 'domains', window.userDomain, 'students', userID);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            console.error('No such document!');
            return false;
        }

        // update the student's badgeID
        await updateDoc(docRef, {
            badgeID: null,
            lastUpdateType: 'admin-remove-badge',
            lastUpdateDate: Timestamp.now(),
        });

        // if the badge is the personal badge, remove personalBadgeID
        if (personalBadge) {
            await updateDoc(docRef, {
                personalBadgeID: null,
            });
        }

        return true;
    } catch (error) {
        console.error('Error removing badge:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}



// fill the new user modal
async function fillCreateUserModal() {
    console.log('filling new user modal...');
    // fill the dropdown
    // do nothing for now
}

// called when the role dropdown is changed
async function handleNewUserRoleChange() {
    console.log('handling new user role change...');
    // get the selected role
    const role = document.querySelector('input[name="role"]:checked').value;
    // update the summary
    await updateNewUserRoleSummary(role);
}

// update the summary of ROLE
async function updateNewUserRoleSummary(role) {
    console.log('updating summary of new user role...');
    // get the summary
    const summary = document.getElementById('newUser-roleSummary');
    // check if there is a role
    if (role) {
        // there is a role
        // set the colour
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');
        // set the summary to the role
        summary.innerHTML = role;
    } else {
        // there is no role
        // set the summary to 'select role...'
        // set the colour to the default
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
        summary.innerHTML = 'Select role...';
    }
}

// create a new user
// add user data the array /users/invitedUsers/users
// this is an array of maps
// each map has the following keys:
// email, groups, invitedBy, role, domain, status
async function createNewUser() {
    console.log('creating new user...');
    // get the data
    const roleElement = document.querySelector('input[name="role"]:checked');
    var role = roleElement ? roleElement.value : null;

    const emailElement = document.getElementById('new-user-email');
    const email = emailElement ? emailElement.value : null;


    // check the data
    // email
    if (email == null || email.length == 0) {
        console.log('User email is empty');
        showError('', 'Create User', 'Please enter a valid email address.', null, false, false);
        return false;
    } else {
        // check the email is valid
        if (!validateEmail(email)) {
            console.log('User email is invalid');
            showError('', 'Create User', 'Please enter a valid email address.', null, false, false);
            return false;
        }
    }

    // format the role
    // value will be "Domain Admin", "Super Admin", "Badge Admin", "Viewer"
    // format to "domainadmin", "superadmin", "badgeadmin", "viewer"
    if (role == 'Domain Admin') {
        role = 'domainadmin';
    } else if (role == 'Super Admin') {
        role = 'superadmin';
    } else if (role == 'Badge Admin') {
        role = 'badgeadmin';
    } else if (role == 'Viewer') {
        role = 'viewer';
    } else if (role == 'Fire Marshal') {
        role = 'firemarshal';
    } else {
        console.error('Role is invalid:', role);
        showError('', 'Create User', 'Please select a valid role.', null, false, false);
        return false;
    }

    const data = {
        email: email,
        invitedBy: window.userID,
        domain: window.userDomain, // use the user's domain
        role: role,
        status: 'created'
    };


    // domain
    if (data.domain.length == 0) {
        console.log('User has no domain');
        // send sentry for this - domains should be automatically set, so this is a runtime error, not user input error
        showError('', 'Create User', 'There was an error creating the user.', new Error('User has no domain'), false, true);
        return false;
    }

    // role
    if (data.role.length == 0) {
        console.log('User has no role');
        showError('', 'Create User', 'Please select a valid role.', null, false, false);
        return false;
    }

    // print the user data
    console.log('User data:', data);

    // check if the user already exists - call checkUserExists cloud function
    // eventually this whole process should be done in the cloud function (!!)

    // get the function
    const checkUserExists = httpsCallable(functions, 'checkUserExists');
    // call the function
    console.log(`checking if user ${data.email} exists...`);
    const result = await checkUserExists(data);

    // check the result
    if (result.data.exists) {
        // the user already exists
        console.log('User already exists');
        showError('', 'User already exists', 'A user with that email already exists in Inscribe.', null, false, false);
        return false;
    }

    // create the map under /users/invitedUsers
    // add the user to the database
    const userRef = await addDoc(collection(db, 'users', 'invitedUsers', 'users'), data);
    console.log('User added with ID: ', userRef.id);

    // close the modal
    closeModal(document.getElementById('new-user-modal'));

    // wait 500ms, for the modal to close
    await delay(500);

    // reload the users table
    await loadInvitedUsers();


    // clear modal content
    document.getElementById('new-user-email').value = '';
    document.getElementById('newUser-roleSummary').innerHTML = 'Select role...';
    // reset the colours
    document.getElementById('newUser-roleSummary').style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
    // uncheck all the role radio buttons
    document.getElementById('newUser-roleOption-domainadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-badgeadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-viewer').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-firemarshal').getElementsByTagName('input')[0].checked = false;

}

// Load the badges table
async function loadBadges() {
    console.log('Loading badges...');

    // if the user is a viewer, hide the change column
    if (window.userRole === 'viewer') {
        if (document.getElementById('badges-change-column')) {
            document.getElementById('badges-change-column').remove();
        }
        if (document.getElementById('new-temp-badge-button')) {
            document.getElementById('new-temp-badge-button').remove();
        }
    }
    // get all the badges
    const badgesRef = query(collection(db, 'domains', window.userDomain, 'badges'), where('deleted', '==', false), orderBy('name'));
    const badgesSnapshot = await getDocs(badgesRef);

    // Pre-fetch all student data and store in an object
    const students = {};

    // get all records - for the date
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), where('archived', '==', false));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    const tableBody = document.querySelector('#badges-table tbody');
    // clear the table
    tableBody.innerHTML = '';

    // if there are no badges
    if (badgesSnapshot.size == 0) {
        console.log('No badges found');
        const row = document.createElement('tr');
        row.innerHTML = `
        <td colspan="6" style="text-align: center;">No badges found</td>
        `;
        tableBody.appendChild(row);
    } else {
        // there are badges
        // for each badge
        badgesSnapshot.forEach(async (docu) => {
            const badgeData = docu.data();

            const row = document.createElement('tr');
            row.id = `badge-${docu.id}`;

            const assignedTo = badgeData.assignedTo;

            // find the student's name
            var assignedToName = null;
            if (assignedTo != null) {
                // if the student data is null, it's a student in a previous interval
                if (students[assignedTo]) {
                    assignedToName = `${students[assignedTo].studentName} (${students[assignedTo].formGroup})`;
                } else {
                    assignedToName = ''; // empty - same as items page does
                }
            } else {
                assignedToName = 'Unassigned';
            }

            var assignedAt = null;
            try {
                assignedAt = humanReadableDate(badgeData.assignedAt.toDate(), 'extended');
            } catch (error) { // if the badge is not assigned
                assignedAt = '-';
            }

            let updateButton = '';
            if (window.userRole !== 'viewer') {
                updateButton = `<td><a id="update-badge-button-${docu.id}">Update</a></td>`;
            }

            // name, assigned to, assigned at, disable button, update button, history button
            row.innerHTML = `
            <td>${badgeData.name}</td>
            <td>${assignedToName}</td>
            <td>${assignedAt}</td>
            ${updateButton}
            <td><a id="history-badge-button-${docu.id}">History</a></td>
            `;

            // if the badge is not assigned, set it to green
            // green - colour = 'rgba(124, 179, 66, 0.38)';
            // red - colour = 'rgba(179, 66, 66, 0.38)';
            if (assignedTo == null) {
                row.style.backgroundColor = 'rgba(124, 179, 66, 0.38)';
            } else {
                row.style.backgroundColor = 'rgba(179, 66, 66, 0.38)';
            }

            tableBody.appendChild(row);


            if (window.userRole !== 'viewer') {
                // update button - change which student the badge is assigned to
                document.getElementById(`update-badge-button-${docu.id}`).addEventListener('mousedown', async () => {
                    console.log(`update badge button for badge ${docu.id} has been clicked`);
                    assigningTempBadgeID = docu.id;
                    await loadAssignBadge(docu.id);
                    // open modal
                    openModal(document.getElementById('assign-badge-modal'));
                });
            }

            // history button
            document.getElementById(`history-badge-button-${docu.id}`).addEventListener('mousedown', async () => {
                console.log(`history badge button for badge ${docu.id} has been clicked`);

                document.getElementById(`history-badge-button-${docu.id}`).setAttribute('aria-busy', 'true');
                await loadBadgeHistory(docu.id);
                // open modal
                openModal(document.getElementById('badge-history-modal'));
                document.getElementById(`history-badge-button-${docu.id}`).setAttribute('aria-busy', 'false');

            });
        });
    }
}

async function loadBadgeHistory(badgeID) {
    console.log('loading badge history FOR BADGE', badgeID);

    // get all history records for the badge
    const historyRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'badgeHistory'), where('badgeID', '==', badgeID), orderBy('timestamp', 'desc'));
    const historySnapshot = await getDocs(historyRef);

    // clear the table
    document.querySelector('#badge-history-table tbody').innerHTML = '';

    // if there are no history records
    if (historySnapshot.size == 0) {
        console.log('No history found');
        const tableBody = document.querySelector('#badge-history-table tbody');
        const row = document.createElement('tr');
        row.innerHTML = '<td colspan="4" style="text-align: center;">No history found</td>';
        tableBody.appendChild(row);
    } else {
        // there are history records
        console.log('History found');

        // for each history record - array of promises to fetch student details
        const historyDataPromises = historySnapshot.docs.map(async (docu) => {
            const historyData = docu.data();

            let studentName = '-';
            let studentForm = '-';
            let action = null;

            // if the student is unknown, it was a cretae/update event, so don't show the student name
            if (historyData.studentID) {
                // student assign/unassign events
                const studentRef = doc(db, 'domains', window.userDomain, 'students', historyData.studentID);
                const studentSnapshot = await getDoc(studentRef);

                if (studentSnapshot.exists()) {
                    const studentData = studentSnapshot.data();
                    studentName = studentData.studentName;
                    studentForm = studentData.formGroup;
                }
            }

            // get the admin's name
            if (historyData.adminID) {
                // get the admin's name
                const adminRef = doc(db, 'users', historyData.adminID);
                const adminDoc = await getDoc(adminRef);
                const adminData = adminDoc.data();
                action = `${historyData.action.charAt(0).toUpperCase() + historyData.action.slice(1)} by ${adminData.name} (Admin)`;
            }

            // Include timestamp for sorting if needed
            return {
                studentName,
                studentForm,
                // use a pre-defined action, else use the action from the history record
                action: action ? action : historyData.action.charAt(0).toUpperCase() + historyData.action.slice(1),
                timestamp: humanReadableDate(historyData.timestamp.toDate(), 'full')
            };
        });

        // wait for all the promises to resolve and then sort by timestamp if necessary
        const historyDataResults = await Promise.all(historyDataPromises);

        // Now append each row to the table in the correct order
        const tableBody = document.querySelector('#badge-history-table tbody');
        historyDataResults.forEach(data => {
            const row = document.createElement('tr');
            row.innerHTML = `<td>${data.studentName}</td>
                             <td>${data.studentForm}</td>
                             <td>${data.action}</td>
                             <td>${data.timestamp}</td>`;
            tableBody.appendChild(row);
        });
    }
}


// fill the assign badge modal
async function loadAssignBadge(badgeID) {
    // get the badge data
    const badgeRef = doc(db, 'domains', window.userDomain, 'badges', badgeID);
    const badgeDoc = await getDoc(badgeRef);

    // reset the modal
    document.getElementById('assign-search-container').style.display = 'none';
    document.getElementById('assign-badge-search').value = '';
    document.querySelector('#assign-badge-table tbody').innerHTML = '';


    if (badgeDoc.exists()) {
        const data = badgeDoc.data();
        console.log('Badge data:', data);

        // if the badge is assigned
        var assignedHTML = '';
        if (data.assignedTo != null) {
            // get the student's name
            const studentRef = doc(db, 'domains', window.userDomain, 'students', data.assignedTo);
            const studentSnapshot = await getDoc(studentRef);

            if (studentSnapshot.exists()) {
                const studentData = studentSnapshot.data();
                assignedHTML = `<p id="assign-text">Assigned to: ${studentData.studentName}</p>`;
            } else {
                assignedHTML = '<p id="assign-text">Assigned to: Unknown</p>';
            }

            // add the unassign button
            assignedHTML += `<a href="#" id="unassign-badge-button-${badgeID}" role="button" style="background-color: red; border-color: red;">Unassign Badge</a>`;
        } else {
            assignedHTML = '<p id="assign-text">Unassigned</p>';
        }


        // fill the modal
        document.getElementById('assign-detail').innerHTML = `
        <h2>${data.name}</h2>
        ${assignedHTML}
        <a href="#" id="assign-badge-button-${badgeID}" role="button">Assign Badge</a>
        `;

        // add event listener for button
        document.getElementById(`assign-badge-button-${badgeID}`).addEventListener('mousedown', async () => {
            console.log(`assign badge button for badge ${badgeID} has been clicked`);

            document.getElementById(`assign-badge-button-${badgeID}`).setAttribute('aria-busy', 'true');

            await prepareBadgeStudentSearch();

            // show the search stuff - assign-search-container
            document.getElementById('assign-search-container').style.display = 'block';
            // hide the assign button
            document.getElementById(`assign-badge-button-${badgeID}`).style.display = 'none';
            // hide the text
            document.getElementById('assign-text').style.display = 'none';

            document.getElementById(`assign-badge-button-${badgeID}`).setAttribute('aria-busy', 'false');
        });

        // add event listener for unassign button
        if (data.assignedTo != null) {
            document.getElementById(`unassign-badge-button-${badgeID}`).addEventListener('mousedown', async () => {
                console.log(`unassign badge button for badge ${badgeID} has been clicked`);
                // unassign the badge
                const result = await removeTempBadge(data.assignedTo, `unassign-badge-button-${badgeID}`, false);
                if (result) {
                    // reload the badges table
                    await loadBadges();
                    // close the modal
                    closeModal(document.getElementById('assign-badge-modal'));
                }
            });
        }

    } else {
        console.error('No such document!');
    }
}

// load the data into a local variable
// for use in the search function
async function prepareBadgeStudentSearch() {
    console.log('preparing badge student search');

    badgeStudentData = [];

    // clear the table & search query

    document.getElementById('assign-badge-search').value = '';
    document.querySelector('#assign-badge-table tbody').innerHTML = '';
    lastBadgeSearchQuery = '';

    // get the students - trial students only for now
    const studentsQuery = query(collection(db, 'domains', window.userDomain, 'students'), where('intervals', 'array-contains', window.currentInterval), where('archived', '==', false));
    const studentsQuerySnapshot = await getDocs(studentsQuery);

    // if no students found, show no students found
    if (studentsQuerySnapshot.size === 0) {
        console.log('no students found');
        document.querySelector('#assign-badge-table tbody').innerHTML = '<tr><td colspan="2" style="text-align: center;">No students found</td></tr>';
        return;
    }

    console.log(`Found ${studentsQuerySnapshot.size} students`);

    // loop through students
    studentsQuerySnapshot.forEach((studentDoc) => {
        // get the student document
        const studentDocData = studentDoc.data();

        // add the student to the search data
        badgeStudentData.push({
            studentName: studentDocData.studentName,
            formGroup: studentDocData.formGroup,
            misID: studentDoc.id,
            personalBadgeID: studentDocData.personalBadgeID,
            badgeID: studentDocData.badgeID,
        });
    });

    console.log('badge student search data prepared');

    // show the table & search box
    document.getElementById('assign-search-container').style.display = 'block';
    document.getElementById('assign-badge-table').style.display = 'table';
    document.getElementById('assign-badge-search').style.display = 'block';
    // focus the search box
    document.getElementById('assign-badge-search').focus();
}

// contents of the batch search box have changed
async function badgeSearchNameChanged() {
    // if the search query is empty, clear the table
    if (document.getElementById('assign-badge-search').value === '') {
        document.querySelector('#assign-badge-table tbody').innerHTML = '';
        return;
    }

    const tableBody = document.querySelector('#assign-badge-table tbody');

    // check if the query has changed
    if (document.getElementById('assign-badge-search').value !== lastBadgeSearchQuery) {
        lastBadgeSearchQuery = document.getElementById('assign-badge-search').value;
        tableBody.innerHTML = '';
        badgeStudentData.forEach((student) => {
            // if the student name contains the search query
            if (student.studentName.toLowerCase().includes(lastBadgeSearchQuery.toLowerCase())) {
                // create the row
                const row = document.createElement('tr');
                row.innerHTML = `
                <td>${student.studentName}</td>
                <td>${student.formGroup}</td>
                `;
                row.id = `badge-student-${student.misID}`;


                // if the student's personal badge ID equals their badge ID 
                // (i.e. their currently assigned badge is their personal badge, they do not have a temp badge
                if (student.personalBadgeID === student.badgeID) {
                    // add the row to the table
                    tableBody.appendChild(row);

                    document.getElementById(`badge-student-${student.misID}`).addEventListener('mousedown', async () => {
                        console.log(`row for student ${student.misID} has been clicked`);

                        // show the progress bar
                        document.getElementById('assign-badge-progress').style.display = 'block';

                        await assignTempBadge(student.misID, assigningTempBadgeID, 'assign-detail');

                        // hide the progress bar
                        document.getElementById('assign-badge-progress').style.display = 'none';

                        await delay(500);
                        // close the modal
                        closeModal(document.getElementById('assign-badge-modal'));
                    });
                } else {
                    row.style.textDecoration = 'line-through';
                    // hover text
                    row.title = 'This student already has a temporary badge assigned';
                    tableBody.appendChild(row);
                }
            }
        });

    } else {
        console.log('search query has not changed');
        return;
    }
}

async function loadNewTempBadge() {
    // reset identify-detail
    document.getElementById('new-temp-detail').innerHTML = 'Tap a badge...';
    document.getElementById('new-temp-detail').setAttribute('aria-busy', 'true');
    // hide new-temp-badge-name
    document.getElementById('new-temp-badge-name').style.display = 'none';
    document.getElementById('new-temp-badge-name').value = '';
    // hide confirm-create-temp-badge-button
    document.getElementById('confirm-create-temp-badge-button').style.display = 'none';

    // deselect the button so badge reader enter doesn't trigger it
    document.getElementById('new-temp-badge-button').blur();

    // start listening for a badge
    const badgeID = await enableBadgeListener();
    console.log('Tapped Badge ID:', badgeID);

    // call the checkBadgeExists cloud function
    document.getElementById('new-temp-detail').innerHTML = 'Checking if this badge already exists...';
    const checkBadgeExists = httpsCallable(functions, 'checkBadgeExists');
    const result = await checkBadgeExists({
        badgeID: badgeID,
        domainId: window.userDomain
    });

    // will return exists, a bool - if it does exist, we cannot create a new temp badge
    console.log('checkBadgeExists result:', result);

    // if we didn't get a response
    if (!result) {
        console.error('No response from checkBadgeExists');
        showError('', 'New Temporary Badge', 'There was an error creating the badge. Please try again.', '', false, false);
        return;
    }

    // if we got a response. and the badge exists
    else if (result.data.exists) {
        console.log('Badge already exists');
        // change the text
        document.getElementById('new-temp-detail').innerHTML = 'This badge already exists.';
        document.getElementById('new-temp-detail').setAttribute('aria-busy', 'false');
        return;
    }

    else if (!result.data.exists) {
        console.log('Badge does not exist, safe to create');

        /// badge is safe to assign

        // show the text box, hide the badge detail
        document.getElementById('new-temp-detail').setAttribute('aria-busy', 'false');
        document.getElementById('new-temp-detail').innerHTML = 'Enter a name for the badge:';
        document.getElementById('new-temp-badge-name').style.display = 'block';
        document.getElementById('new-temp-badge-name').focus();
        document.getElementById('confirm-create-temp-badge-button').style.display = 'block';

        // set the global variable
        newTempBadgeID = badgeID;
    }
}

async function createTempBadge() {
    console.log(`creating temp badge for badge ${newTempBadgeID}`);

    // get the badge name
    const badgeName = document.getElementById('new-temp-badge-name').value;

    // if the name is empty, null, etc
    if (badgeName.length == 0 || badgeName == null) {
        console.log('Badge name is empty');
        showError('', 'New Temporary Badge', 'Please enter a name for the badge.', '', false, true);
        return;
    }

    // create the badge
    const badgeData = {
        name: badgeName,
        assignedTo: null,
        assignedAt: null,
        badgeID: newTempBadgeID,
        deleted: false
    };

    // add the badge to the database
    const badgeRef = await addDoc(collection(db, 'domains', window.userDomain, 'badges'), badgeData);
    console.log('Badge added with ID: ', badgeRef.id);

    // create a badge history record
    const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'badgeHistory');
    await addDoc(historyRef, {
        badgeID: badgeRef.id,
        action: 'created',
        studentID: null,
        timestamp: Timestamp.now(),
        adminID: window.userID,
        archived: false
    });

    await loadBadges();
}