// all students page
import { db } from '../shared/firebase.js';
import { collection, getDocs, query, where, orderBy, limit, doc, getDoc } from 'firebase/firestore';
import { updateStudentOnsite } from '../index.js';
import { humanReadableDate } from './utils/date-format.js';

export async function fillAllStudentsTable() {
    console.log('Filling all students table');

    // if the user is a viewer, hide the update column
    if (window.userRole === 'viewer') {
        if (document.getElementById('y12-all-update-column')) {
            document.getElementById('y12-all-update-column').remove();
        }
        if (document.getElementById('y13-all-update-column')) {
            document.getElementById('y13-all-update-column').remove();
        }
    }

    const y12TableBody = document.querySelector('#all-y12-table tbody');
    const y13TableBody = document.querySelector('#all-y13-table tbody');

    y12TableBody.innerHTML = '';
    y13TableBody.innerHTML = '';

    // Execute in parallel to reduce wait time
    await Promise.all([
        fetchAndProcessStudents('12', y12TableBody),
        fetchAndProcessStudents('13', y13TableBody)
    ]).catch(error => console.error('Error processing students:', error));
}

async function fetchAndProcessStudents(yearGroup, tableBody) {
    const studentsQuerySnapshot = await getDocs(query(collection(db, 'domains', window.userDomain, 'students'),
        where('archived', '==', false),
        where('yearGroup', '==', yearGroup),
        where('intervals', 'array-contains', window.currentInterval),
        orderBy('yearIndex')));

    // Initiate all attendance time fetches in parallel
    const attendancePromises = studentsQuerySnapshot.docs.map(doc =>
        fetchLatestAttendanceTime(doc.id).then(latestAttendanceTime => ({
            ...doc.data(),
            id: doc.id,
            latestAttendanceTime
        }))
    );

    // Wait for all attendance times before proceeding
    const students = await Promise.all(attendancePromises);

    // Render each student row
    students.forEach(student => {
        createStudentRow(student, tableBody);
    });
}

async function fetchLatestAttendanceTime(studentId) {
    const attendanceSnapshot = await getDocs(query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'),
        where('studentID', '==', studentId),
        orderBy('time', 'desc'),
        limit(1)));

    if (attendanceSnapshot.empty) return 'Never';

    const attendanceData = attendanceSnapshot.docs[0].data();
    const attendanceDate = attendanceData.time.toDate();
    return humanReadableDate(attendanceDate, 'simple');
}

function createStudentRow(student, tableBody) {
    const { id, studentName, formGroup, onsite, latestAttendanceTime } = student;

    const colour = onsite ? 'rgba(124, 179, 66, 0.38)' : 'rgba(216, 27, 96, 0.38)';

    let updateButton = '';
    // depending on if the user is a viewer
    if (window.userRole !== 'viewer') {
        updateButton = `<td><a id="update-student-${id}" class="update-button">${onsite ? 'Sign Out' : 'Sign In'}</a></td>`;
    }

    const row = document.createElement('tr');
    row.innerHTML = `
        <th scope="row">${studentName}</th>
        <td>${formGroup}</td>
        <td>${latestAttendanceTime}</td>
        ${updateButton}
    `;
    row.style.backgroundColor = colour;
    row.id = `home-${id}`;

    tableBody.appendChild(row);

    // if the user is not a viewer, add event listener to update button
    if (window.userRole !== 'viewer') {
        document.getElementById(`update-student-${id}`).addEventListener('mousedown', async () => {
            console.log(`update-student-${id} clicked`);
            const updateButtonElement = document.getElementById(`update-student-${id}`);
            updateButtonElement.setAttribute('aria-busy', 'true');

            await updateStudentOnsite(id, onsite, 'home'); // don't reload the home table
            await refreshRow(id);

            updateButtonElement.setAttribute('aria-busy', 'false');
        });
    }

    return row;
}

// silently refresh all rows - change individual rows if needed.
// used when the user re-activates the tab.
// avoids clearing/re-rendering the entire table
export async function refreshAllHomeRows() {
    // check if both tables have more thsn 1 row
    if (document.querySelector('#all-y12-table tbody').childElementCount < 2 && document.querySelector('#all-y12-table tbody').childElementCount < 2) {
        console.log('No students found');
        return;
    }

    console.log('Refreshing all home rows');

    // get all students
    const studentsQuerySnapshot = await getDocs(query(collection(db, 'domains', window.userDomain, 'students'),
        where('archived', '==', false),
        where('intervals', 'array-contains', window.currentInterval),
        orderBy('yearIndex')));

    // run refreshRow for each student
    studentsQuerySnapshot.docs.forEach(doc => {
        refreshRow(doc.id);
    });
}

async function refreshRow(studentID) {
    // check the row exists for this student
    if (!document.getElementById(`home-${studentID}`)) {
        console.error(`Row for student ${studentID} not found`);
        return;
    }

    const studentDocRef = doc(db, 'domains', window.userDomain, 'students', studentID);
    const studentDocSnap = await getDoc(studentDocRef);
    const updatedStudent = studentDocSnap.data();

    const colour = updatedStudent.onsite ? 'rgba(124, 179, 66, 0.38)' : 'rgba(216, 27, 96, 0.38)';
    const latestAttendanceTime = await fetchLatestAttendanceTime(studentID);

    let updateButton = '';
    // depending on if the user is a viewer
    if (window.userRole !== 'viewer') {
        updateButton = `<td><a id="update-student-${studentID}" class="update-button">${updatedStudent.onsite ? 'Sign Out' : 'Sign In'}</a></td>`;
    }

    // Update the row with new data
    document.getElementById(`home-${studentID}`).innerHTML = `
    <th scope="row">${updatedStudent.studentName}</th>
    <td>${updatedStudent.formGroup}</td>
    <td>${latestAttendanceTime}</td>
    ${updateButton}
    `;
    document.getElementById(`home-${studentID}`).style.backgroundColor = colour;

    // if the user is not a viewer, add event listener to update button
    if (window.userRole !== 'viewer') {
        document.getElementById(`update-student-${studentID}`).addEventListener('mousedown', async () => {
            console.log(`update-student-${studentID} clicked`);
            const updateButtonElement = document.getElementById(`update-student-${studentID}`);
            updateButtonElement.setAttribute('aria-busy', 'true');

            await updateStudentOnsite(studentID, updatedStudent.onsite, false); // false means don't reload the home table
            await refreshRow(studentID);

            updateButtonElement.setAttribute('aria-busy', 'false');
        });
    }
}