let tooltipTimeouts = new Map();

export function simulateTooltipHover(element, timeout = true) {
    console.log(`simulating hover for ${element.id} with timeout ${timeout}`);
    const tooltipText = element.getAttribute('data-tooltip-custom') || 'Tooltip';
    const tooltipPlacement = element.getAttribute('data-placement-tooltip') || 'top';

    // Clear any existing timeout for the tooltip
    if (tooltipTimeouts.has(element)) {
        console.log('clearing existing tooltip timeout');
        clearTimeout(tooltipTimeouts.get(element));
        tooltipTimeouts.delete(element);
    }

    // If the tooltip is already showing, just extend the timeout
    if (element._tooltip) {
        console.log('tooltip is already showing, extending timeout');
        if (timeout) {
            const newTimeout = setTimeout(() => hideTooltip(element), 3000);
            tooltipTimeouts.set(element, newTimeout);
            console.log(`set timeout for ${element.id} to ${newTimeout}`);
        }
    } else {
        createTooltip(element, tooltipText, tooltipPlacement);
        if (timeout) {
            console.log('setting timeout');
            const newTimeout = setTimeout(() => hideTooltip(element), 3000);
            tooltipTimeouts.set(element, newTimeout);
            console.log(`set timeout for ${element.id} to ${newTimeout}`);
        }
    }
}

export function createTooltip(element, text, placement = 'top') {
    console.log(`creating tooltip for ${element.id} with text ${text} and placement ${placement}`);
    // Remove any existing tooltip first
    hideTooltip(element);

    // Create tooltip element
    const tooltip = document.createElement('div');
    tooltip.className = `tooltip ${placement}`;
    tooltip.innerText = text;

    // Style tooltip based on placement
    tooltip.style.position = 'absolute';

    document.body.appendChild(tooltip);

    const rect = element.getBoundingClientRect();

    switch (placement) {
    case 'top':
        tooltip.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (tooltip.offsetWidth / 2)}px`;
        tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 10}px`; // Adjusted for arrow size
        break;
    case 'bottom':
        tooltip.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (tooltip.offsetWidth / 2)}px`;
        tooltip.style.top = `${rect.bottom + window.scrollY + 10}px`; // Adjusted for arrow size
        break;
    case 'left':
        tooltip.style.left = `${rect.left + window.scrollX - tooltip.offsetWidth - 10}px`; // Adjusted for arrow size
        tooltip.style.top = `${rect.top + window.scrollY + (rect.height / 2) - (tooltip.offsetHeight / 2)}px`;
        break;
    case 'right':
        tooltip.style.left = `${rect.right + window.scrollX + 10}px`; // Adjusted for arrow size
        tooltip.style.top = `${rect.top + window.scrollY + (rect.height / 2) - (tooltip.offsetHeight / 2)}px`;
        break;
    }

    // Store reference to the tooltip element in the button element
    element._tooltip = tooltip;

    // Trigger the animation
    requestAnimationFrame(() => {
        tooltip.classList.add('show');
    });

    console.log(`created tooltip for ${element.id}`);
}


export function hideTooltip(element) {
    console.log(`hiding tooltip for ${element.id}`);
    if (element._tooltip) {
        console.log('removing tooltip...');
        element._tooltip.classList.remove('show');
        setTimeout(() => {
            if (element._tooltip) {
                document.body.removeChild(element._tooltip);
                delete element._tooltip;
                console.log('removed tooltip');
            }
        }, 300); // Match the duration of the CSS transition
        // Clear any existing timeout for the tooltip
        if (tooltipTimeouts.has(element)) {
            clearTimeout(tooltipTimeouts.get(element));
            tooltipTimeouts.delete(element);
            console.log('cleared existing tooltip timeout');
        }
    }
}
