import { db } from '../shared/firebase.js';
import { collection, getDocs, query, where, orderBy, doc, getDoc, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { openModal, closeModal } from '../shared/modal.js';
import { humanReadableDate } from '../admin/utils/date-format.js';
import { showError } from '../shared/error.js';
import { downloadCSV } from './utils/csv.js';
import { downloadPDF } from './utils/pdf.js';
import { createSpreadsheet } from './utils/sheet.js';
import { getFormGroups } from '../admin/utils/form-groups.js';
import { delay } from '../shared/delay.js';


var editingEventID = null;

export async function loadEvents(archived = false) {
    console.log(`Loading events - archived: ${archived}`);

    // if the user is a viewer, hide the edit and archive columns
    if (window.userRole === 'viewer') {
        if (document.getElementById('events-edit-column')) {
            document.getElementById('events-edit-column').remove();
        }
        if (document.getElementById('events-archive-column')) {
            document.getElementById('events-archive-column').remove();
        }
        if (document.getElementById('new-event-button')) {
            document.getElementById('new-event-button').remove();
        }
    }

    var eventsRef = null;
    if (archived) {
        // show all
        eventsRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events'), orderBy('start', 'desc'));
    } else {
        // show not archived
        eventsRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events'), where('archived', '==', false), orderBy('start', 'desc'));
    }


    const eventsSnapshot = await getDocs(eventsRef);

    const tableBody = document.querySelector('#events-table tbody');
    tableBody.innerHTML = '';

    // if there are no events
    if (eventsSnapshot.size == 0) {
        console.log('No events found');
        const row = document.createElement('tr');
        row.innerHTML = `
        <td colspan="9" style="text-align: center;">No events found</td>
        `;
        tableBody.appendChild(row);
    } else {
        // there are events
        console.log('Events found');

        // for each event
        eventsSnapshot.forEach((docu) => {
            const eventData = docu.data();

            var attendedCount = 'None';
            // if any students have attended, count them
            if (eventData.attendedStudents != null && eventData.attendedStudents.length > 0) {
                attendedCount = `${eventData.attendedStudents.length} - <a id="view-attended-students-button-${docu.id}">View</a>`;
            }

            var expectedCount = 'None';
            // if there are students expected, count them
            if (eventData.expectedStudents != null && eventData.expectedStudents.length > 0) {
                expectedCount = `${eventData.expectedStudents.length} - <a id="view-expected-students-button-${docu.id}">View</a>`;
            }

            const eventStartDate = eventData.start.toDate();
            const eventEndDate = eventData.end.toDate();

            let eventStart = humanReadableDate(eventStartDate, 'extended');
            let eventEnd = humanReadableDate(eventEndDate, 'extended');

            const row = document.createElement('tr');
            row.id = `event-${docu.id}`;

            let editButton = '';
            if (window.userRole !== 'viewer') {
                editButton = `<td><a id="edit-event-button-${docu.id}">Edit</a></td>`;
            }
            let archiveButton = '';
            if (window.userRole !== 'viewer') {
                archiveButton = `<td><a id="archive-event-button-${docu.id}">${eventData.archived ? 'Unarchive' : 'Archive'}</a></td>`;
            }

            // name, location, date/time, expected count, attended count, edit button, delete button
            row.innerHTML = `
            <td>${eventData.name}</td>
            <td>${eventData.location}</td>
            <td>${eventStart}</td>
            <td>${eventEnd}</td>
            <td>${expectedCount}</td>
            <td>${attendedCount}</td>
            ${editButton}
            ${archiveButton}
            <td><a id="export-event-button-${docu.id}">Export</a></td>
            `;

            tableBody.appendChild(row);

            // add event listener for edit
            if (window.userRole !== 'viewer') {
                document.getElementById(`edit-event-button-${docu.id}`).addEventListener('mousedown', async () => {
                    console.log(`edit event button for event ${docu.id} has been clicked`);
                    document.getElementById(`edit-event-button-${docu.id}`).setAttribute('aria-busy', 'true');
                    editingEventID = docu.id;
                    await loadEditEvents(docu.id);
                    // open modal
                    openModal(document.getElementById('edit-event-modal'));
                    document.getElementById(`edit-event-button-${docu.id}`).setAttribute('aria-busy', 'false');
                });

                // add event listener for archive
                document.getElementById(`archive-event-button-${docu.id}`).addEventListener('mousedown', async () => {
                    console.log(`archive event button for event ${docu.id} has been clicked`);
                    document.getElementById(`archive-event-button-${docu.id}`).setAttribute('aria-busy', 'true');
                    await archiveEvent(docu.id);
                    // if the button still exists, set aria-busy to false
                    // will exist if archived is showing, won't if not
                    if (document.getElementById(`archive-event-button-${docu.id}`)) {
                        document.getElementById(`archive-event-button-${docu.id}`).setAttribute('aria-busy', 'false');
                    }
                });
            }

            // add event listener for view attended students
            if (attendedCount != 'None') {
                document.getElementById(`view-attended-students-button-${docu.id}`).addEventListener('mousedown', async () => {
                    console.log(`view attended students button for event ${docu.id} has been clicked`);
                    document.getElementById(`view-attended-students-button-${docu.id}`).setAttribute('aria-busy', 'true');
                    await loadAttendedStudents(docu.id);
                    // open modal
                    openModal(document.getElementById('event-attendance-modal'));
                    document.getElementById(`view-attended-students-button-${docu.id}`).setAttribute('aria-busy', 'false');
                });
            }

            // add event listener for view expected students
            if (expectedCount != 'None') {
                document.getElementById(`view-expected-students-button-${docu.id}`).addEventListener('mousedown', async () => {
                    console.log(`view expected students button for event ${docu.id} has been clicked`);
                    document.getElementById(`view-expected-students-button-${docu.id}`).setAttribute('aria-busy', 'true');
                    await loadExpectedStudents(docu.id);
                    // open modal
                    openModal(document.getElementById('event-attendance-modal'));
                    document.getElementById(`view-expected-students-button-${docu.id}`).setAttribute('aria-busy', 'false');
                });
            }

            document.getElementById(`export-event-button-${docu.id}`).addEventListener('mousedown', () => {
                console.log(`export event button for event ${docu.id} has been clicked`);

                // Remove existing listeners before adding new ones to avoid duplication
                const downloadPdfButton = document.getElementById('event-download-pdf');
                const downloadCsvButton = document.getElementById('event-download-csv');
                const downloadSheetButton = document.getElementById('event-download-sheet');

                if (downloadPdfButton) {
                    downloadPdfButton.replaceWith(downloadPdfButton.cloneNode(true));
                }

                if (downloadCsvButton) {
                    downloadCsvButton.replaceWith(downloadCsvButton.cloneNode(true));
                }

                if (downloadSheetButton) {
                    downloadSheetButton.replaceWith(downloadSheetButton.cloneNode(true));
                }

                // Add common download listeners for the current event
                addCommonDownloadListeners(docu);

                openModal(document.getElementById('events-export-modal'));
            });


        });
    }
}

// Common event listeners for download buttons
const addCommonDownloadListeners = (docu) => {
    const downloadPdfButton = document.getElementById('event-download-pdf');
    const downloadCsvButton = document.getElementById('event-download-csv');
    const downloadSheetButton = document.getElementById('event-download-sheet');

    if (downloadPdfButton) {
        downloadPdfButton.addEventListener('mousedown', async () => {
            console.log(`download pdf button for event ${docu.id} has been clicked`);
            downloadPdfButton.setAttribute('aria-busy', 'true');
            await exportEventAttendance(docu.id, 'pdf');
            downloadPdfButton.setAttribute('aria-busy', 'false');
        });
    }

    if (downloadCsvButton) {
        downloadCsvButton.addEventListener('mousedown', async () => {
            console.log(`download csv button for event ${docu.id} has been clicked`);
            downloadCsvButton.setAttribute('aria-busy', 'true');
            await exportEventAttendance(docu.id, 'csv');
            downloadCsvButton.setAttribute('aria-busy', 'false');
        });
    }

    if (downloadSheetButton) {
        downloadSheetButton.addEventListener('mousedown', async () => {
            console.log(`download sheet button for event ${docu.id} has been clicked`);
            downloadSheetButton.setAttribute('aria-busy', 'true');
            await exportEventAttendance(docu.id, 'sheet');
            downloadSheetButton.setAttribute('aria-busy', 'false');
        });
    }
};

async function exportEventAttendance(eventID, format) {
    console.log('Exporting event...');

    var exportData = [];

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', eventID);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
        console.error('No such document!');
        showError('2035', 'There was an error exporting the event.', 'Please try again later.', false, false);
        return;
    }

    // fill the modal
    if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        const attendedStudents = eventData.attendedStudents;
        const expectedStudents = eventData.expectedStudents;

        // Pre-fetch all student data and store in an object
        const students = {};

        // get all records - for the date
        const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), orderBy('yearIndex', 'asc'), where('intervals', 'array-contains', window.currentInterval));

        // Pre-fetch all the event history records for this event - exclude create/archive
        const eventHistoryRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'eventHistory'), where('eventID', '==', eventID), where('adminID', '==', null), orderBy('timestamp', 'desc'));
        const eventHistorySnapshot = await getDocs(eventHistoryRef);
        const eventHistoryData = eventHistorySnapshot.docs.map(doc => doc.data());

        await getDocs(studentsRef).then((querySnapshot) => {
            let attendedData = [];
            let expectedData = [];

            querySnapshot.forEach(async (doc) => {
                const studentData = doc.data();
                students[studentData.misID] = studentData;

                if (format !== 'sheet') {
                    // Separate attended and expected students into two arrays
                    if (attendedStudents.includes(studentData.misID)) {
                        const studentHistory = eventHistoryData.find(history => history.studentID === studentData.misID);
                        attendedData.push({
                            Name: studentData.studentName,
                            FormGroup: studentData.formGroup,
                            Status: 'Attended',
                            Time: studentHistory.timestamp.toDate().toLocaleString()
                        });
                    } else if (expectedStudents.includes(studentData.misID)) {
                        expectedData.push({
                            Name: studentData.studentName,
                            FormGroup: studentData.formGroup,
                            Status: 'Not Attended' // i.e. expected
                        });
                    }
                } else {
                    // sheet format - no data names
                    // Separate attended and expected students into two arrays
                    if (attendedStudents.includes(studentData.misID)) {
                        const studentHistory = eventHistoryData.find(history => history.studentID === studentData.misID);
                        attendedData.push([
                            studentData.studentName,
                            studentData.formGroup,
                            'Attended',
                            studentHistory.timestamp.toDate().toLocaleString()
                        ]);
                    } else if (expectedStudents.includes(studentData.misID)) {
                        expectedData.push([
                            studentData.studentName,
                            studentData.formGroup,
                            'Not Attended' // i.e. expected
                        ]);
                    }
                }
            });

            // Concatenate attended and expected arrays to get the attended records at the top
            exportData = [...attendedData, ...expectedData];
        });


        const eventStart = humanReadableDate(eventData.start.toDate(), 'full');
        const eventEnd = humanReadableDate(eventData.end.toDate(), 'full');
        const expectedCount = eventData.expectedStudents.length;
        const attendedCount = eventData.attendedStudents.length;

        const title = `Event Attendance Report - ${eventData.name}`; // used as title and filename
        const description = `${eventStart} to ${eventEnd} - ${expectedCount} students expected, ${attendedCount} attended`;
        const headers = ['Name', 'FormGroup', 'Status', 'Time'];

        if (format === 'pdf') {
            downloadPDF(title, description, headers, exportData, title);
        } else if (format === 'csv') {
            downloadCSV(exportData, headers, title);
        } else if (format === 'sheet') {
            const spreadsheet = await createSpreadsheet(title, headers, exportData);
            if (spreadsheet) {
                console.log('Spreadsheet created - ID:', spreadsheet.spreadsheetId);
                // open the URL - spreadsheetUrl
                window.open(spreadsheet.spreadsheetUrl, '_blank');
            }
            // if there was an error, the function handles it
        }
    } else {
        showError('4004', 'There was an error exporting the event.', 'Please try again later.', false, false);
    }
}

// load the new event modal with the correct data
export async function loadEditEvents(eventID) {
    console.log('Loading edit events...');

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', eventID);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
        console.error('No such document!');
        return;
    }

    const eventData = eventDoc.data() || {};
    const name = eventData.name;
    const location = eventData.location;
    var start = eventData.start;
    var end = eventData.end;

    // Convert to JavaScript Date objects
    start = start.toDate();
    end = end.toDate();

    // Format dates to YYYY-MM-DDTHH:mm (ISO 8601 format without seconds)
    const formatDateTimeLocal = (date) => {
        const ten = (i) => (i < 10 ? '0' : '') + i;
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const mm = ten(date.getMinutes());
        return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
    };

    document.getElementById('edit-event-name').value = name;
    document.getElementById('edit-event-location').value = location;
    document.getElementById('edit-event-start').value = formatDateTimeLocal(start);
    document.getElementById('edit-event-end').value = formatDateTimeLocal(end);
    document.getElementById('edit-event-assigned-forms').innerHTML = '';

    const formGroups = await getFormGroups();
    createDropdown(formGroups, 'edit');

    // Pre-fetch all student data and store in an object
    const students = {};
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval),);

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // find the unique form groups within expected students
    //// THIS ASSUMES ALL STUDENTS IN EACH FORM GROUP ARE EXPECTED
    /// TODO: STORE FORM GROUPS IN THE DB TO MAKE THIS MORE EFFICIENT

    const checkedFormGroups = [];
    const expectedStudents = eventData.expectedStudents;
    // for each expected student, get and store their form group unless it's already in the array
    for (const studentID of expectedStudents) {
        const student = students[studentID];
        const formGroup = student.formGroup;
        if (!checkedFormGroups.includes(formGroup)) {
            checkedFormGroups.push(formGroup);
        }
    }

    console.log('checkedFormGroups:', checkedFormGroups);

    // find the checkbox by ID and set the checked property
    checkedFormGroups.forEach((formGroup) => {
        const checkbox = document.getElementById(`edit-event-form-${formGroup}`);
        checkbox.checked = true;
    });
}

// update an edited event
export async function editEvent() {
    console.log(`updating event ${editingEventID}`);

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', editingEventID);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
        console.error('No such document!');
        showError('2035', 'There was an error updating the event.', 'Please try again later.', false, false);
        return;
    }

    const eventData = eventDoc.data();
    const previousStart = eventData.start.toDate();
    const previousEnd = eventData.end.toDate();
    // get the data
    const name = document.getElementById('edit-event-name').value;
    const location = document.getElementById('edit-event-location').value;
    const start = document.getElementById('edit-event-start').value;
    const end = document.getElementById('edit-event-end').value;
    const checkboxes = document.querySelectorAll('.edit-event-form-checkbox');
    // Filter out the unchecked boxes and map to their values
    const selected = Array.from(checkboxes)
        .filter(checkbox => checkbox.checked) // Keep only checked boxes
        .map(checkbox => checkbox.value); // Extract the value attribute

    // check the data
    // name
    if (name.length == 0 || name == null || name == undefined) {
        console.log('Event name is empty');
        showError('', 'Edit Event', 'Please enter a name for the event.', false, false);
        return;
    }

    // location
    if (location.length == 0 || location == null || location == undefined) {
        console.log('Event location is empty');
        showError('', 'Edit Event', 'Please enter a location for the event.', false, false);
        return;
    }

    // start
    if (start.length == 0 || start == null || start == undefined) {
        console.log('Event start is empty');
        showError('', 'Edit Event', 'Please enter a valid start time for the event.', false, false);
        return;
    }

    // end
    if (end.length == 0 || end == null || end == undefined) {
        console.log('Event end is empty');
        showError('', 'Edit Event', 'Please enter a valid end time for the event.', false, false);
        return;
    }

    // check if any form groups have been selected
    if (selected.length == 0) {
        console.log('No form groups have been selected');
        showError('', 'Edit Event', 'Please select at least one form group for the event.', false, false);
        return;
    }

    // check if the start is in the past, unless it's the same as the previous start
    if (new Date(start) < new Date(previousStart) && new Date(start) != new Date(previousStart)) {
        console.log('Event start is in the past');
        showError('', 'Edit Event', 'The event start time is in the past.', false, false);
        return;
    }

    // check if the end is in the past, unless it's the same as the previous end
    if (new Date(end) < new Date(previousEnd) && new Date(end) != new Date(previousEnd)) {
        console.log('Event end is in the past');
        showError('', 'Edit Event', 'The event end time is in the past.', false, false);
        return;
    }

    // check if the event ends before it starts
    if (new Date(end) < new Date(start)) {
        console.log('Event end is before the start');
        showError('', 'Edit Event', 'The event end time is before the start time.', false, false);
        return;
    }

    // check if the start is the same as the end
    if (new Date(start) == new Date(end)) {
        console.log('Event start is the same as the end');
        showError('', 'Edit Event', 'The event start time is the same as the end time.', false, false);
        return;
    }

    // check if the start is after the end
    if (new Date(start) > new Date(end)) {
        console.log('Event start is after the end');
        showError('', 'Edit Event', 'The event start time is after the end time.', false, false);
        return;
    }

    // check if the dates are the same
    if (new Date(start) == new Date(end)) {
        console.log('Event start and end are the same');
        showError('', 'Edit Event', 'The event start and end times are the same.', false, false);
        return;
    }

    // get all the students in the form groups
    // for each form group, find all the students in it
    //// TODO: STORE FORM GROUPS IN THE DB TO MAKE THIS MORE EFFICIENT
    const students = [];
    for (const formGroup of selected) {
        const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('formGroup', '==', formGroup), where('intervals', 'array-contains', window.currentInterval));
        const studentsSnapshot = await getDocs(studentsRef);

        if (studentsSnapshot.size > 0) {
            studentsSnapshot.forEach((docu) => {
                students.push(docu.id);
            });
        }
    }

    eventData.name = name;
    eventData.location = location;
    eventData.start = new Date(start);
    eventData.end = new Date(end);
    eventData.expectedStudents = students;

    await updateDoc(eventRef, eventData);

    // create a history record
    const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'eventHistory');
    await addDoc(historyRef, {
        action: 'edited',
        eventID: eventRef.id,
        adminID: window.userID,
        kioskID: null,
        archived: false,
        timestamp: Timestamp.now(),
        studentID: null
    });

    // check if we need to reload with archived events
    if (document.getElementById('event-archived').checked) {
        await loadEvents(true);
    } else {
        await loadEvents();
    }

    editingEventID = null;
    closeModal(document.getElementById('edit-event-modal'));
    // wait 500ms, for the modal to close
    await delay(500);
    // clear all the inputs
    document.getElementById('edit-event-name').value = '';
    document.getElementById('edit-event-location').value = '';
    document.getElementById('edit-event-start').value = '';
    document.getElementById('edit-event-end').value = '';
    document.getElementById('edit-event-assigned-forms').innerHTML = '';
}

async function loadAttendedStudents(eventID) {
    console.log(`Loading attended students for event ${eventID}...`);

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', eventID);
    const eventDoc = await getDoc(eventRef);
    const eventData = eventDoc.data();

    // reset the modal
    const tableBody = document.querySelector('#event-attend-table tbody');
    tableBody.innerHTML = '';

    document.getElementById('event-attend-title').innerHTML = 'Attended Students';
    document.getElementById('event-attend-description').innerHTML = `<strong>${eventData.name}</strong><br>${eventData.start.toDate().toLocaleString()} to ${eventData.end.toDate().toLocaleString()}<br>${eventData.attendedStudents.length} students attended`;

    // show the Time column
    document.getElementById('event-attend-time').style.display = 'table-cell';

    // Pre-fetch all student data and store in an object
    const students = {};
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), orderBy('index'), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // Pre-fetch all event history for this event
    const eventHistory = {};
    // no admin ID - exclude create/archive
    const eventHistoryRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'eventHistory'), where('adminID', '==', null), where('eventID', '==', eventID), orderBy('timestamp', 'desc'));

    await getDocs(eventHistoryRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const historyData = doc.data();
            eventHistory[historyData.studentID] = historyData;
        });
    });

    // fill the modal
    if (eventDoc.exists()) {
        console.log('Event data:', eventData);

        // if there are no  students
        if (eventData.attendedStudents.length == 0) {
            console.log('No expected students found');
            const row = document.createElement('tr');
            row.innerHTML = `
            <td colspan="3" style="text-align: center;">No students found</td>
            `;
            tableBody.appendChild(row);
        } else {
            // there are students
            console.log(`found ${eventData.attendedStudents.length} attended students`);
            const sortedStudentIDs = Object.keys(students).sort((a, b) => students[a].index - students[b].index);
            sortedStudentIDs.forEach(studentID => {
                if (eventHistory[studentID.toString()]) {
                    const attendedAt = humanReadableDate(eventHistory[studentID.toString()].timestamp.toDate(), 'full');
                    const studentName = students[studentID.toString()].studentName;
                    const formGroup = students[studentID.toString()].formGroup;

                    const row = document.createElement('tr');
                    row.innerHTML = `
                    <td>${studentName}</td>
                    <td>${formGroup}</td>
                    <td>${attendedAt}</td>
                    `;
                    tableBody.appendChild(row); // Add the row to the table
                }
            });
        }
    } else {
        console.error('No such document!');
        tableBody.innerHTML = '<tr><td colspan="3" style="text-align: center;">No students found</td></tr>';
    }

}

async function loadExpectedStudents(eventID) {
    console.log(`Loading expected students for event ${eventID}...`);

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', eventID);
    const eventDoc = await getDoc(eventRef);
    const eventData = eventDoc.data();

    // reset the modal
    const tableBody = document.querySelector('#event-attend-table tbody');
    tableBody.innerHTML = '';

    document.getElementById('event-attend-title').innerHTML = 'Expected Students';
    document.getElementById('event-attend-description').innerHTML = `<strong>${eventData.name}</strong><br>${eventData.start.toDate().toLocaleString()} to ${eventData.end.toDate().toLocaleString()}<br>${eventData.expectedStudents.length} students expected`;

    // hide the Time column - expected students do not have a time
    document.getElementById('event-attend-time').style.display = 'none';

    // Pre-fetch all student data and store in an object
    const students = {};
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), orderBy('index'), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // fill the modal
    if (eventDoc.exists()) {
        console.log('Event data:', eventData);

        // if there are no expected students
        if (eventData.expectedStudents.length == 0) {
            console.log('No expected students found');
            const row = document.createElement('tr');
            row.innerHTML = `
            <td colspan="2" style="text-align: center;">No expected students found</td>
            `;
            tableBody.appendChild(row);
        } else {
            // there are expecred students
            console.log(`found ${eventData.expectedStudents.length} expected students`);

            const sortedStudentIDs = Object.keys(students).sort((a, b) => students[a].index - students[b].index);
            sortedStudentIDs.forEach(studentID => {
                if (eventData.expectedStudents.includes(studentID)) {
                    const studentName = students[studentID].studentName;
                    const formGroup = students[studentID].formGroup;

                    const row = document.createElement('tr');
                    row.innerHTML = `
                    <td>${studentName}</td>
                    <td>${formGroup}</td>
                    `;
                    tableBody.appendChild(row); // Add the row to the table
                }
            });

        }
    } else {
        console.error('No such document!');
        tableBody.innerHTML = '<tr><td colspan="2" style="text-align: center;">No expected students found</td></tr>';
    }
}

export async function fillNewEventModal() {
    // clear all the inputs
    document.getElementById('new-event-title').innerHTML = 'New Event';
    document.getElementById('new-event-name').value = '';
    document.getElementById('new-event-location').value = '';
    document.getElementById('new-event-start').value = '';
    document.getElementById('new-event-end').value = '';
    document.getElementById('new-event-assigned-forms').innerHTML = '';
    document.getElementById('new-event-forms').style.display = 'block';

    const formGroups = await getFormGroups();
    createDropdown(formGroups, 'new');
}


//// TODO: make this a general function across the app
function createDropdown(array, prefix) {
    // Find the container where the dropdown will be placed
    const container = document.getElementById(`${prefix}-event-assigned-forms`);

    // Create the <details> and <summary> elements
    const details = document.createElement('details');
    const summary = document.createElement('summary');
    details.appendChild(summary);
    details.setAttribute('open', 'open');

    // Iterate over the array to create checkboxes
    array.forEach((item) => {
        // Create a checkbox for each item
        const label = document.createElement('label');
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.id = `${prefix}-event-form-${item}`;
        checkbox.value = item;
        checkbox.className = `${prefix}-event-form-checkbox`;
        label.setAttribute('for', `${prefix}-event-form-${item}`);
        label.textContent = item;
        label.insertBefore(checkbox, label.firstChild);

        // Append the checkbox and label to the <details>
        details.appendChild(label);
    });

    // Append the <details> to the container
    container.appendChild(details);
}

export async function createNewEvent() {
    console.log('creating new event...');

    // get the data
    const name = document.getElementById('new-event-name').value;
    const location = document.getElementById('new-event-location').value;
    const start = document.getElementById('new-event-start').value;
    const end = document.getElementById('new-event-end').value;

    const checkboxes = document.querySelectorAll('.new-event-form-checkbox');
    // Filter out the unchecked boxes and map to their values
    const selected = Array.from(checkboxes)
        .filter(checkbox => checkbox.checked) // Keep only checked boxes
        .map(checkbox => checkbox.value); // Extract the value attribute



    // check the data
    // name
    if (name.length == 0 || name == null || name == undefined) {
        console.log('Event name is empty');
        showError('', 'Create Event', 'Please enter a name for the event.', false, false);
        return;
    }

    // location
    if (location.length == 0 || location == null || location == undefined) {
        console.log('Event location is empty');
        showError('', 'Create Event', 'Please enter a location for the event.', false, false);
        return;
    }

    // start
    // check if it's in the future
    if (start.length == 0 || start == null || start == undefined) {
        console.log('Event start is empty');
        showError('', 'Create Event', 'Please enter a valid start time for the event.', false, false);
        return;
    }

    // end
    // check if it's after the start, and in the future
    if (end.length == 0 || end == null || end == undefined) {
        console.log('Event end is empty');
        showError('', 'Create Event', 'Please enter a valid end time for the event.', false, false);
        return;
    }

    // check if any form groups have been selected
    if (selected.length == 0) {
        console.log('No form groups have been selected');
        showError('', 'Create Event', 'Please select at least one form group for the event.', false, false);
        return;
    }

    // check if the start is before the end
    if (new Date(start) > new Date(end)) {
        console.log('Event start is after the end');
        showError('', 'Create Event', 'The event start time is after the end time.', false, false);
        return;
    }

    // check if the start is in the past
    if (new Date(start) < new Date()) {
        console.log('Event start is in the past');
        showError('', 'Create Event', 'The event start time is in the past.', false, false);
        return;
    }

    // check if the end is in the past
    if (new Date(end) < new Date()) {
        console.log('Event end is in the past');
        showError('', 'Create Event', 'The event end time is in the past.', false, false);
        return;
    }

    // check if the start is the same as the end
    if (new Date(start) == new Date(end)) {
        console.log('Event start is the same as the end');
        showError('', 'Create Event', 'The event start time is the same as the end time.', false, false);
        return;
    }


    // get all the students in the form groups
    // for each form group, find all the students in it
    //// TODO: STORE FORM GROUPS IN THE DB TO MAKE THIS MORE EFFICIENT
    const students = [];
    for (const formGroup of selected) {
        const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('formGroup', '==', formGroup), where('intervals', 'array-contains', window.currentInterval));
        const studentsSnapshot = await getDocs(studentsRef);

        if (studentsSnapshot.size > 0) {
            studentsSnapshot.forEach((docu) => {
                students.push(docu.id);
            });
        }
    }

    // create the event
    const eventData = {
        archived: false,
        attendedStudents: [],
        end: new Date(end),
        expectedStudents: students,
        location: location,
        name: name,
        start: new Date(start)
    };

    // add the event to the database
    const eventRef = await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events'), eventData);
    console.log('Event added with ID: ', eventRef.id);

    // create a history record
    const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'eventHistory');
    await addDoc(historyRef, {
        action: 'created',
        eventID: eventRef.id,
        adminID: window.userID,
        kioskID: null,
        archived: false,
        timestamp: Timestamp.now(),
        studentID: null
    });

    // check if we need to reload with archived events
    if (document.getElementById('event-archived').checked) {
        await loadEvents(true);
    } else {
        await loadEvents();
    }

    closeModal(document.getElementById('new-event-modal'));
    // wait 500ms, for the modal to close
    await delay(500);
    // clear all the inputs
    document.getElementById('new-event-name').value = '';
    document.getElementById('new-event-location').value = '';
    document.getElementById('new-event-start').value = '';
    document.getElementById('new-event-end').value = '';
    document.getElementById('new-event-assigned-forms').innerHTML = '';
}

async function archiveEvent(eventID) {
    console.log(`archiving event ${eventID}`);

    // get the event data
    const eventRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', eventID);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
        console.error('No such document!');
        return;
    }

    const event = eventDoc.data();
    const archived = event.archived;
    console.log(`archiving event ${eventID} - archived: ${archived}`);

    // update to the opposite of the archived state
    await updateDoc(eventRef, {
        archived: !archived
    });

    // create a history record
    const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'eventHistory');
    await addDoc(historyRef, {
        action: archived ? 'unarchived' : 'archived', // archived/unarchived
        eventID: eventRef.id,
        adminID: window.userID,
        kioskID: null,
        archived: false,
        timestamp: Timestamp.now(),
        studentID: null
    });

    // reload
    // check if we need to reload with archived events
    if (document.getElementById('event-archived').checked) {
        await loadEvents(true);
    } else {
        await loadEvents();
    }

}