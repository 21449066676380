// A simple function to format dates for display
// e.g. 'Today at 09:10', 'Yesterday at 09:10', 'Tomorrow at 09:10', '01/01/2024 at 09:10'


/*
simple: Returns 'Today at 09:10', 'Yesterday at 09:10', 'Tomorrow at 09:10', or '01/04/2024 at 09:10'.
extended: Returns 'Monday 1st January 2024 at 09:10'.
full: Returns '01/04/2024 at 09:10'.
*/


export function humanReadableDate(date, format = 'simple') {
    const today = new Date();
    const yesterday = new Date(today);
    const tomorrow = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    tomorrow.setDate(today.getDate() + 1);

    // Get time in '09:10' format
    const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Decide on format based on the format flag
    let dateFormatOptions;

    switch (format) {
    case 'extended':
        dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        break;
    case 'full':
        dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-GB', dateFormatOptions) + ' at ' + timeString;
    case 'simple':
    default:
        if (date.toDateString() === today.toDateString()) {
            return 'Today at ' + timeString;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Yesterday at ' + timeString;
        } else if (date.toDateString() === tomorrow.toDateString()) {
            return 'Tomorrow at ' + timeString;
        } else {
            dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        }
        break;
    }

    return date.toLocaleDateString('en-GB', dateFormatOptions) + ' at ' + timeString;
}
