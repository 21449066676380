import { db } from '../shared/firebase.js';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, orderBy } from 'firebase/firestore';
import { humanReadableDate } from './utils/date-format.js';
import { openModal } from '../shared/modal.js';
import { delay } from '../shared/delay.js';
import { downloadCSV } from './utils/csv.js';
import { downloadPDF } from './utils/pdf.js';
import { createSpreadsheet } from './utils/sheet.js';

// Common event listeners for download buttons
const addCommonDownloadListeners = (docu) => {
    document.getElementById('fire-register-table-content').style.display = 'none';
    document.getElementById('fire-register-export-content').style.display = 'block';
    document.getElementById('fire-register-title').innerHTML = 'Export Fire Register';

    const downloadPdfButton = document.getElementById('fire-download-pdf');
    const downloadCsvButton = document.getElementById('fire-download-csv');
    const downloadSheetButton = document.getElementById('fire-download-sheet');

    if (downloadPdfButton) {
        downloadPdfButton.addEventListener('mousedown', async () => {
            console.log(`download pdf button for fire ${docu.id} has been clicked`);
            downloadPdfButton.setAttribute('aria-busy', 'true');
            await exportFireRegister(docu.id, 'pdf');
            downloadPdfButton.setAttribute('aria-busy', 'false');
        });
    }

    if (downloadCsvButton) {
        downloadCsvButton.addEventListener('mousedown', async () => {
            console.log(`download csv button for fire ${docu.id} has been clicked`);
            downloadCsvButton.setAttribute('aria-busy', 'true');
            await exportFireRegister(docu.id, 'csv');
            downloadCsvButton.setAttribute('aria-busy', 'false');
        });
    }

    if (downloadSheetButton) {
        downloadSheetButton.addEventListener('mousedown', async () => {
            console.log(`download sheet button for fire ${docu.id} has been clicked`);
            downloadSheetButton.setAttribute('aria-busy', 'true');
            await exportFireRegister(docu.id, 'sheet');
            downloadSheetButton.setAttribute('aria-busy', 'false');
        });
    }
};

export async function loadFireRegisters() {
    console.log('Loading fire registers...');

    // if the user is a viewer, hide the archive column
    if (window.userRole === 'viewer') {
        if (document.getElementById('fire-register-archive-column')) {
            document.getElementById('fire-register-archive-column').remove();
        }
    }

    // get the fire registers
    var fireRegistersRef = null;

    if (document.getElementById('fire-archived').checked) {
        // show all
        fireRegistersRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire'), orderBy('timestamp', 'desc'));
    } else {
        // show not archived
        fireRegistersRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire'), where('archived', '==', false), orderBy('timestamp', 'desc'));
    }

    const fireRegistersSnapshot = await getDocs(fireRegistersRef);

    // reset the table
    const tableBody = document.querySelector('#fire-register-table tbody');
    tableBody.innerHTML = '';

    // if there are no fire registers
    if (fireRegistersSnapshot.empty) {
        console.log('No fire registers found');
        const row = document.createElement('tr');
        row.innerHTML = `
        <td colspan="6" style="text-align: center;">No fire registers found</td>
        `;
        tableBody.appendChild(row);
        return;
    }

    // for each fire register
    fireRegistersSnapshot.forEach((docu) => {
        const fireRegister = docu.data();
        const row = document.createElement('tr');

        const date = humanReadableDate(fireRegister.timestamp.toDate(), 'simple');

        let archiveButton = '';
        if (window.userRole !== 'viewer') {
            archiveButton = `<td><a id="archive-fire-register-button-${docu.id}">${fireRegister.archived ? 'Unarchive' : 'Archive'}</a></td>`;
        }

        // date, onsite, present, export, archive / unarchive
        row.innerHTML = `
        <td>${date}</td>
        <td>${fireRegister.onsite.length} ${fireRegister.onsite.length === 1 ? 'student' : 'students'} - <a id="view-fire-onsite-button-${docu.id}">View</a></td>
        <td>${fireRegister.present.length} ${fireRegister.present.length === 1 ? 'student' : 'students'} - <a id="view-fire-present-button-${docu.id}">View</a></td>
        <td>${fireRegister.absent.length} ${fireRegister.absent.length === 1 ? 'student' : 'students'} - <a id="view-fire-absent-button-${docu.id}">View</a></td>
        <td><a id="export-fire-register-button-${docu.id}">Export</a></td>
        ${archiveButton}
        `;
        tableBody.appendChild(row);

        // add event listener for export
        document.getElementById(`export-fire-register-button-${docu.id}`).addEventListener('mousedown', () => {
            console.log(`export event button for fire ${docu.id} has been clicked`);

            // Remove existing listeners before adding new ones to avoid duplication
            const downloadPdfButton = document.getElementById('fire-download-pdf');
            const downloadCsvButton = document.getElementById('fire-download-csv');
            const downloadSheetButton = document.getElementById('fire-download-sheet');

            if (downloadPdfButton) {
                downloadPdfButton.replaceWith(downloadPdfButton.cloneNode(true));
            }

            if (downloadCsvButton) {
                downloadCsvButton.replaceWith(downloadCsvButton.cloneNode(true));
            }

            if (downloadSheetButton) {
                downloadSheetButton.replaceWith(downloadSheetButton.cloneNode(true));
            }

            // Add common download listeners for the current fire
            addCommonDownloadListeners(docu);

            openModal(document.getElementById('fire-register-modal'));
        });

        // add event listener for archive
        if (window.userRole !== 'viewer') {
            document.getElementById(`archive-fire-register-button-${docu.id}`).addEventListener('mousedown', async () => {
                console.log(`archive fire register button for fire register ${docu.id} has been clicked`);
                document.getElementById(`archive-fire-register-button-${docu.id}`).setAttribute('aria-busy', 'true');
                await archiveFireRegister(docu.id);
                await delay(500);
            });
        }

        // add event listener for onsite
        document.getElementById(`view-fire-onsite-button-${docu.id}`).addEventListener('mousedown', async () => {
            console.log(`view onsite button for fire register ${docu.id} has been clicked`);
            document.getElementById(`view-fire-onsite-button-${docu.id}`).setAttribute('aria-busy', 'true');
            await loadOnsiteStudents(docu.id);
            document.getElementById('fire-register-table-content').style.display = 'block';
            document.getElementById('fire-register-export-content').style.display = 'none';
            // open modal
            openModal(document.getElementById('fire-register-modal'));
            document.getElementById(`view-fire-onsite-button-${docu.id}`).setAttribute('aria-busy', 'false');
        });

        // add event listener for present
        document.getElementById(`view-fire-present-button-${docu.id}`).addEventListener('mousedown', async () => {
            console.log(`view present button for fire register ${docu.id} has been clicked`);
            document.getElementById(`view-fire-present-button-${docu.id}`).setAttribute('aria-busy', 'true');
            await loadPresentStudents(docu.id);
            document.getElementById('fire-register-table-content').style.display = 'block';
            document.getElementById('fire-register-export-content').style.display = 'none';
            // open modal
            openModal(document.getElementById('fire-register-modal'));
            document.getElementById(`view-fire-present-button-${docu.id}`).setAttribute('aria-busy', 'false');
        });

        // add event listener for absent
        document.getElementById(`view-fire-absent-button-${docu.id}`).addEventListener('mousedown', async () => {
            console.log(`view absent button for fire register ${docu.id} has been clicked`);
            document.getElementById(`view-fire-absent-button-${docu.id}`).setAttribute('aria-busy', 'true');
            await loadAbsentStudents(docu.id);
            document.getElementById('fire-register-table-content').style.display = 'block';
            document.getElementById('fire-register-export-content').style.display = 'none';
            // open modal
            openModal(document.getElementById('fire-register-modal'));
            document.getElementById(`view-fire-absent-button-${docu.id}`).setAttribute('aria-busy', 'false');
        });

    });
}

// Function to fetch student details by their IDs
async function fetchStudentNames(studentIDs, status, sheetFormat) {
    const names = await Promise.all(studentIDs.map(async (studentID) => {
        const studentRef = doc(db, 'domains', window.userDomain, 'students', studentID);
        const studentDoc = await getDoc(studentRef);
        if (studentDoc.exists()) {
            const studentData = studentDoc.data();

            // google sheets
            if (sheetFormat) {
                return [
                    studentData.studentName,
                    studentData.formGroup,
                    status
                ];
            }

            // pdf / csv
            return {
                Name: studentData.studentName, // Defaulting to "Unknown" if name is not available
                FormGroup: studentData.formGroup, // Defaulting to "No Form" if form group is not provided
                Status: status
            };
        } else {
            console.error('No such student with ID:', studentID);
            return null;
        }
    }));
    return names.filter(name => name !== null); // Filter out any null entries
}

// Function to construct data for the fire register export
async function constructRegisterExportData(fireRegister, sheetFormat) {
    const presentNames = await fetchStudentNames(fireRegister.present, 'Present', sheetFormat);
    const absentNames = await fetchStudentNames(fireRegister.absent, 'Absent', sheetFormat);

    // Consolidate all names into a single array
    return [...presentNames, ...absentNames];
}

// Function to export the fire register as a CSV
async function exportFireRegister(fireRegisterID, format) {
    console.log('exporting fire register...');

    try {
        const fireRegisterRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire', fireRegisterID);
        const fireRegisterDoc = await getDoc(fireRegisterRef);

        if (!fireRegisterDoc.exists()) {
            console.error('No such document!');
            return;
        }

        const fireRegister = fireRegisterDoc.data();
        const registerDate = humanReadableDate(fireRegister.timestamp.toDate(), 'full');

        var sheetFormat = false; // google sheet format - doesn't need column headers
        if (format === 'sheet') {
            sheetFormat = true;
        }
        const exportData = await constructRegisterExportData(fireRegister, sheetFormat);

        if (format === 'pdf') {
            downloadPDF('Fire Register Report', registerDate, ['Name', 'FormGroup', 'Status'], exportData, `fire-register-${registerDate}`);
        } else if (format === 'csv') {
            downloadCSV(exportData, ['Name', 'FormGroup', 'Status'], `fire-register-${registerDate}`);
        } else if (format === 'sheet') {
            const spreadsheet = await createSpreadsheet(`Fire Register Report - ${registerDate}`, ['Name', 'Form Group', 'Status'], exportData);
            if (spreadsheet) {
                console.log('Spreadsheet created - ID:', spreadsheet.spreadsheetId);
                // open the URL - spreadsheetUrl
                window.open(spreadsheet.spreadsheetUrl, '_blank');
            }
            // if there was an error, the function handles it
        }
    } catch (error) {
        console.error('Failed to export fire register:', error);
    }
}


async function archiveFireRegister(registerID) {
    console.log(`archiving register ${registerID}`);

    // get the fire register data
    const fireRegisterRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire', registerID);
    const fireRegisterDoc = await getDoc(fireRegisterRef);

    if (!fireRegisterDoc.exists()) {
        console.error('No such document!');
        return;
    }

    const fireRegister = fireRegisterDoc.data();
    const archived = fireRegister.archived;
    console.log(`archiving register ${registerID} - archived: ${archived}`);

    // update the fire register to the opposite of the archived state
    await updateDoc(fireRegisterRef, {
        archived: !archived
    });

    // reload
    loadFireRegisters();

}

async function loadOnsiteStudents(registerID) {
    console.log(`loading onsite students for register ${registerID}`);

    // get the  data
    const fireRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire', registerID);
    const fireDoc = await getDoc(fireRef);

    if (!fireDoc.exists()) {
        console.error('No such document!');
        return;
    }

    // reset the modal
    const tableBody = document.querySelector('#fire-register-modal-table tbody');
    tableBody.innerHTML = '';

    document.getElementById('fire-register-title').innerHTML = 'On-Site Students';

    // fill the modal
    if (fireDoc.exists()) {
        const fireData = fireDoc.data();

        // if there are no expected students
        if (fireData.onsite.length == 0) {
            console.log('No expected students found');
            const row = document.createElement('tr');
            row.innerHTML = `
            <td colspan="2" style="text-align: center;">No on-site students found</td>
            `;
            tableBody.appendChild(row);
        } else {
            // there are expecred students
            console.log('Onsite students found');

            // Fetch and sort student data first
            const studentsData = await Promise.all(fireData.onsite.map(async (studentID) => {
                const studentRef = doc(db, 'domains', window.userDomain, 'students', studentID);
                const studentDoc = await getDoc(studentRef);
                if (studentDoc.exists()) {
                    return { studentID, ...studentDoc.data() };
                } else {
                    console.error('No such student with ID:', studentID);
                    return null;
                }
            }));

            // Filter out nulls (for not found students) and sort by index
            const sortedStudents = studentsData.filter(s => s !== null).sort((a, b) => a.index - b.index);

            for (const student of sortedStudents) {
                const studentName = student.studentName;
                const formGroup = student.formGroup;

                const row = document.createElement('tr');
                row.innerHTML = `
                    <td>${studentName}</td>
                    <td>${formGroup}</td>
                    `;

                tableBody.appendChild(row);
            }

        }
    } else {
        console.error('No such document!');
        tableBody.innerHTML = '<tr><td colspan="2" style="text-align: center;">No on-site students found</td></tr>';
    }

}

async function loadPresentStudents(registerID) {
    console.log(`loading present students for register ${registerID}`);

    // get the  data
    const fireRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire', registerID);
    const fireDoc = await getDoc(fireRef);

    if (!fireDoc.exists()) {
        console.error('No such document!');
        return;
    }

    // reset the modal
    const tableBody = document.querySelector('#fire-register-modal-table tbody');
    tableBody.innerHTML = '';

    document.getElementById('fire-register-title').innerHTML = 'Present Students';

    // fill the modal
    if (fireDoc.exists()) {
        const fireData = fireDoc.data();

        // if there are no expected students
        if (fireData.present.length == 0) {
            console.log('No expected students found');
            const row = document.createElement('tr');
            row.innerHTML = `
            <td colspan="2" style="text-align: center;">No present students found</td>
            `;
            tableBody.appendChild(row);
        } else {
            // there are expecred students
            console.log('present students found');

            // Fetch and sort student data first
            const studentsData = await Promise.all(fireData.present.map(async (studentID) => {
                const studentRef = doc(db, 'domains', window.userDomain, 'students', studentID);
                const studentDoc = await getDoc(studentRef);
                if (studentDoc.exists()) {
                    return { studentID, ...studentDoc.data() };
                } else {
                    console.error('No such student with ID:', studentID);
                    return null;
                }
            }));

            // Filter out nulls (for not found students) and sort by index
            const sortedStudents = studentsData.filter(s => s !== null).sort((a, b) => a.index - b.index);

            for (const student of sortedStudents) {
                const studentName = student.studentName;
                const formGroup = student.formGroup;

                const row = document.createElement('tr');
                row.innerHTML = `
                    <td>${studentName}</td>
                    <td>${formGroup}</td>
                    `;

                tableBody.appendChild(row);
            }

        }
    } else {
        console.error('No such document!');
        tableBody.innerHTML = '<tr><td colspan="2" style="text-align: center;">No present students found</td></tr>';
    }
}

async function loadAbsentStudents(registerID) {
    console.log(`loading absent students for register ${registerID}`);

    // get the  data
    const fireRef = doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'fire', registerID);
    const fireDoc = await getDoc(fireRef);

    if (!fireDoc.exists()) {
        console.error('No such document!');
        return;
    }

    // reset the modal
    const tableBody = document.querySelector('#fire-register-modal-table tbody');
    tableBody.innerHTML = '';

    document.getElementById('fire-register-title').innerHTML = 'Absent Students';

    // fill the modal
    if (fireDoc.exists()) {
        const fireData = fireDoc.data();

        // if there are no expected students
        if (fireData.absent.length == 0) {
            console.log('No abent students found');
            const row = document.createElement('tr');
            row.innerHTML = `
            <td colspan="2" style="text-align: center;">No absent students found</td>
            `;
            tableBody.appendChild(row);
        } else {
            // there are expecred students
            console.log('absent students found');

            // Fetch and sort student data first
            const studentsData = await Promise.all(fireData.absent.map(async (studentID) => {
                const studentRef = doc(db, 'domains', window.userDomain, 'students', studentID);
                const studentDoc = await getDoc(studentRef);
                if (studentDoc.exists()) {
                    return { studentID, ...studentDoc.data() };
                } else {
                    console.error('No such student with ID:', studentID);
                    return null;
                }
            }));

            // Filter out nulls (for not found students) and sort by index
            const sortedStudents = studentsData.filter(s => s !== null).sort((a, b) => a.index - b.index);

            for (const student of sortedStudents) {
                const studentName = student.studentName;
                const formGroup = student.formGroup;

                const row = document.createElement('tr');
                row.innerHTML = `
                    <td>${studentName}</td>
                    <td>${formGroup}</td>
                    `;

                tableBody.appendChild(row);
            }

        }
    } else {
        console.error('No such document!');
        tableBody.innerHTML = '<tr><td colspan="2" style="text-align: center;">No absent students found</td></tr>';
    }
}

