// Admin page authentication, for domains & admin control panel.

import { db, auth, provider, functions } from '../../shared/firebase';
import { httpsCallable } from 'firebase/functions';
import { showError } from '../../shared/error.js';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { delay } from '../../shared/delay.js';

import initSentry from '../../shared/sentry';
const Sentry = initSentry();
import initPosthog from '../../shared/posthog';
const posthog = initPosthog();

// defaults
window.userName = null;
window.userID = null;
window.userDomain = null;
window.userRole = null;
window.googleToken = null;
provider.addScope('https://www.googleapis.com/auth/drive.file'); // for creating google sheets & adding photos to Google Drive

async function triggerLogin() {
    console.log('Logging in...');
    // show loading-progress
    document.getElementById('loading-progress').style.display = 'block';
    document.getElementById('loading-title').innerHTML = 'Logging in...';
    // User is not signed in, initiate sign-in
    await handleSignIn();

    // Check again if the user is signed in after sign-in attempt
    if (auth.currentUser) {
        await processLogin();
    } else {
        showError('1015', 'Error Signing In', 'There was an error signing in.', false);
    }
}

// logInButton - when the user clicks login, handle the sign-in
if (document.getElementById('logInButton')) {
    document.getElementById('logInButton').addEventListener('mousedown', async () => {
        document.getElementById('logInButton').style.display = 'none';
        await triggerLogin();
    });
}

// Function to handle sign-in
async function handleSignIn() {
    console.log('Initiating popup sign-in...');
    await signInWithPopup(auth, provider).then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        window.googleToken = credential.accessToken;
    }).catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.error('Error getting Google token:', message, details, code);
        const debugInfo = `${message} ${details} ${code}`; // this is sent to Sentry

        showError('1018', 'Error Logging In', 'There was an error logging in.', debugInfo, false, false);
    });
}

// Function to process user login
// callback is the function to load this specific page
export async function processLogin(callback = null) {
    if (!auth.currentUser) {
        console.log('No user signed in');
        return;
        // the login button will be shown on the page by default
    }

    window.userName = auth.currentUser.displayName;
    window.userID = auth.currentUser.uid;

    // Check for invite
    const hasInvite = await checkForInvite();
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);

    // if there's an invite, remove it from the URL
    if (params.has('invite')) {
        params.delete('invite');
        url.search = params.toString();
        window.history.replaceState({}, document.title, url.toString());
    }

    // if the invite has been processed
    if (hasInvite) {
        // wait 500ms - for the account to be created
        await delay(500);
        location.reload(true);
    } else {
        // No invite - do the login stuff

        // check if we have a domain parameter
        if (params.has('domain')) {
            const domain = params.get('domain');
            // if the domain param is null or empty string, remove the param
            if (domain === null || domain === '' || domain === undefined) {
                console.log('domain param is null or empty string, removing it from the URL');
                params.delete('domain');
                url.search = params.toString();
                window.history.replaceState({}, document.title, url.toString());
            }

            // if we're on the manager page, remove & ignore the domain parameter
            if (window.location.pathname.includes('manager')) {
                console.log('on manager page, removing domain parameter');
                params.delete('domain');
                url.search = params.toString();
                window.history.replaceState({}, document.title, url.toString());
            } else {
                // if we're not on the manager page, set the domain
                window.userDomain = params.get('domain');
                console.log(`valid override - domain parameter set to ${window.userDomain}`);
            }
        }

        const result = await successfulLogin();
        if (result) {
            console.log('auth complete, loading app');
            if (callback) {
                await callback();
            }
        }
    }

}

// general things to do after a successful login
async function successfulLogin() {
    console.log('Successful login as', window.userName, 'with ID', window.userID);

    try {
        // Try to get the user's document
        const userDocSnap = await getDoc(doc(db, 'users', window.userID));

        if (!userDocSnap.exists()) {
            // User does not exist in database
            console.log('User does not exist in database');
            // Show noAccountContainer and hide everything else
            document.getElementById('noAccountContainer').style.display = 'flex';
            document.getElementById('mainContainer').style.display = 'none';
            document.getElementById('loadingContainer').style.display = 'none';
        } else {
            // User exists - process further based on user's role and domain
            const userData = userDocSnap.data();
            console.log('Logged in user role:', userData.role, 'domain:', userData.domain);

            // check if the user is deleted
            if (userData.status === 'deleted') {
                console.error('User is deleted');
                // sign out
                await signOutUser('You are no longer authorised to access Inscribe.<br>Please try again later.');
                return false;
            }

            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);
            var domainOverride = false;

            // if we have an override domain, set it
            if (window.userDomain) {
                console.log(`overriding domain to ${window.userDomain}`);
                domainOverride = true;
            } else {
                window.userDomain = userData.domain;
            }

            window.userRole = userData.role;
            window.userStatus = userData.status;


            // if the user is not a super admin, check if they have a domain
            // super admins do not have a domain
            if (window.userRole !== 'superadmin') {
                // first, check if we're on the manager page - only super admins can access the manager page
                if (window.location.href.includes('manager')) {
                    console.log('User is not a super admin - redirecting to root');
                    window.location.replace('/');
                    return;
                }

                // if there's an override domain, remove it
                // only super admins can override the domain
                if (domainOverride) {
                    console.log(`resetting override to ${window.userDomain}, user is not super admin`);
                    console.log('not a super admin, ignoring domain parameter');
                    params.delete('domain');
                    url.search = params.toString();
                    window.history.replaceState({}, document.title, url.toString());
                    window.userDomain = userData.domain; // Ensure we use the domain from the user
                }

                // check this domain actually exists
                const domainRef = doc(db, 'domains', window.userDomain);
                const domainDoc = await getDoc(domainRef);
                if (!domainDoc.exists()) {
                    console.error('Domain does not exist');
                    showError('1018', 'Error Logging In', 'This domain does not exist.', null, false, false, true); // reload on done button
                    return false;
                }
            } else {
                // if a super admin has a domain override, check it exists
                if (domainOverride) {
                    console.log(`super admin has domain override: ${window.userDomain}`);
                    const domainRef = doc(db, 'domains', window.userDomain);
                    const domainDoc = await getDoc(domainRef);
                    if (!domainDoc.exists()) {
                        console.error('Domain does not exist');
                        showError('1018', 'Error Logging In', 'This domain does not exist.', null, false, false, true); // reload on done button
                        return false;
                    }
                }
            }

            // update the lastLogin on the user's document
            updateDoc(doc(db, 'users', window.userID), {
                lastLogin: Timestamp.now()
            });

            // set sentry user
            Sentry.setUser({
                id: window.userID,
                userName: window.userName,
                email: auth.currentUser.email,
                name: window.userName,
                domain: window.userDomain,
                role: window.userRole
            });

            // set posthog user
            posthog.identify(window.userID, {
                name: window.userName,
                email: auth.currentUser.email,
                domain: window.userDomain,
                role: window.userRole
            });

            // if the user is a super admin, check if they are on manager page
            // unless we have a domain override
            if (window.userRole === 'superadmin' && !domainOverride) {
                if (!window.location.href.includes('manager')) {
                    console.log('User is a super admin - redirecting to manager');
                    window.location.replace('/manager');
                }
            }

            // if the user is a kiosk, redirect to kiosk page
            if (window.userRole == 'kiosk') {
                console.log('User is a kiosk - redirecting to kiosk page');
                window.location.replace('/kiosk');
            }

            // set the user name in the navbar
            document.getElementById('hi-name').innerHTML = 'Hi, ' + window.userName + '!';
            return true;
        }
    } catch (error) {
        // A general error occured while loading
        console.error('Error during authentication:', error);
        showError('1016', 'There was an error logging in.', 'Refresh the page and try again.', error, false, false, true); // reload on done button
        return false;
    }
}

// check if the user has an invite link
async function checkForInvite() {
    // get any URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const inviteParam = urlParams.get('invite'); // this will be the invite code

    // if there's an invite parameter, show the invite modal
    if (inviteParam) {
        try {
            console.log(`URL invite token: ${inviteParam}`);
            // update loading-title
            document.getElementById('loading-title').innerHTML = 'Creating your account...';
            // hide the login button
            document.getElementById('logInButton').style.display = 'none';

            const createInvitedUser = httpsCallable(functions, 'createInvitedUser');
            await createInvitedUser({ token: inviteParam, userName: window.userName, userID: window.userID, userEmail: auth.currentUser.email })
                .then((result) => {
                    // Read result of the Cloud Function.
                    var message = result.data.message;
                    console.log('createInvitedUser result:', message);

                    return true;
                })
                .catch((error) => {
                    // Getting the Error details.
                    var code = error.code;
                    var message = error.message;
                    var details = error.details;
                    console.error('Error calling createInvitedUser:', message, details, code);
                    const debugInfo = `${message} ${details} ${code}`; // this is sent to Sentry

                    // if User already exists, silently return false and a normal login will take place
                    // this could happen if the user has already signed in with the invite link, but clicks the link again
                    if (message === 'User already exists.') {
                        console.log('User already exists - returning false');
                        return false;
                    }
                    // if the message is 'This invite has expired', show the error
                    else if (message === 'This invite has expired.') {
                        showError('1029', 'Invite Expired', 'This invite has expired.', null, false, false);
                        return false;
                    } else if (message === 'Invite has already been completed.') {
                        // user has already completed the invite, so should be able to log in as usual.
                        return true; // as if the invite was successful
                    } else if (message === 'Invite has been deleted.') {
                        // invite has been deleted/cancelled by an admin - show the error
                        showError('1030', 'Invite Cancelled', 'This invite has been cancelled.', null, false, false);
                        return false;
                    }

                    showError('1018', 'Error Processing Invite', 'There was an error processing your invite.', debugInfo, false, true);
                    return false;
                });



        } catch (error) {
            console.error('Error fetching invited user document:', error);
            showError('1019', 'Error Processing Invite', 'There was an error processing your invite.', error, false, true, true); // reload to try again, without removing the invite from the URL
        }
    } else {
        console.log('no invite code');
        return false;
    }
}

// get a google oauth token for creating a new spreadsheet
export async function getGoogleToken() {
    // if we already have a token, return it
    if (window.googleToken) {
        return window.googleToken;
    }

    // don't have a token - authenticate to get one
    console.log('Reauthenticating to get Google token...');
    await signInWithPopup(auth, provider).then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        window.googleToken = credential.accessToken || null;
    }).catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.error('Error reauthenticating:', message, details, code);
        const debugInfo = `${message} ${details} ${code}`; // this is sent to Sentry

        showError('3005', 'There was an error creating the spreadsheet.', 'Please log out and try again.', debugInfo, false, true);
    });

    // check if we got one from auth
    if (window.googleToken) {
        return window.googleToken;
    } else {
        console.error('No Google token');
        return null;
    }
}

// Function to sign out the user
export async function signOutUser(endMessage = null, silent = false) {
    document.getElementById('logout-title').innerHTML = 'Logging out...';
    document.getElementById('logout-progress').style.display = 'block';
    document.getElementById('logout-desc').style.display = 'none';

    // hide all divs
    document.getElementById('noAccountContainer').style.display = 'none';
    document.getElementById('mainContainer').style.display = 'none';
    document.getElementById('loadingContainer').style.display = 'none';
    document.getElementById('logoutContainer').style.display = 'flex';

    // sign out
    await signOut(auth).then(async () => {
        // Sign-out successful.
        console.log('Signed out');

        // clear the user data
        window.userID = null;
        window.userName = null;
        window.userDomain = null;
        window.userRole = null;
        window.googleToken = null;

        Sentry.setUser(null);
        posthog.reset();

        // wait 500ms
        await delay(500);

        if (!silent) {
            document.getElementById('logout-title').innerHTML = 'Logged Out';
            document.getElementById('logout-progress').style.display = 'none';
            document.getElementById('logout-desc').style.display = 'block';

            // if we have a message, show it
            if (endMessage) {
                document.getElementById('logout-desc').innerHTML = endMessage;
            }
        }
    }).catch((error) => {
        // An error happened.
        console.error('Error signing out:', error);
        showError('1017', 'Error Signing Out', 'There was an error signing out.', error, false, true);
    });
}