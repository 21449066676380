import { db } from '../shared/firebase.js';
import { collection, getDocs, query, where, orderBy, limit, doc, getDoc } from 'firebase/firestore';
import { updateStudentOnsite } from '../index.js';
import { humanReadableDate } from './utils/date-format.js';

const YEAR_GROUPS = ['12', '13'];

function createTableRow(id, colspan, text, textAlign = 'center') {
    const row = document.createElement('tr');
    row.id = id;
    row.innerHTML = `<th scope="row" colspan="${colspan}" style="text-align: ${textAlign};">${text}</th>`;
    return row;
}

function addStudentRow(tbody, studentData, lastAttendance) {
    const { studentName, formGroup, studentID } = studentData;
    const { time, type } = lastAttendance;

    // if the user is not a viewer, show the update button
    let updateButton = '';
    if (window.userRole !== 'viewer') {
        updateButton = `<td><a id="update-onsite-student-${studentID}">Sign Out</a></td>`;
    }

    const row = document.createElement('tr');
    row.style.backgroundColor = 'rgba(124, 179, 66, 0.38)';
    row.innerHTML = `
        <th scope="row">${studentName}</th>
        <td>${formGroup}</td>
        <td>${time}</td>
        <td>${type}</td>
        ${updateButton}
    `;
    row.id = `onsite-row-${studentID}`;
    tbody.appendChild(row);

    // Add event listener
    if (window.userRole !== 'viewer') {
        document.getElementById(`update-onsite-student-${studentID}`).addEventListener('mousedown', async () => {
            console.log(`update-onsite-student-${studentID} clicked`);
            const updateButtonElement = document.getElementById(`update-onsite-student-${studentID}`);
            updateButtonElement.setAttribute('aria-busy', 'true');

            await updateStudentOnsite(studentID, true, 'onsite'); // don't reload the onsite table
            await refreshRow(studentID, tbody);

            updateButtonElement.setAttribute('aria-busy', 'false');
        });
        console.log(`added event listener to update onsite student ${studentID}`);
    }
}

// silently refresh all rows - change individual rows if needed.
// used when the user re-activates the tab.
// avoids clearing/re-rendering the entire table
export async function refreshOnsiteStudentRows() {
    console.log('refreshing onsite student rows');
    const y12tbody = document.querySelector('#onsite-table-y12 tbody');
    const y13tbody = document.querySelector('#onsite-table-y13 tbody');

    // Get all students
    const studentsQuerySnapshot = await getDocs(query(collection(db, 'domains', window.userDomain, 'students'),
        where('archived', '==', false),
        where('intervals', 'array-contains', window.currentInterval),
        orderBy('yearIndex')));

    // Check if there are onsite students for each year group
    const y12OnsiteStudents = studentsQuerySnapshot.docs.filter(doc => doc.data().onsite && doc.data().yearGroup === '12');
    const y13OnsiteStudents = studentsQuerySnapshot.docs.filter(doc => doc.data().onsite && doc.data().yearGroup === '13');

    // Manage the no-onsite-y12 row
    const noOnsiteY12Row = document.getElementById('no-onsite-y12');
    if (y12OnsiteStudents.length > 0) {
        if (noOnsiteY12Row) noOnsiteY12Row.remove();
    } else {
        if (!noOnsiteY12Row) {
            y12tbody.appendChild(createTableRow('no-onsite-y12', 5, 'No students on-site'));
        }
    }

    // Manage the no-onsite-y13 row
    const noOnsiteY13Row = document.getElementById('no-onsite-y13');
    if (y13OnsiteStudents.length > 0) {
        if (noOnsiteY13Row) noOnsiteY13Row.remove();
    } else {
        if (!noOnsiteY13Row) {
            y13tbody.appendChild(createTableRow('no-onsite-y13', 5, 'No students on-site'));
        }
    }

    // Refresh rows for each student
    const refreshRowPromises = studentsQuerySnapshot.docs.map(doc => {
        if (doc.data().yearGroup === '12') {
            return refreshRow(doc.id, y12tbody);
        } else if (doc.data().yearGroup === '13') {
            return refreshRow(doc.id, y13tbody);
        } else {
            console.error('Unknown year group');
            return Promise.resolve();
        }
    });

    await Promise.all(refreshRowPromises);
}

async function refreshRow(id, tbody) {
    const studentDocRef = doc(db, 'domains', window.userDomain, 'students', id);
    const studentDocSnap = await getDoc(studentDocRef);
    const studentData = studentDocSnap.data();

    // Check if the student is onsite
    const onsite = studentData.onsite;
    const existingRow = document.getElementById(`onsite-row-${id}`);

    if (onsite) {
        const { studentName, formGroup } = studentData;
        studentData.studentID = id;

        const attQ = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'), where('studentID', '==', id), orderBy('time', 'desc'), limit(1));
        const attendanceSnapshot = await getDocs(attQ);
        const lastAttendance = attendanceSnapshot.empty
            ? { time: 'Never', type: 'Unknown' }
            : formatAttendance(attendanceSnapshot.docs[0].data());

        if (existingRow) {
            existingRow.querySelector('th[scope="row"]').textContent = studentName;
            existingRow.querySelectorAll('td')[0].textContent = formGroup;
            existingRow.querySelectorAll('td')[1].textContent = lastAttendance.time;
            existingRow.querySelectorAll('td')[2].textContent = lastAttendance.type;
        } else {
            addStudentRow(tbody, studentData, lastAttendance);
        }
    } else if (existingRow) {
        existingRow.remove();
    }
}



// Main functions
export async function loadOnsite() {
    console.log('Loading onsite');
    // if the user is a viewer, hide the update column
    if (window.userRole === 'viewer') {
        if (document.getElementById('y12-onsite-update-column')) {
            document.getElementById('y12-onsite-update-column').remove();
        }
        if (document.getElementById('y13-onsite-update-column')) {
            document.getElementById('y13-onsite-update-column').remove();
        }
    }
    await Promise.all(YEAR_GROUPS.map(yearGroup => loadYearGroup(yearGroup)));
}

async function loadYearGroup(yearGroup) {
    console.log(`Loading onsite for year ${yearGroup}`);
    const tbody = document.querySelector(`#onsite-table-y${yearGroup} tbody`);
    tbody.innerHTML = '';

    try {
        const q = query(collection(db, 'domains', window.userDomain, 'students'), where('onsite', '==', true), where('yearGroup', '==', yearGroup), where('archived', '==', false),         where('intervals', 'array-contains', window.currentInterval), orderBy('yearIndex'));
        const studentsQuery = await getDocs(q);

        if (studentsQuery.empty) {
            tbody.appendChild(createTableRow(`no-onsite-y${yearGroup}`, 5, 'No students on-site'));
        } else {
            studentsQuery.forEach(async doc => {
                const studentData = doc.data();
                const attQ = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'attendance'), where('studentID', '==', doc.id), orderBy('time', 'desc'), limit(1));
                const attendanceSnapshot = await getDocs(attQ);
                const lastAttendance = attendanceSnapshot.empty
                    ? { time: 'Never', type: 'Unknown' }
                    : formatAttendance(attendanceSnapshot.docs[0].data());

                addStudentRow(tbody, { ...studentData, studentID: doc.id }, lastAttendance);
            });
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        tbody.appendChild(createTableRow(`error-onsite-y${yearGroup}`, 5, 'Error loading students'));
    }
}

function formatAttendance(data) {
    const time = humanReadableDate(data.time.toDate(), 'simple');
    const typeMap = {
        'badge': 'Badge',
        'admin': 'Admin',
        'AM_register': 'Registration'
    };
    const type = typeMap[data.type] || 'Unknown';
    return { time, type };
}