import { query, where, getDocs, collection, Timestamp, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import initPosthog from './posthog';
import { signOutUser } from '../admin/utils/auth';
const posthog = initPosthog();

window.maintenanceInProgress = false;
window.kioskMaintenanceReload = null; 

export async function checkForMaintenance(reload = false, kiosk = false) {
    // if localhost, ignore maintenance
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.warn('localhost, ignoring maintenance');
        return false;
    }
    console.log('Checking for maintenance...');
    // find a valid maintenance record by the startDate and endDate
    const maintenanceQuery = query(collection(db, 'maintenance'), where('startDate', '<=', Timestamp.now()), where('endDate', '>=', Timestamp.now()), orderBy('startDate'));
    const maintenanceQuerySnapshot = await getDocs(maintenanceQuery);

    if (maintenanceQuerySnapshot.empty) {
        console.log('No maintenance in progress');

        // if maintenance was in progress, reload to get out of maintenance mode
        if (reload && window.maintenanceInProgress) {
            window.maintenanceInProgress = false;
            window.location.reload();
        }
        
        return null;
    }

    console.warn('Maintenance in progress');

    // use the first maintenance record, the first to start
    const maintenanceDoc = maintenanceQuerySnapshot.docs[0];
    // reload to show the maintenance page (automatic check)
    if (reload && !window.maintenanceInProgress) {
        window.location.reload();
        return;
    }

    // if we have a user ID, sign them out
    if (window.userID) {
        await signOutUser();
    }

    window.maintenanceInProgress = true;
    document.getElementById('loading-title').innerHTML = maintenanceDoc.data().title;
    document.getElementById('loading-desc').innerHTML = maintenanceDoc.data().description;
    document.getElementById('loading-progress').style.display = 'none';

    posthog.capture('maintenance', { maintenence_id: maintenanceDoc.id });

    // if kiosk, reload page at end of maintenance - unless a reload has already been scheduled
    if (kiosk && maintenanceDoc.data().endDate && !window.kioskMaintenanceReload) {
        window.kioskMaintenanceReload = setTimeout(() => {
            console.log('Reloading kiosk - maintenance end');
            window.location.reload(true);
        }, maintenanceDoc.data().endDate.toDate() - new Date());

        console.log('Kiosk maintenance reload scheduled');
    }
    
    return true;
}